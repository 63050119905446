.favorite {
  position: relative;
  width: 16px;
  height: 16px;
}
.favorite .icon {
  width: 16px;
  height: 16px;
  fill: #777e90;
  transition: all 0.2s;
}
.favorite:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M7.388 2.085a.67.67 0 0 1 1.224 0l1.453 3.368c.098.227.314.381.561.401l3.727.288a.67.67 0 0 1 .373 1.179l-2.803 2.314c-.198.163-.284.425-.223.674l.863 3.486a.67.67 0 0 1-.985.735l-3.24-1.907c-.209-.123-.468-.123-.676 0l-3.24 1.907a.67.67 0 0 1-.985-.735l.863-3.486c.062-.249-.025-.511-.223-.674L1.273 7.32a.67.67 0 0 1 .373-1.179l3.727-.288c.247-.019.463-.173.561-.401l1.453-3.368z' fill='%23ffd166'/%3E%3C/svg%3E")
    no-repeat 50% 50%/100% auto;
  opacity: 0;
  transition: opacity 0.2s;
}
.favorite:hover .icon {
  fill: #ffd166;
}
.favorite.active .icon {
  opacity: 0;
}
.favorite.active:after {
  opacity: 1;
}

.sorting {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  cursor: pointer;
}
.sorting:before,
.sorting:after {
  content: "";
  position: absolute;
  right: 0;
  width: 7px;
  height: 4px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
}
.sorting:before {
  top: calc(50% - 5px);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='4' fill='none' viewBox='0 0 7 4'%3E%3Cpath fill-rule='evenodd' d='M1.148 3.804c.26.26.682.26.943 0l1.862-1.862 1.862 1.862c.26.26.682.26.943 0s.26-.682 0-.943L4.425.528c-.26-.26-.682-.26-.943 0L1.148 2.862c-.26.26-.26.682 0 .943z' fill='%23777e91'/%3E%3C/svg%3E");
}
.sorting:after {
  top: calc(50%+1px);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='4' fill='none' viewBox='0 0 7 4'%3E%3Cpath fill-rule='evenodd' d='M1.148.195c.26-.26.682-.26.943 0l1.862 1.862L5.815.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.425 3.471c-.26.26-.682.26-.943 0L1.148 1.137c-.26-.26-.26-.682 0-.943z' fill='%23777e91'/%3E%3C/svg%3E");
}
.sorting.up:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='4' fill='none' viewBox='0 0 7 4'%3E%3Cpath fill-rule='evenodd' d='M1.148 3.804c.26.26.682.26.943 0l1.862-1.862 1.862 1.862c.26.26.682.26.943 0s.26-.682 0-.943L4.425.528c-.26-.26-.682-.26-.943 0L1.148 2.862c-.26.26-.26.682 0 .943z' fill='%2323262F'/%3E%3C/svg%3E");
}
.sorting.up:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='4' fill='none' viewBox='0 0 7 4'%3E%3Cpath fill-rule='evenodd' d='M1.148.195c.26-.26.682-.26.943 0l1.862 1.862L5.815.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.425 3.471c-.26.26-.682.26-.943 0L1.148 1.137c-.26-.26-.26-.682 0-.943z' fill='%23B1B5C3'/%3E%3C/svg%3E");
}
.sorting.down:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='4' fill='none' viewBox='0 0 7 4'%3E%3Cpath fill-rule='evenodd' d='M1.148 3.804c.26.26.682.26.943 0l1.862-1.862 1.862 1.862c.26.26.682.26.943 0s.26-.682 0-.943L4.425.528c-.26-.26-.682-.26-.943 0L1.148 2.862c-.26.26-.26.682 0 .943z' fill='%23B1B5C3'/%3E%3C/svg%3E");
}
.sorting.down:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='4' fill='none' viewBox='0 0 7 4'%3E%3Cpath fill-rule='evenodd' d='M1.148.195c.26-.26.682-.26.943 0l1.862 1.862L5.815.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.425 3.471c-.26.26-.682.26-.943 0L1.148 1.137c-.26-.26-.26-.682 0-.943z' fill='%2323262F'/%3E%3C/svg%3E");
}

.theme {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
}
.theme__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.theme__input:checked + .theme__inner .theme__box {
  background: #3b71fe;
}
.theme__input:checked + .theme__inner .theme__box:before {
  -webkit-transform: translate(20px, -50%);
  transform: translate(20px, -50%);
  background: #fcfcfd;
}
.theme__inner {
  position: relative;
  display: inline-block;
  transition: all 0.2s;
}
.theme__box {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  background: #e6e8ec;
  border-radius: 12px;
  transition: all 0.2s;
}
body.dark .theme__box {
  background: #353945;
}
.theme__box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #3b71fe;
  transition: all 0.2s;
}
.theme__icon {
  width: 24px;
  height: 24px;
}
.theme__icon .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
  transition: fill 0.2s;
}
.theme__icon .icon:nth-child(2) {
  display: none;
}
.theme__icon:hover .icon {
  fill: #23262f;
}
body.dark .theme__icon:hover .icon {
  fill: #fcfcfd;
}

.main__cards {
  display: flex;
  padding: 24px 8px;
  border-radius: 24px;
  background: #f4f5f6;
}
@media only screen and (max-width: 1023px) {
  .main__cards {
    margin: 0 -20px;
  }
}
@media only screen and (max-width: 767px) {
  .main__cards {
    display: block;
    margin: -32px -16px 0;
    padding: 24px;
  }
}
body.dark .main__cards {
  background: #23262f;
}
.main__card {
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  padding: 32px;
  border-radius: 16px;
  color: #23262f;
  transition: all 0.2s;
}
@media only screen and (max-width: 1179px) {
  .main__card {
    padding: 24px 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .main__card {
    padding: 16px 0;
  }
}
@media only screen and (max-width: 767px) {
  .main__card {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 16px 24px;
  }
}
body.dark .main__card {
  color: #fcfcfd;
}
.main__card:hover {
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  background: #fcfcfd;
}
@media only screen and (max-width: 1023px) {
  .main__card:hover {
    box-shadow: none;
    background: none;
  }
}
body.dark .main__card:hover {
  background: #353945;
}
@media only screen and (max-width: 1023px) {
  body.dark .main__card:hover {
    background: none;
  }
}
.main__icon {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 767px) {
  .main__icon {
    flex-shrink: 0;
    width: 40px;
    margin: 0 16px 0 0;
  }
}
.main__icon img {
  max-width: 100%;
  max-height: 100%;
}
@media only screen and (max-width: 767px) {
  .main__details {
    flex-grow: 1;
  }
}
.main__line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
}
.main__subtitle {
  margin-right: 12px;
  color: #777e90;
}
.main__positive,
.main__negative {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #fcfcfd;
}
.main__positive {
  background: #58bd7d;
}
.main__negative {
  background: #ff6838;
}
.main__price {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.main__money {
  font-size: 12px;
  line-height: 1.66667;
}
.scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  border: 2px solid #e6e8ec;
  transition: border-color 0.2s;
}
.scroll .icon {
  width: 24px;
  height: 24px;
  fill: #b1b5c3;
  transition: fill 0.2s;
}
.scroll:hover {
  border-color: #23262f;
}
.scroll:hover .icon {
  fill: #23262f;
}
body.dark .scroll {
  border-color: #353945;
}
body.dark .scroll:hover {
  border-color: #fcfcfd;
}
body.dark .scroll:hover .icon {
  fill: #fcfcfd;
}
.scroll__line {
  display: flex;
  flex-direction: column;
  -webkit-transform: translateY(-23px);
  transform: translateY(-23px);
  -webkit-animation: arrowsDown ease-out 1.4s infinite;
  animation: arrowsDown ease-out 1.4s infinite;
}
.scroll__line .icon:not(:last-child) {
  margin-bottom: 24px;
}
@-webkit-keyframes arrowsDown {
  80% {
    -webkit-transform: translateY(23px);
    transform: translateY(23px);
  }
  100% {
    -webkit-transform: translateY(23px);
    transform: translateY(23px);
  }
}
@keyframes arrowsDown {
  80% {
    -webkit-transform: translateY(23px);
    transform: translateY(23px);
  }
  100% {
    -webkit-transform: translateY(23px);
    transform: translateY(23px);
  }
}
.learn {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .learn {
    padding-bottom: 80px;
  }
}
.learn__anchor {
  top: -30px;
}
.learn__head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 80px;
}
@media only screen and (max-width: 1179px) {
  .learn__head {
    margin-bottom: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .learn__head {
    display: block;
  }
}
.learn__head .learn__button {
  flex-shrink: 0;
  margin-left: 40px;
}
@media only screen and (max-width: 767px) {
  .learn__head .learn__button {
    position: absolute;
    left: 32px;
    right: 32px;
    bottom: 0;
    width: calc(100% - 64px);
    margin: 0;
  }
}
.learn__wrap {
  flex-grow: 1;
}
.learn__title {
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .learn__title {
    margin-bottom: 24px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .learn .nav {
    margin: 0 -32px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .learn .nav::-webkit-scrollbar {
    display: none;
  }
  .learn .nav:before,
  .learn .nav:after {
    content: "";
    flex-shrink: 0;
    width: 32px;
    height: 1px;
  }
}
@media only screen and (max-width: 767px) {
  .learn .nav__link {
    flex-shrink: 0;
  }
}
.learn .nav__link:not(:last-child) {
  margin-right: 24px;
}
@media only screen and (max-width: 767px) {
  .learn .nav__link:not(:last-child) {
    margin-right: 20px;
  }
}
.learn__wrapper {
  margin: -32px -18px 0;
}
@media only screen and (max-width: 1179px) {
  .learn__wrapper {
    margin: 0 -16px;
  }
}
.learn__wrapper:after {
  content: " ";
  display: table;
  clear: both;
}
@media only screen and (max-width: 1179px) {
  .learn__wrapper:after {
    display: none;
  }
}
.learn__item {
  display: block;
  width: calc(50% - 36px);
  margin: 32px 18px 0;
  float: right;
  color: #23262f;
}
@media only screen and (max-width: 1179px) {
  .learn__item {
    float: left;
    width: 100%;
    margin: 0;
  }
}
body.dark .learn__item {
  color: #fcfcfd;
}
.learn__item:hover .learn__preview img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.learn__item:hover .learn__subtitle {
  color: #3b71fe;
}
.learn__item:first-child {
  float: left;
}
@media only screen and (max-width: 1179px) {
  .learn__item:first-child {
    float: left;
  }
}
.learn__item:first-child .learn__preview {
  margin-bottom: 40px;
  border-radius: 20px;
}
@media only screen and (max-width: 1179px) {
  .learn__item:first-child .learn__preview {
    margin-bottom: 24px;
    border-radius: 16px;
  }
}
.learn__item:first-child .learn__subtitle {
  margin-bottom: 16px;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 1179px) {
  .learn__item:first-child .learn__subtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 767px) {
  .learn__item:first-child .learn__subtitle {
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.learn__item:first-child .learn__content {
  font-size: 16px;
  line-height: 1.5;
}
@media only screen and (max-width: 1179px) {
  .learn__item:first-child .learn__content {
    font-size: 14px;
  }
}
.learn__item:not(:first-child) {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 60px;
}
@media only screen and (max-width: 1179px) {
  .learn__item:not(:first-child) {
    display: block;
    padding: 0;
  }
}
.learn__item:not(:first-child) .learn__preview {
  flex: 0 0 45.65%;
  border-radius: 16px;
}
.learn__item:not(:first-child) .learn__subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 1179px) {
  .learn__item:not(:first-child) .learn__subtitle {
    font-size: 24px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .learn__item:not(:first-child) .learn__subtitle {
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.learn__item:not(:first-child) .learn__content {
  margin-bottom: auto;
}
.learn__preview {
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 1179px) {
  .learn__preview {
    margin-bottom: 24px;
    border-radius: 16px;
  }
  .learn__preview:before {
    content: "";
    display: block;
    padding-bottom: 77%;
  }
}
.learn__preview img {
  width: 100%;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
@media only screen and (max-width: 1179px) {
  .learn__preview img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.learn__line {
  display: flex;
}
.learn__line .learn__button {
  flex-shrink: 0;
  margin-left: 32px;
}
@media only screen and (max-width: 1179px) {
  .learn__line .learn__button {
    display: none;
  }
}
.learn__subtitle {
  transition: color 0.2s;
}
@media only screen and (max-width: 1179px) {
  .learn__subtitle {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .learn__subtitle {
    margin-bottom: 8px;
  }
}
.learn__content {
  color: #777e90;
}
.learn__details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 16px;
}
@media only screen and (max-width: 1179px) {
  .learn__details {
    display: block;
    padding: 0;
  }
}
.learn__date {
  margin-top: 24px;
  color: #777e90;
}
@media only screen and (max-width: 1179px) {
  .learn__date {
    display: none;
  }
}
@media only screen and (max-width: 1179px) {
  .learn__slider {
    padding-bottom: 72px;
    visibility: hidden;
  }
  .learn__slider.slick-initialized {
    visibility: visible;
  }
}
.learn .slick-list {
  overflow: visible;
}
.learn .slick-slide {
  padding: 0 16px;
  opacity: 0;
  transition: opacity 0.4s;
}
.learn .slick-slide.slick-active {
  opacity: 1;
}
.learn .slick-arrow {
  bottom: 0;
}
.learn .slick-prev {
  left: 16px;
}
@media only screen and (max-width: 767px) {
  .learn .slick-prev {
    left: calc(50% - 48px);
  }
}
.learn .slick-next {
  left: 64px;
}
@media only screen and (max-width: 767px) {
  .learn .slick-next {
    left: auto;
    right: calc(50% - 48px);
  }
}
.nav {
  display: flex;
}
.nav__link {
  display: flex;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: all 0.2s;
  cursor: pointer;
}
.nav__link .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  fill: #777e90;
  transition: fill 0.2s;
}
.nav__link:hover {
  color: #353945;
}
.nav__link:hover .icon {
  fill: #353945;
}
body.dark .nav__link:hover {
  color: #e6e8ec;
}
body.dark .nav__link:hover .icon {
  fill: #e6e8ec;
}
.nav__link.active {
  background: #353945;
  color: #fcfcfd;
}
.nav__link.active .icon {
  fill: #fcfcfd;
}
body.dark .nav__link.active {
  background: #353945;
  color: #fcfcfd;
}
body.dark .nav__link.active .icon {
  fill: #fcfcfd;
}
.nav__link:not(:last-child) {
  margin-right: 8px;
}
@media only screen and (max-width: 767px) {
  .trend {
    position: relative;
    padding-bottom: 80px;
  }
}
.trend__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .trend__line {
    display: block;
    margin-bottom: 32px;
  }
}
.trend__line .trend__button {
  flex-shrink: 0;
  margin-left: 40px;
}
@media only screen and (max-width: 767px) {
  .trend__line .trend__button {
    position: absolute;
    left: 32px;
    right: 32px;
    bottom: 0;
    width: calc(100% - 64px);
    margin: 0;
  }
}
.trend .nav {
  margin-bottom: 64px;
}
@media only screen and (max-width: 767px) {
  .trend .nav {
    display: none;
  }
}
.trend .nav__link:not(:last-child) {
  margin-right: 24px;
}
.trend .select {
  display: none;
}
@media only screen and (max-width: 767px) {
  .trend .select {
    display: block;
    margin-bottom: 32px;
  }
}
.trend__table {
  display: table;
  width: 100%;
}
.trend__row {
  display: table-row;
  color: #23262f;
}
.trend__row:first-child .trend__col {
  padding: 24px 16px;
  border-bottom: 1px solid #e6e8ec;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .trend__row:first-child .trend__col {
    padding: 8px;
  }
  .trend__row:first-child .trend__col:nth-child(2) {
    padding-left: 0;
  }
  .trend__row:first-child .trend__col:last-child {
    padding-right: 0;
  }
}
body.dark .trend__row:first-child .trend__col {
  border-color: #353945;
}
.trend__row:not(:first-child) {
  border-radius: 12px;
  transition: background 0.2s;
}
.trend__row:not(:first-child) .trend__col {
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .trend__row:not(:first-child) .trend__col {
    font-size: 12px;
  }
}
.trend__row:not(:first-child) .trend__col:first-child {
  border-radius: 12px 0 0 12px;
  color: #777e90;
}
@media only screen and (max-width: 1023px) {
  .trend__row:not(:first-child) .trend__col:first-child {
    border-radius: 0;
  }
}
.trend__row:not(:first-child) .trend__col:last-child {
  border-radius: 0 12px 12px 0;
}
@media only screen and (max-width: 1023px) {
  .trend__row:not(:first-child) .trend__col:last-child {
    border-radius: 0;
  }
}
.trend__row:not(:first-child):hover {
  background: #f4f5f6;
}
@media only screen and (max-width: 1023px) {
  .trend__row:not(:first-child):hover {
    background: none;
  }
}
body.dark .trend__row:not(:first-child):hover {
  background: #23262f;
}
.trend__col {
  display: table-cell;
  padding: 20px 16px;
}
@media only screen and (max-width: 1023px) {
  .trend__col:nth-child(5) {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .trend__col {
    padding: 8px 8px 6px;
    font-size: 12px;
  }
  .trend__col:first-child,
  .trend__col:nth-child(6) {
    display: none;
  }
  .trend__col:nth-child(2) {
    padding-left: 0;
  }
  .trend__col:last-child {
    padding-right: 0;
  }
}
body.dark .trend__col {
  color: #fcfcfd;
}
.trend__item {
  display: flex;
  align-items: center;
}
.trend__icon {
  flex-shrink: 0;
  width: 40px;
  margin-right: 32px;
}
@media only screen and (max-width: 767px) {
  .trend__icon {
    margin-right: 16px;
  }
}
.trend__icon img {
  max-width: 100%;
}
.trend__currency {
  margin-left: 12px;
  color: #b1b5c3;
}
@media only screen and (max-width: 767px) {
  .trend__currency {
    display: none;
  }
}
.trend__positive {
  color: #58bd7d;
}
.trend__negative {
  color: #ff6838;
}
.trend__chart {
  width: 136px;
  margin: -17px 0;
}
@media only screen and (max-width: 1179px) {
  .popular__center {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .popular__center {
    padding: 0 32px;
  }
}
.popular__head {
  max-width: 455px;
  margin: 0 auto 64px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .popular__head {
    margin-bottom: 48px;
    text-align: left;
  }
}
.popular__title {
  margin-bottom: 20px;
}
.popular__info {
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.popular__wrapper {
  margin: 0 -12px;
}
@media only screen and (max-width: 767px) {
  .popular__wrapper {
    margin: 0 -4px;
  }
}
.popular__item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 50px 32px;
  border-radius: 20px;
  background: #fcfcfd;
  text-align: center;
  transition: all 0.2s;
}
.popular__item:hover {
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
}
body.dark .popular__item {
  background: #23262f;
  box-shadow: inset 0 0 0 2px #23262f;
}
body.dark .popular__item:hover {
  background: transparent;
}
.popular__item .popular__button {
  margin-top: auto;
}
.popular__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  margin: 0 auto 32px;
}
.popular__preview img {
  max-width: 100%;
  max-height: 100%;
}
.popular__subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.popular__content {
  margin-bottom: 16px;
  color: #777e90;
}
.popular__btns {
  margin-top: 64px;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .popular__btns {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .popular__btns .popular__button {
    width: 100%;
  }
}
.popular__slider {
  visibility: hidden;
}
@media only screen and (max-width: 1023px) {
  .popular__slider {
    padding-bottom: 72px;
  }
}
.popular__slider.slick-initialized {
  visibility: visible;
}
.popular .slick-list {
  overflow: visible;
}
.popular .slick-track {
  display: flex;
}
.popular .slick-slide {
  height: auto;
  margin: 0 12px;
  opacity: 0;
  transition: opacity 0.4s;
}
@media only screen and (max-width: 767px) {
  .popular .slick-slide {
    margin: 0 4px;
    opacity: 1;
  }
}
.popular .slick-slide.slick-active {
  opacity: 1;
}
.popular .slick-arrow {
  bottom: 0;
}
.popular .slick-prev {
  left: calc(50% - 50px);
}
.popular .slick-next {
  right: calc(50% - 50px);
}
.download {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 908px;
  padding: 136px 0;
}
@media only screen and (max-width: 1179px) {
  .download {
    min-height: 764px;
    padding: 112px 0;
  }
}
@media only screen and (max-width: 767px) {
  .download {
    min-height: auto;
    padding: 8px 0 64px;
  }
}
.download__wrap {
  position: relative;
  z-index: 3;
  max-width: 450px;
}
@media only screen and (max-width: 1023px) {
  .download__wrap {
    max-width: 300px;
  }
}
.download__title {
  margin-bottom: 16px;
}
.download__info {
  margin-bottom: 64px;
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .download__info {
    margin-bottom: 48px;
  }
}
.download__list {
  max-width: 348px;
}
.download__item {
  display: flex;
  align-items: center;
}
.download__item:hover .download__icon:after {
  opacity: 1;
  visibility: visible;
}
.download__item:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .download__item:not(:last-child) {
  border-color: #353945;
}
.download__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 24px;
  border-radius: 50%;
  background: #23262f;
}
.download__icon img {
  max-width: 24px;
}
.download__icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #3b71fe
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' fill='none' viewBox='0 0 14 9'%3E%3Cpath fill-rule='evenodd' d='M9.091.265a1 1 0 0 1 1.413.057l3.231 3.5a1 1 0 0 1 0 1.357l-3.231 3.5a1 1 0 0 1-1.47-1.357L10.716 5.5H1a1 1 0 1 1 0-2h9.716L9.034 1.678A1 1 0 0 1 9.091.265z' fill='%23fcfcfd'/%3E%3C/svg%3E")
    no-repeat 50% 50%/14px auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}
.download__details {
  flex-grow: 1;
}
.download__subtitle {
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -0.01em;
  color: #23262f;
}
body.dark .download__subtitle {
  color: #fcfcfd;
}
.download__content {
  color: #777e90;
}
.download__bg {
  position: absolute;
  top: 50%;
  right: calc(50% - 750px);
  width: 800px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}
@media only screen and (max-width: 1179px) {
  .download__bg {
    right: calc(50% - 620px);
    width: 620px;
  }
}
@media only screen and (max-width: 1023px) {
  .download__bg {
    right: calc(50% - 520px);
    width: 540px;
  }
}
@media only screen and (max-width: 767px) {
  .download__bg {
    position: static;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: auto;
    margin-right: -32px;
  }
}
.download__bg img {
  width: 100%;
}
.news__center {
  max-width: 1132px;
}
.news__head {
  max-width: 545px;
  margin: 0 auto 80px;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .news__head {
    margin-bottom: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .news__head {
    margin-bottom: 24px;
  }
}
.news__logo {
  margin-bottom: 16px;
}
.news__title {
  margin-bottom: 20px;
}
.news__info {
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.news__item {
  position: relative;
  display: flex !important;
  width: 100%;
  min-height: 544px;
  padding: 80px 96px;
  border-radius: 24px;
  background: #fcfcfd;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
}
@media only screen and (max-width: 1179px) {
  .news__item {
    padding: 80px;
  }
}
@media only screen and (max-width: 1023px) {
  .news__item {
    display: block !important;
    padding: 0;
    min-height: auto;
    box-shadow: none;
    background: none;
  }
}
body.dark .news__item {
  background: #23262f;
}
@media only screen and (max-width: 1023px) {
  body.dark .news__item {
    background: none;
  }
}
.news__preview {
  position: absolute;
  top: -30px;
  right: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 563px;
  height: 563px;
}
@media only screen and (max-width: 1179px) {
  .news__preview {
    top: 20px;
    width: 500px;
    height: 500px;
  }
}
@media only screen and (max-width: 1023px) {
  .news__preview {
    position: static;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 639px) {
  .news__preview {
    width: 100%;
    height: 320px;
  }
}
.news__preview img {
  max-width: 100%;
  max-height: 100%;
}
.news__wrap {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 352px;
}
@media only screen and (max-width: 1023px) {
  .news__wrap {
    display: block;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }
}
.news__stage {
  margin-top: auto;
}
.news__subtitle {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -0.01em;
}
.news__content {
  margin-bottom: auto;
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.news__button {
  margin-top: 48px;
}
.news__slider {
  visibility: hidden;
}
@media only screen and (max-width: 767px) {
  .news__slider {
    padding-bottom: 72px;
  }
}
.news__slider.slick-initialized {
  visibility: visible;
}
.news .slick-list {
  overflow: visible;
}
.news .slick-slide {
  opacity: 0;
  transition: opacity 0.4s;
}
.news .slick-slide.slick-active {
  opacity: 1;
}
.news .slick-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .news .slick-arrow {
    top: auto;
    bottom: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.news .slick-prev {
  left: -96px;
}
@media only screen and (max-width: 1419px) {
  .news .slick-prev {
    left: -64px;
  }
}
@media only screen and (max-width: 1179px) {
  .news .slick-prev {
    left: -48px;
  }
}
@media only screen and (max-width: 1023px) {
  .news .slick-prev {
    left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .news .slick-prev {
    left: calc(50% - 48px);
  }
}
.news .slick-next {
  right: -96px;
}
@media only screen and (max-width: 1419px) {
  .news .slick-next {
    right: -64px;
  }
}
@media only screen and (max-width: 1179px) {
  .news .slick-next {
    right: -48px;
  }
}
@media only screen and (max-width: 1023px) {
  .news .slick-next {
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .news .slick-next {
    right: calc(50% - 48px);
  }
}
.steps__head {
  max-width: 380px;
  margin: 0 auto 80px;
  text-align: center;
}
@media only screen and (max-width: 1179px) {
  .steps__head {
    margin-bottom: 64px;
  }
}
@media only screen and (max-width: 767px) {
  .steps__head {
    margin-bottom: 40px;
  }
}
.steps__title {
  margin-bottom: 20px;
}
.steps__info {
  color: #353945;
}
body.dark .steps__info {
  color: #e6e8ec;
}
.steps__list {
  display: flex;
  margin: 0 -16px;
}
@media only screen and (max-width: 1023px) {
  .steps__list {
    margin: 0 -40px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .steps__list::-webkit-scrollbar {
    display: none;
  }
  .steps__list:before,
  .steps__list:after {
    content: "";
    flex-shrink: 0;
    width: 40px;
    height: 1px;
  }
}
.steps__item {
  position: relative;
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .steps__item {
    flex: 0 0 256px;
    width: 256px;
    margin: 0;
  }
}
@media only screen and (max-width: 767px) {
  .steps__item {
    text-align: left;
  }
}
.steps__item:after {
  content: "";
  position: absolute;
  top: 47px;
  right: -84px;
  width: 130px;
  height: 2px;
  background-image: linear-gradient(90deg, #b1b5c3 0, #b1b5c3 6px, transparent 6px, transparent 14px);
  background-repeat: repeat-x;
  background-size: 12px auto;
}
@media only screen and (max-width: 1259px) {
  .steps__item:after {
    right: -66px;
    width: 100px;
  }
}
@media only screen and (max-width: 1179px) {
  .steps__item:after {
    right: -50px;
    width: 60px;
  }
}
@media only screen and (max-width: 1023px) {
  .steps__item:after {
    right: -79px;
    width: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .steps__item:after {
    right: 0;
  }
}
.steps__item:first-child .steps__preview:before {
  display: none;
}
.steps__item:last-child:after {
  display: none;
}
.steps__item:last-child .steps__preview:after {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .steps__item:not(:last-child) {
    margin-right: 32px;
  }
}
.steps__preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  margin: 0 auto 80px;
  border-radius: 24px;
}
@media only screen and (max-width: 767px) {
  .steps__preview {
    margin: 0 0 32px 0;
  }
}
.steps__preview:before,
.steps__preview:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 2px solid #b1b5c3;
}
.steps__preview:before {
  left: -24px;
}
.steps__preview:after {
  right: -24px;
}
.steps__preview img {
  width: 100%;
}
.steps__number {
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.steps__subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.steps__content {
  color: #353945;
}
body.dark .steps__content {
  color: #e6e8ec;
}

.home {
  position: relative;
  padding-top: 156px;
  min-height: 692px;
  background: #decbe9;
}
@media only screen and (max-width: 767px) {
  .home {
    min-height: auto;
    padding: 64px 0 112px;
  }
}
body.dark .home {
  background: #18191d;
}
.home__wrap {
  position: relative;
  z-index: 3;
  max-width: 545px;
  margin-bottom: 143px;
}
@media only screen and (max-width: 767px) {
  .home__wrap {
    margin-bottom: 8px;
  }
}
.home__title {
  margin-bottom: 32px;
}
@media only screen and (max-width: 767px) {
  .home__title {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 48px;
  }
}
.home__text {
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -0.01em;
  color: #353945;
}
@media only screen and (max-width: 767px) {
  .home__text {
    font-size: 16px;
  }
}
body.dark .home__text {
  color: #b1b5c3;
}
.home__text strong {
  font-weight: 600;
}
.home__bg {
  position: absolute;
  top: 0;
  right: calc(50% - 760px);
  width: 780px;
  pointer-events: none;
}
@media only screen and (max-width: 1179px) {
  .home__bg {
    right: calc(50% - 820px);
  }
}
@media only screen and (max-width: 767px) {
  .home__bg {
    position: static;
    width: auto;
    margin: 0 -76px 24px -30px;
  }
}
.home__bg img {
  width: 100%;
}
.dashbordi {
  position: relative;
  margin: -137px 0 72px;
}
.dashbordi__wrapper {
  border-radius: 24px;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  background: #fcfcfd;
  border: 1px solid #f4f5f6;
}
@media only screen and (max-width: 767px) {
  .dashbordi__wrapper {
    margin: 0 -16px 32px;
  }
}
body.dark .dashbordi__wrapper {
  background: #18191d;
  border-color: #23262f;
}
.dashbordi__list {
  display: flex;
  margin: 0 -18px;
}
@media only screen and (max-width: 1023px) {
  .dashbordi__list {
    margin: 0;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .dashbordi__list::-webkit-scrollbar {
    display: none;
  }
  .dashbordi__list:before,
  .dashbordi__list:after {
    content: "";
    flex-shrink: 0;
    width: 24px;
    height: 1px;
  }
}
.dashbordi__item {
  display: flex;
  flex: 0 0 calc(33.333% - 36px);
  width: calc(33.333% - 36px);
  margin: 0 18px;
  padding: 24px;
  color: #23262f;
}
@media only screen and (max-width: 1023px) {
  .dashbordi__item {
    flex: 0 0 250px;
    width: 250px;
    margin: 0;
    padding: 24px 0;
  }
}
@media only screen and (max-width: 767px) {
  .dashbordi__item {
    flex: 0 0 220px;
    width: 220px;
  }
}
body.dark .dashbordi__item {
  color: #fcfcfd;
}
.dashbordi__item:hover .dashbordi__price {
  color: #3b71fe;
}
.dashbordi__icon {
  flex-shrink: 0;
  width: 40px;
  margin-right: 16px;
}
.dashbordi__line {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.dashbordi__title {
  margin-right: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.dashbordi__positive,
.dashbordi__negative {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #fcfcfd;
}
.dashbordi__positive {
  background: #58bd7d;
}
.dashbordi__negative {
  background: #ff6838;
}
.dashbordi__price {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
  transition: color 0.2s;
}
.dashbordi__money {
  font-size: 12px;
  line-height: 1.66667;
}
.dashbordi__foot {
  display: flex;
  align-items: center;
  padding: 34px 32px;
  border-top: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .dashbordi__foot {
    display: block;
  }
}
body.dark .dashbordi__foot {
  border-color: #23262f;
}
.dashbordi .nav {
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .dashbordi .nav {
    display: none;
  }
}
.dashbordi .nav__link:not(:last-child) {
  margin-right: 12px;
}
@media only screen and (max-width: 1023px) {
  .dashbordi .nav__link:not(:last-child) {
    margin-right: 6px;
  }
}
@media only screen and (max-width: 767px) {
  .dashbordi__button {
    display: none;
  }
}
.dashbordi .select {
  display: none;
}
@media only screen and (max-width: 767px) {
  .dashbordi .select {
    display: block;
    width: 100%;
  }
  body.dark .dashbordi .select {
    background: none;
  }
}
.dashbordi__chart {
  width: 100px;
  margin: -16px 0 0 6px;
}
@media only screen and (max-width: 1339px) {
  .dashbordi__chart {
    flex-grow: 1;
  }
}
@media only screen and (max-width: 1179px) {
  .dashbordi__chart {
    display: none;
  }
}
.market__table {
  display: table;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .market__table {
    display: block;
  }
}
.market__row {
  display: table-row;
  color: #23262f;
}
@media only screen and (max-width: 1023px) {
  .market__row {
    position: relative;
    display: block;
  }
}
@media only screen and (max-width: 1023px) {
  .market__row:first-child {
    display: none;
  }
}
.market__row:first-child .market__col {
  padding: 0 16px 32px;
  border-bottom: 1px solid #e6e8ec;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
body.dark .market__row:first-child .market__col {
  border-color: #23262f;
}
.market__row:first-child .market__col .icon {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  fill: #777e90;
}
.market__row:not(:first-child) {
  transition: background 0.2s;
}
.market__row:not(:first-child) .market__col {
  vertical-align: middle;
  font-weight: 500;
}
.market__row:not(:first-child) .market__col:first-child {
  border-radius: 12px 0 0 12px;
  color: #777e90;
}
@media only screen and (max-width: 1023px) {
  .market__row:not(:first-child) .market__col:first-child {
    border-radius: 0;
  }
}
.market__row:not(:first-child) .market__col:last-child {
  border-radius: 0 12px 12px 0;
}
@media only screen and (max-width: 1023px) {
  .market__row:not(:first-child) .market__col:last-child {
    border-radius: 0;
  }
}
.market__row:not(:first-child):hover {
  background: #f4f5f6;
}
body.dark .market__row:not(:first-child):hover {
  background: #23262f;
}
.market__row:not(:first-child):hover .market__button {
  display: inline-flex;
}
.market__row:not(:first-child):hover .market__chart {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .market__row:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e8ec;
  }
  body.dark .market__row:not(:last-child) {
    border-color: #353945;
  }
}
.market__col {
  display: table-cell;
  padding: 16px;
  text-align: right;
  font-weight: 500;
}
@media only screen and (max-width: 1023px) {
  .market__col {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 0;
  }
}
body.dark .market__col {
  color: #fcfcfd;
}
.market__col:first-child,
.market__col:nth-child(2) {
  text-align: left;
}
@media only screen and (max-width: 1023px) {
  .market__col:nth-child(2) {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 1179px) {
  .market__col:nth-last-child(2) {
    display: none;
  }
}
.market__col:first-child {
  font-size: 12px;
}
@media only screen and (max-width: 1023px) {
  .market__col:first-child {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .market__col:last-child {
    margin-bottom: 0;
  }
}
.market__line {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.market .favorite {
  position: relative;
  top: -1px;
  margin-right: 10px;
}
.market__item {
  display: flex;
  align-items: center;
}
.market__icon {
  flex-shrink: 0;
  width: 32px;
  margin-right: 12px;
}
.market__icon img {
  max-width: 100%;
}
.market__subtitle {
  margin-right: 4px;
}
.market__currency {
  font-weight: 400;
  color: #777e90;
}
.market__positive {
  color: #58bd7d;
}
.market__negative {
  color: #ff6838;
}
.market__button {
  display: none;
  width: 96px;
}
@media only screen and (max-width: 1179px) {
  .market__button {
    display: inline-flex;
  }
}
@media only screen and (max-width: 1023px) {
  .market__button {
    position: absolute;
    top: -6px;
    right: 0;
  }
}
.market__chart {
  width: 96px;
  margin: -15px 0 -15px auto;
}
@media only screen and (max-width: 1179px) {
  .market__chart {
    display: none;
  }
}
.market__label {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .market__label {
    display: block;
    margin-right: auto;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777e90;
  }
}
.loader {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  font-size: 4px;
  -webkit-animation: loader 1.1s infinite ease;
  animation: loader 1.1s infinite ease;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
body.dark .loader {
  -webkit-animation-name: loader-white;
  animation-name: loader-white;
}
.loader_white {
  -webkit-animation-name: loader-white;
  animation-name: loader-white;
}
@-webkit-keyframes loader {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.5),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.5), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7), 2.5em 0em 0 0em #777e90,
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.5), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.7), 0em 2.5em 0 0em #777e90,
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.5),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.5), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.7), -2.6em 0em 0 0em #777e90,
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.5),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.7), -1.8em -1.8em 0 0em #777e90;
  }
}
@keyframes loader {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #777e90, 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2), 2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.5),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.7), 1.8em -1.8em 0 0em #777e90, 2.5em 0em 0 0em rgba(119, 126, 144, 0.2),
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.5), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.7), 2.5em 0em 0 0em #777e90,
      1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2), 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.5),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.7), 1.75em 1.75em 0 0em #777e90, 0em 2.5em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.5), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.7), 0em 2.5em 0 0em #777e90,
      -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.2), -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.5),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.7), -1.8em 1.8em 0 0em #777e90, -2.6em 0em 0 0em rgba(119, 126, 144, 0.2),
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.5), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.7), -2.6em 0em 0 0em #777e90,
      -1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(119, 126, 144, 0.2), 1.8em -1.8em 0 0em rgba(119, 126, 144, 0.2),
      2.5em 0em 0 0em rgba(119, 126, 144, 0.2), 1.75em 1.75em 0 0em rgba(119, 126, 144, 0.2),
      0em 2.5em 0 0em rgba(119, 126, 144, 0.2), -1.8em 1.8em 0 0em rgba(119, 126, 144, 0.5),
      -2.6em 0em 0 0em rgba(119, 126, 144, 0.7), -1.8em -1.8em 0 0em #777e90;
  }
}
@-webkit-keyframes loader-white {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes loader-white {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
.lessons__head {
  max-width: 455px;
  margin: 0 auto 64px;
  text-align: center;
}
@media only screen and (max-width: 1179px) {
  .lessons__head {
    margin-bottom: 48px;
  }
}
.lessons__title {
  margin-bottom: 20px;
}
.lessons__info {
  font-size: 16px;
  line-height: 1.5;
  color: #353945;
}
body.dark .lessons__info {
  color: #b1b5c3;
}
.lessons__list {
  display: flex;
  flex-wrap: wrap;
  margin: -48px -16px 0;
}
@media only screen and (max-width: 767px) {
  .lessons__list {
    display: block;
    margin: 0;
  }
}
.lessons__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 48px 16px 0;
  padding-bottom: 48px;
  border-bottom: 2px solid #e6e8ec;
  color: #23262f;
  transition: color 0.2s;
}
@media only screen and (max-width: 1023px) {
  .lessons__item {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
  }
}
@media only screen and (max-width: 767px) {
  .lessons__item {
    width: 100%;
    margin: 32px 0 0;
    padding-bottom: 0;
    border: none;
  }
}
body.dark .lessons__item {
  border-color: #353945;
  color: #fcfcfd;
}
.lessons__item:hover {
  color: #3b71fe;
}
.lessons__item:hover .lessons__preview img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.lessons__item:nth-child(n + 4) {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .lessons__item:nth-child(n + 4) {
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .lessons__item:nth-child(n + 4) {
    display: none;
  }
}
.lessons__preview {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 48px;
  border-radius: 12px;
}
@media only screen and (max-width: 767px) {
  .lessons__preview {
    margin-bottom: 32px;
  }
}
.lessons__preview:before {
  content: "";
  display: block;
  padding-bottom: 57%;
}
@media only screen and (max-width: 1179px) {
  .lessons__preview:before {
    padding-bottom: 75%;
  }
}
.lessons__preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.lessons__category {
  margin-bottom: 16px;
}
.lessons__subtitle {
  margin-bottom: 48px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 767px) {
  .lessons__subtitle {
    margin-bottom: 32px;
  }
}
.lessons__line {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  font-weight: 500;
  color: #777e90;
}
.lessons__author {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.lessons__avatar {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
  background: #58bd7d;
}
.lessons__avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.lessons__btns {
  margin-top: 64px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .lessons__btns {
    margin-top: 48px;
  }
}
.lessons__button .loader {
  margin: 0 16px 0 5px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.onex_d {
  padding-top: 80px;
}
@media only screen and (max-width: 767px) {
  .onex_d {
    padding-top: 64px;
  }
}
.onex_d__head {
  max-width: 730px;
  margin: 0 auto 80px;
  text-align: center;
  z-index: 1;
  position: relative;
}
.onex_d__title {
  margin-bottom: 16px;
}
.onex_d__info {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -0.01em;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .onex_d__info {
    font-size: 16px;
  }
}
.onex_d__btns {
  display: flex;
  justify-content: center;
}
.onex_d__btns.ssv {
  margin-bottom: 50px;
  margin-top: -10px;
}
.onex_d__button:not(:last-child) {
  margin-right: 16px;
}
.onex_d__btns.ssv a {
  min-width: 140px;
}
.onex_d__video {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .onex_d__video:before {
    content: "";
    display: block;
    padding-bottom: 122%;
  }
}
.onex_d__video img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .onex_d__video img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.releases__list {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.5rem;
}
.releases {
  position: relative;
}
.releases__anchor {
  top: 48px;
}
.releases__head {
  display: flex;
  margin-bottom: 80px;
}
@media only screen and (max-width: 1023px) {
  .releases__head {
    display: block;
    margin-bottom: 48px;
  }
}
.releases__stage {
  width: 352px;
}
@media only screen and (max-width: 1023px) {
  .releases__stage {
    width: 100%;
    margin-bottom: 20px;
  }
}
.releases__wrap {
  flex-shrink: 0;
  width: 640px;
  margin-left: auto;
}
@media only screen and (max-width: 1179px) {
  .releases__wrap {
    width: 500px;
  }
}
@media only screen and (max-width: 1023px) {
  .releases__wrap {
    width: 100%;
  }
}
.releases__title {
  margin-bottom: 20px;
}

.releases__info {
  max-width: 450px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 1.5;
  color: #353945;
}
@media only screen and (max-width: 1023px) {
  .releases__info {
    margin-bottom: 48px;
  }
}
body.dark .releases__info {
  color: #f4f5f6;
}

.releases .nav__link:not(:last-child) {
  margin-right: 12px;
}
.releases .select {
  display: none;
  background: transparent;
}
@media only screen and (max-width: 767px) {
  .releases .select {
    display: block;
  }
}
body.dark .releases .select {
  background: none;
}
.releases__item {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #23262f;
  transition: color .2s;
  align-items: center;
  background: white;
    padding: 1.5rem;
    border-radius: .5rem;
    border: 2px solid #e5e7eb;
}

a.releases__item {
  cursor: pointer;
}

body.dark .releases__item {
  color: #fcfcfd;
}
.releases__item:hover {
  color: #3b71fe;
}
.releases__item:hover .releases__arrow {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.releases__item:hover .releases__arrow .icon {
  fill: #3b71fe;
}
/* .releases__item:not(:last-child) {
  margin-bottom: 30px;
} */
.releases__preview {
  flex-shrink: 0;
  width: 100%;
  margin-left: 0;
}

.releases__preview img {
  width: 100%;
    border-radius: 16px;
    max-width: initial;
    height: 200px;
    object-fit: cover;
}


.releases__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.releases__details h4.h4 {
  font-size: 22px;
  line-height: normal;
}


.releases__category {
  margin-bottom: auto;
}
.releases__subtitle {
  margin: 24px 0 8px;
}
@media only screen and (max-width: 1023px) {
  .releases__subtitle {
    margin-top: 16px;
  }
}
.releases__onex_d {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #353945;
}
body.dark .releases__onex_d {
  color: #b1b5c3;
}
.releases__content {
  margin-bottom: auto;
    color: #777e90;
    line-height: 1.5rem;
    font-weight: 600;
}
.releases__arrow {
  position: absolute;
  top: calc(100% + 14px);
  left: 0;
  width: 24px;
  height: 24px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;

  display: none;
}
@media only screen and (max-width: 1023px) {
  .releases__arrow {
    position: static;
    margin-top: 16px;
  }
}
.releases__arrow .icon {
  width: 14px;
  height: 14px;
  fill: #777e90;
  transition: fill 0.2s;
}
.catalog {
  position: relative;
}
.catalog__anchor {
  top: 48px;
}
.catalog__form {
  position: relative;
  margin-bottom: 48px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .catalog__form {
    margin-bottom: 24px;
  }
}
body.dark .catalog__form {
  border-color: #353945;
}
.catalog__input {
  width: 100%;
  height: 48px;
  padding-right: 64px;
  border-radius: 0;
  background: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #23262f;
}
@media only screen and (max-width: 767px) {
  .catalog__input {
    font-size: 16px;
    font-weight: 500;
  }
}
.catalog__input::-webkit-input-placeholder {
  color: #23262f;
}
.catalog__input::-moz-placeholder {
  color: #23262f;
}
.catalog__input:-ms-input-placeholder {
  color: #23262f;
}
.catalog__input::-ms-input-placeholder {
  color: #23262f;
}
.catalog__input::placeholder {
  color: #23262f;
}
body.dark .catalog__input {
  color: #fcfcfd;
}
body.dark .catalog__input::-webkit-input-placeholder {
  color: #e6e8ec;
}
body.dark .catalog__input::-moz-placeholder {
  color: #e6e8ec;
}
body.dark .catalog__input:-ms-input-placeholder {
  color: #e6e8ec;
}
body.dark .catalog__input::-ms-input-placeholder {
  color: #e6e8ec;
}
body.dark .catalog__input::placeholder {
  color: #e6e8ec;
}
.catalog__result {
  position: absolute;
  top: 0;
  right: 0;
}
.catalog__result .icon {
  width: 24px;
  height: 24px;
  margin: 0;
}
.catalog__sorting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
}
@media only screen and (max-width: 767px) {
  .catalog__sorting {
    display: block;
  }
}
.catalog .select {
  width: 256px;
}
@media only screen and (max-width: 767px) {
  .catalog .select {
    width: 100%;
  }
  .catalog .select:not(:last-child) {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .catalog .nav {
    display: none;
  }
}
.catalog .nav__link:not(:last-child) {
  margin-right: 12px;
}
.catalog__list,
.catalog__group {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0;
}
@media only screen and (max-width: 767px) {
  .catalog__list,
  .catalog__group {
    display: block;
    margin: 0;
  }
}
.catalog .card {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 32px 16px 0;
}
@media only screen and (max-width: 1179px) {
  .catalog .card {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
  }
}
@media only screen and (max-width: 767px) {
  .catalog .card {
    width: 100%;
    margin: 0;
  }
  .catalog .card:not(:last-child) {
    margin-bottom: 32px;
  }
}
.catalog__group .card:nth-child(n + 4) {
  display: none;
}
@media only screen and (max-width: 1179px) {
  .catalog__group .card:nth-child(n + 4) {
    display: block;
  }
}
@media only screen and (max-width: 1179px) {
  .catalog__group .card:nth-child(n + 5) {
    display: none;
  }
}
.catalog__btns {
  margin-top: 32px;
  text-align: center;
}
.card {
  display: block;
  color: #23262f;
  transition: color 0.2s;
}
body.dark .card {
  color: #fcfcfd;
}
.card:hover {
  color: #3b71fe;
}
.card:hover .card__preview img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.card__preview {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}
.card__preview img {
  width: 100%;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.card__category {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
}
.card__body {
  display: flex;
  padding: 20px 0;
}
.card__avatar {
  position: relative;
  top: 2px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #e4d7cf;
}
body.dark .card__avatar {
  background: #353945;
}
.card__avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.card__details {
  flex-grow: 1;
  padding: 0 16px;
}
.card__title {
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.card__author {
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.card__status {
  align-self: flex-start;
}
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs__item {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #23262f;
}
body.dark .breadcrumbs__item {
  color: #fcfcfd;
}
.breadcrumbs__item:not(:last-child) {
  margin-right: 16px;
}
.breadcrumbs__item:not(:last-child):after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 9px;
  margin-left: 16px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777E90'/%3E%3C/svg%3E")
    no-repeat 50% 50%/100% auto;
}
@media only screen and (max-width: 767px) {
  .breadcrumbs__item:not(:last-child):not(:nth-last-child(2)) {
    display: none;
  }
}
.breadcrumbs__link {
  color: #777e90;
  transition: color 0.2s;
}
.breadcrumbs__link:hover {
  color: #3b71fe;
}
.article {
  padding-top: 80px;
}
@media only screen and (max-width: 767px) {
  .article {
    padding-top: 40px;
  }
}
.article__head {
  max-width: 730px;
  margin: 0 auto 80px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .article__head {
    margin-bottom: 32px;
  }
}
.article__title {
  margin-bottom: 24px;
}
.article .breadcrumbs {
  justify-content: center;
}
@media only screen and (max-width: 1179px) {
  .article__body {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .article__body {
    margin-bottom: 32px;
  }
}
.article__wrap .article__center {
  max-width: 888px;
}
.article .actions {
  margin-top: 80px;
}
@media only screen and (max-width: 1179px) {
  .article .actions {
    margin-top: 64px;
  }
}
@media only screen and (max-width: 767px) {
  .article .actions {
    margin-top: 32px;
  }
}
.player {
  position: relative;
}
.player:before {
  content: "";
  display: block;
  padding-bottom: 53.6%;
}
@media only screen and (max-width: 1179px) {
  .player:before {
    padding-bottom: 69%;
  }
}
@media only screen and (max-width: 767px) {
  .player:before {
    padding-bottom: 150%;
  }
}
.player img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  -o-object-fit: cover;
  object-fit: cover;
}
.player__control {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  background: #fcfcfd;
}
body.dark .player__control {
  background: #141416;
}
.player__button .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
  transition: fill 0.2s;
}
.player__button:hover .icon {
  fill: #23262f;
}
body.dark .player__button:hover .icon {
  fill: #fcfcfd;
}
.player__button:not(:last-child) {
  margin-right: 24px;
}
@media only screen and (max-width: 767px) {
  .player__button:not(:last-child) {
    margin-right: 16px;
  }
}
.player__line {
  position: relative;
  flex-grow: 1;
  height: 8px;
  margin-right: 24px;
  background: #e6e8ec;
  border-radius: 4px;
}
@media only screen and (max-width: 767px) {
  .player__line {
    margin-right: 16px;
  }
}
body.dark .player__line {
  background: #353945;
}
.player__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background: #3b71fe;
}
.player__time {
  margin-right: 24px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .player__time {
    margin-right: 16px;
  }
}
.content h1 {
  font-size: 64px;
  line-height: 1;
  letter-spacing: -0.02em;
}
@media only screen and (max-width: 767px) {
  .content h1 {
    font-size: 48px;
    line-height: 1.16667;
  }
}
.content h2 {
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -0.02em;
}
.content.ppost h2 {
  font-size: 30px;
}
@media only screen and (max-width: 767px) {
  .content h2 {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.01em;
  }
}
.content h3 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.01em;
}
.content.ppost h3 {
  font-size: 26px;
}
@media only screen and (max-width: 767px) {
  .content h3 {
    font-size: 32px;
    line-height: 1.25;
  }
}
.content h4 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.01em;
}
.content.ppost h4 {
  font-size: 24px;
}
.content h1,
.content h2,
.content h3,
.content h4 {
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 32px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
}
.content h1:after,
.content h2:after,
.content h3:after,
.content h4:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 166px;
  height: 2px;
  background: #e6e8ec;
}
body.dark .content h1:after,
body.dark .content h2:after,
body.dark .content h3:after,
body.dark .content h4:after {
  background: #353945;
}
.content h1:not(:first-child),
.content h2:not(:first-child),
.content h3:not(:first-child),
.content h4:not(:first-child) {
  margin-top: 80px;
}
@media only screen and (max-width: 767px) {
  .content h1:not(:first-child),
  .content h2:not(:first-child),
  .content h3:not(:first-child),
  .content h4:not(:first-child) {
    margin-top: 40px;
  }
}
.content.ppost h5 {
  font-size: 20px;
}
.content p {
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.content p:not(:last-child) {
  margin-bottom: 32px;
}
.content figure {
  margin: 0 -76px;
}
@media only screen and (max-width: 1023px) {
  .content figure {
    margin-left: 0;
    margin-right: 0;
  }
}
.content figure img {
  width: 100%;
  border-radius: 16px;
}
@media only screen and (max-width: 767px) {
  .content figure img {
    min-height: auto;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.content figure:not(:first-child) {
  margin-top: 80px;
}
@media only screen and (max-width: 767px) {
  .content figure:not(:first-child) {
    margin-top: 40px;
  }
}
.actions {
  position: relative;
}
.actions__list {
  display: flex;
}
.actions__item_share {
  position: relative;
}
.actions__item:not(:last-child) {
  margin-right: 16px;
}
.actions__button .icon {
  width: 24px;
  height: 24px;
}
.actions__item.active .actions__button {
  background: #23262f;
  box-shadow: 0 0 0 2px #23262f inset;
  color: #fcfcfd;
}
.actions__item.active .actions__button .icon {
  fill: #fcfcfd;
}
body.dark .actions__item.active .actions__button {
  background: #353945;
  box-shadow: 0 0 0 2px #353945 inset;
}
.actions__body {
  position: absolute;
  z-index: 5;
  border: 1px solid #e6e8ec;
  background: #fcfcfd;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.actions__item.active .actions__body {
  visibility: visible;
  opacity: 1;
}
.actions__item_share .actions__body {
  left: 50%;
  top: calc(100%+8px);
  width: 220px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 32px 16px;
  box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
  border-radius: 16px;
  text-align: center;
}
body.dark .actions__item_share .actions__body {
  background: #23262f;
  border-color: #353945;
}
.actions__item_share .actions__body_up {
  top: auto;
  bottom: calc(100%+8px);
}
.actions__title {
  margin-bottom: 24px;
  font-weight: 500;
}
.actions__list {
  display: flex;
  justify-content: center;
}
.actions__link span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #e6e8ec;
  cursor: pointer;
  transition: all 0.2s;
}
.actions__link span .icon {
  width: 20px;
  height: 20px;
  transition: fill 0.2s;
}
.actions__link span:hover {
  background: #3b71fe;
}
.actions__link span:hover .icon {
  fill: #fcfcfd;
}
body.dark .actions__link span {
  background: #353945;
}
body.dark .actions__link span .icon {
  fill: #fcfcfd;
}
body.dark .actions__link span:hover {
  background: #3b71fe;
}
body.dark .actions__link span:hover .icon {
  fill: #fcfcfd;
}
.actions__link:not(:last-child) {
  margin-right: 24px;
}
.actions__favorite {
  position: relative;
}
.actions__favorite .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.2s;
}
.actions__favorite .icon:nth-child(2) {
  z-index: 2;
  opacity: 0;
}
.actions__favorite:hover {
  box-shadow: inset 0 0 0 2px #23262f;
  background: #23262f;
}
body.dark .actions__favorite:hover {
  box-shadow: 0 0 0 2px #353945 inset;
}
.actions__favorite:hover .icon {
  fill: #fcfcfd;
}
.actions__favorite.active {
  box-shadow: inset 0 0 0 2px #23262f;
  background: #23262f;
  color: #23262f;
}
.actions__favorite.active .icon {
  fill: #fcfcfd;
}
.actions__favorite.active .icon:first-child {
  opacity: 0;
}
.actions__favorite.active .icon:nth-child(2) {
  opacity: 1;
}
body.dark .actions__favorite.active {
  background: #353945;
  box-shadow: 0 0 0 2px #353945 inset;
}
.contact {
  position: relative;
}
.contact__body .contact__center {
  display: flex;
  align-items: center;
  min-height: 740px;
  padding-top: 112px;
  padding-bottom: 112px;
}
@media only screen and (max-width: 1179px) {
  .contact__body .contact__center {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .contact__body .contact__center {
    display: block;
    min-height: auto;
    padding-top: 32px;
    padding-bottom: 16px;
  }
}
.contact__wrap {
  position: relative;
  z-index: 3;
  max-width: 545px;
}
@media only screen and (max-width: 1023px) {
  .contact__wrap {
    max-width: 350px;
  }
}
@media only screen and (max-width: 767px) {
  .contact__wrap {
    max-width: 100%;
    margin-bottom: 16px;
  }
}
.contact__stage {
  margin-bottom: 12px;
}
.contact__title {
  margin-bottom: 20px;
}
.contact__text {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.contact .subscription {
  max-width: 300px;
}
@media only screen and (max-width: 767px) {
  .contact .subscription {
    max-width: 100%;
  }
}
.contact__bg {
  position: absolute;
  top: 12px;
  right: calc(50% - 730px);
  width: 785px;
  pointer-events: none;
}
@media only screen and (max-width: 1179px) {
  .contact__bg {
    right: calc(50% - 670px);
    width: 700px;
  }
}
@media only screen and (max-width: 767px) {
  .contact__bg {
    position: static;
    width: 100%;
    padding-left: 16px;
  }
}
.contact__bg img {
  width: 100%;
}
.contact__foot .contact__center {
  max-width: 1440px;
  padding: 0;
}
.contact__info {
  max-width: 350px;
  margin: 0 auto 40px;
  text-align: center;
  font-weight: 500;
  color: #777e90;
}
.contact__item {
  display: block;
  color: #23262f;
  transition: color 0.2s;
}
body.dark .contact__item {
  color: #fcfcfd;
}
.contact__item:hover {
  color: #3b71fe;
}
.contact__item:hover .contact__preview img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.contact__preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 296px;
  overflow: hidden;
}
.contact__preview img {
  max-width: 100%;
  max-height: 100%;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.contact__category {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
}
.contact__box {
  padding: 40px 40px 0;
}
.contact__subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.contact__content {
  color: #777e90;
}
.contact__slider {
  padding-bottom: 88px;
  visibility: hidden;
}
.contact__slider.slick-initialized {
  visibility: visible;
}
.contact .slick-list {
  overflow: visible;
}
.contact .slick-slide {
  opacity: 0;
  transition: opacity 0.4s;
}
.contact .slick-slide.slick-active {
  opacity: 1;
}
.contact .slick-slide:nth-child(2n + 1) .contact__preview {
  background: #f4f5f6;
}
body.dark .contact .slick-slide:nth-child(2n + 1) .contact__preview {
  background: #323642;
}
.contact .slick-slide:nth-child(2n) .contact__preview {
  background: #e6e8ec;
}
body.dark .contact .slick-slide:nth-child(2n) .contact__preview {
  background: #23262f;
}
.contact .slick-arrow {
  bottom: 0;
}
.contact .slick-prev {
  left: calc(50% - 48px);
}
.contact .slick-next {
  right: calc(50% - 48px);
}
.faq__title {
  margin-bottom: 48px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .faq__title {
    margin-bottom: 40px;
  }
}
.faq .nav {
  justify-content: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .faq .nav {
    display: none;
  }
}
.faq .nav__link:not(:last-child) {
  margin-right: 12px;
}
.faq .select {
  display: none;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .faq .select {
    display: block;
    margin-bottom: 24px;
  }
}
.faq__list {
  max-width: 546px;
  margin: 0 auto;
}
.faq__item:hover .faq__head {
  color: #3b71fe;
}
.faq__item:hover .faq__number {
  color: #3b71fe;
}
.faq__item.active .faq__number {
  color: #3b71fe;
}
.faq__item.active .faq__arrow .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.faq__item:not(:last-child) .faq__head {
  border-bottom: 1px solid #e6e8ec;
}
body.dark .faq__item:not(:last-child) .faq__head {
  border-color: #353945;
}
.faq__head {
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.faq__number {
  flex-shrink: 0;
  width: 40px;
  color: #777e90;
  transition: color 0.2s;
}
.faq__subtitle {
  flex-grow: 1;
}
.faq__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  margin-left: 16px;
}
.faq__arrow .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.faq__body {
  display: none;
  padding: 24px 0 0 40px;
  color: #777e90;
}

.field__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  pointer-events: none;
}
.field__icon .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
}
.field__note {
  margin-top: 12px;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 500;
  color: #777e90;
}

.field_button .field__input {
  padding-right: 136px;
}
.field_textarea {
  font-size: 0;
}
.field_empty .field__input {
  border: none;
  height: 26px;
}
.field_empty .select {
  height: 26px;
  padding: 0 36px 0 14px;
  box-shadow: none;
  background: none;
  line-height: 26px;
}
body.dark .field_empty .select {
  box-shadow: none;
  background: none;
}
.field_empty .select:before {
  right: 12px;
  width: 24px;
  height: 24px;
  box-shadow: none;
}
body.dark .field_empty .select:before {
  box-shadow: none;
}
.field_empty .option {
  padding: 8px 14px;
}
.field_view .field__input,
.field_icon .field__input {
  padding-right: 48px;
}
.question__form {
  max-width: 546px;
  margin: 0 auto;
}
.ant-drawer-body .question__form {
  max-width: 100%;
}
.question__fieldset {
  margin-bottom: -60px;
}
.question__fieldset.cvb {
  margin-bottom: 45px;
}
.question__field:not(:last-child),
.question .field:not(:last-child) {
  margin-bottom: 32px;
}
.question__label {
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #b1b5c3;
}
.question__variants {
  display: flex;
  margin: 0 -10px;
}
.question__radio {
  position: relative;
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin: 0 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.question__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.question__input:checked + .question__inner {
  box-shadow: inset 0 0 0 2px #3b71fe;
}
body.dark .question__input:checked + .question__inner {
  box-shadow: inset 0 0 0 2px #3b71fe;
}
.question__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 12px;
  border-radius: 5px;
  background: #fcfcfd;
  transition: box-shadow 0.2s;
  box-shadow: inset 0 0 0 2px #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .question__inner {
    height: 48px;
  }
}
.question__inner:hover {
  box-shadow: inset 0 0 0 2px #3b71fe;
}
body.dark .question__inner {
  background: #23262f;
}
body.dark .question__inner:hover {
  box-shadow: inset 0 0 0 2px #353945;
}
.question__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  margin-right: 14px;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) {
  .question__icon {
    display: none;
  }
}
.question__icon .icon {
  width: 20px;
  height: 20px;
  fill: #fcfcfd;
}
.question .field__input,
.question .field__textarea {
  background: #fcfcfd;
}
body.dark .question .field__input,
body.dark .question .field__textarea {
  background: #23262f;
}
body.dark .question .select {
  background: #23262f;
}
.question__text {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #23262f;
}
body.dark .question__text {
  color: #fcfcfd;
}
.question__btns {
  text-align: right;
}
.question__btns.c_form {
  margin-top: 75px;
}

.checkbox {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.checkbox__input:checked + .checkbox__inner .checkbox__tick {
  background: #3b71fe;
  border-color: #3b71fe;
}
.checkbox__input:checked + .checkbox__inner .checkbox__tick:before {
  opacity: 1;
}
.checkbox__inner {
  display: flex;
}
.checkbox__inner:hover .checkbox__tick {
  border-color: #3b71fe;
}
.checkbox__tick {
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 4px;
  border: 2px solid #e6e8ec;
  transition: all 0.2s;
}
body.dark .checkbox__tick {
  background: none;
  border-color: #353945;
}
.checkbox__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 14px;
  height: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E")
    no-repeat 50% 50%/100% auto;
  opacity: 0;
  transition: opacity 0.2s;
}
.checkbox__text {
  line-height: 1.71429;
  font-weight: 500;
  color: #23262f;
}
.checkbox__text svg {
  fill: #a3a3a3;
  stroke: white;
  margin-left: 5px;
}
body.dark .checkbox__text {
  color: #fcfcfd;
}
.checkbox__link {
  color: #23262f;
  transition: color 0.2s;
}
body.dark .checkbox__link {
  color: #fcfcfd;
}
.checkbox__link:hover {
  color: #3b71fe;
}
body.dark .checkbox__link:hover {
  color: #3b71fe;
}
.notifications {
  padding: 32px 0;
  background: #f4f5f6;
  min-height: calc(100vh - 346px);
}
@media only screen and (max-width: 1023px) {
  .notifications {
    padding-bottom: 64px;
  }
}
body.dark .notifications {
  background: none;
}
.notifications__body {
  padding: 40px;
  border-radius: 8px;
  background: #fcfcfd;
}
@media only screen and (max-width: 1023px) {
  .notifications__body {
    padding: 0;
    background: none;
  }
}
body.dark .notifications__body {
  background: #18191d;
}
@media only screen and (max-width: 1023px) {
  body.dark .notifications__body {
    background: none;
  }
}
.notifications__top {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .notifications__top {
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding-bottom: 0;
    border: none;
  }
}
body.dark .notifications__top {
  border-color: #353945;
}
.notifications__top .notifications__button:not(:last-child) {
  margin-right: 16px;
}
.notifications__title {
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .notifications__title {
    flex: 0 0 100%;
    margin: 0 0 20px;
  }
}
.notifications__row {
  display: flex;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 767px) {
  .notifications__row {
    display: block;
  }
}
.notifications__toggle {
  display: none;
}
@media only screen and (max-width: 767px) {
  .notifications__toggle {
    display: flex;
    width: 100%;
  }
}
.notifications__wrapper {
  flex-grow: 1;
  /* padding-right:80px; */
}
/* @media only screen and (max-width:1179px) {
	.notifications__wrapper {
		padding-right:48px;
	}
}
@media only screen and (max-width:1023px) {
	.notifications__wrapper {
		padding-right:32px;
	}
} */
@media only screen and (max-width: 767px) {
  .notifications__wrapper {
    padding: 48px 0 0;
  }
}
.notifications__filters {
  flex-shrink: 0;
  width: 352px;
}
@media only screen and (max-width: 1339px) {
  .notifications__filters {
    width: 300px;
  }
}
@media only screen and (max-width: 1179px) {
  .notifications__filters {
    width: 230px;
  }
}
@media only screen and (max-width: 767px) {
  .notifications__filters {
    display: none;
    padding-top: 32px;
  }
  .notifications__filters.visible {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .notifications__list {
    margin: 0 -32px;
  }
}
.notifications__btns {
  margin-top: 56px;
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .notifications__btns {
    margin-top: 32px;
  }
}
.notifications__info {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.notifications__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.notifications .checkbox:not(:last-child) {
  margin-bottom: 24px;
}
.notifications__control {
  display: flex;
  margin-top: 24px;
}
.notifications__control .notifications__button:not(:last-child) {
  margin-right: 12px;
}
.notifications__item {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .notifications__item {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.notifications__item:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .notifications__item:not(:last-child) {
  border-color: #353945;
}
.notifications__item.active .notifications__status {
  display: block;
}
.notifications__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 50%;
  border: 2px solid #e6e8ec;
}
body.dark .notifications__icon {
  border-color: #353945;
}
.notifications__icon .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
}
.notifications__line {
  display: flex;
  margin-bottom: 8px;
}
@media only screen and (max-width: 767px) {
  .notifications__line {
    flex-wrap: wrap;
  }
}
.notifications__subtitle {
  margin-right: auto;
  padding-right: 32px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}
.notifications__subtitle {
  color: #979797;
}
.active .notifications__subtitle {
  color: #23262f;
}
@media only screen and (max-width: 767px) {
  .notifications__subtitle {
    flex: 0 0 100%;
    margin: 0 0 8px;
    padding-right: 0;
  }
}
.notifications__date {
  flex-shrink: 0;
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .notifications__date {
    margin: 0 auto 0 0;
  }
}
.notifications__status {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin: 5px 0 0 16px;
  border-radius: 50%;
  background: #e6e8ec;
}
.active .notifications__status {
  background: #58bd7d;
}
@media only screen and (max-width: 767px) {
  .notifications__status {
    margin-top: 3px;
  }
}
.notifications__content {
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.post {
  margin-top: 16px;
  padding: 32px;
  border-radius: 8px;
  background: #fcfcfd;
}
@media only screen and (max-width: 1023px) {
  .post {
    margin-top: 56px;
    padding: 0;
    background: none;
  }
}
body.dark .post {
  background: #18191d;
}
@media only screen and (max-width: 1023px) {
  body.dark .post {
    background: none;
  }
}
.post__list {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0;
}
@media only screen and (max-width: 767px) {
  .post__list {
    flex-wrap: nowrap;
    margin: 0 -32px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .post__list::-webkit-scrollbar {
    display: none;
  }
  .post__list:before,
  .post__list:after {
    content: "";
    flex-shrink: 0;
    width: 32px;
    height: 1px;
  }
}
.post__item {
  display: flex;
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 32px 16px 0;
  color: #23262f;
}
@media only screen and (max-width: 1023px) {
  .post__item {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .post__item {
    flex: 0 0 222px;
    width: 222px;
    margin: 0;
  }
  .post__item:not(:last-child) {
    margin-right: 16px;
  }
}
body.dark .post__item {
  color: #fcfcfd;
}
.post__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 128px;
  height: 144px;
  margin-right: 32px;
  padding: 12px;
  border-radius: 16px;
}
@media only screen and (max-width: 1023px) {
  .post__preview {
    width: 100%;
    margin: 0 0 24px;
  }
}
.post__preview img {
  max-width: 100%;
}
.post__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}
.post__title {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 767px) {
  .post__title {
    font-size: 16px;
  }
}
.post__content {
  margin-bottom: 16px;
  color: #777e90;
}
.post__button {
  margin-top: auto;
}
.activity {
  padding: 32px 0;
  background: #f4f5f6;
}
@media only screen and (max-width: 767px) {
  .activity {
    background: #fcfcfd;
  }
}
body.dark .activity {
  background: none;
}
@media only screen and (max-width: 1179px) {
  .activity__center {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .activity__center {
    padding: 0 32px;
  }
}
.activity__wrapper {
  padding: 40px;
  border-radius: 8px;
  background: #fcfcfd;
}
@media only screen and (max-width: 1179px) {
  .activity__wrapper {
    padding: 24px;
  }
}
@media only screen and (max-width: 1023px) {
  .activity__wrapper {
    padding: 0;
    background: none;
  }
}
body.dark .activity__wrapper {
  background: #18191d;
}
@media only screen and (max-width: 1023px) {
  body.dark .activity__wrapper {
    background: none;
  }
}
.activity__top {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .activity__top {
    display: block;
    padding: 0;
    border: none;
  }
}
body.dark .activity__top {
  border-color: #353945;
}
.activity .select {
  display: none;
  background: none;
}
@media only screen and (max-width: 1023px) {
  .activity .select {
    display: block;
    width: 256px;
    margin-right: auto;
  }
}
@media only screen and (max-width: 767px) {
  .activity .select {
    width: 100%;
    margin: 0 0 16px;
  }
}
.activity .nav {
  margin-right: auto;
}
@media only screen and (max-width: 1023px) {
  .activity .nav {
    display: none;
  }
}
.activity .nav__link:not(:last-child) {
  margin-right: 16px;
}
@media only screen and (max-width: 1179px) {
  .activity .nav__link:not(:last-child) {
    margin-right: 8px;
  }
}
.activity__form {
  position: relative;
  flex-shrink: 0;
  width: 276px;
  margin-right: 16px;
}
@media only screen and (max-width: 1179px) {
  .activity__form {
    width: 230px;
  }
}
@media only screen and (max-width: 767px) {
  .activity__form {
    width: 100%;
    margin: 0 0 16px;
  }
}
.activity__input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 14px;
  border-radius: 4px;
  background: none;
  border: 2px solid #e6e8ec;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 1.66667;
  color: #23262f;
  transition: border-color 0.2s;
}
@media only screen and (max-width: 1023px) {
  .activity__input {
    height: 48px;
  }
}
body.dark .activity__input {
  border-color: #353945;
  color: #fcfcfd;
}
.activity__input::-webkit-input-placeholder {
  color: #777e90;
}
.activity__input::-moz-placeholder {
  color: #777e90;
}
.activity__input:-ms-input-placeholder {
  color: #777e90;
}
.activity__input::-ms-input-placeholder {
  color: #777e90;
}
.activity__input::placeholder {
  color: #777e90;
}
.activity__input:focus {
  border-color: #777e90;
}
.activity__result {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
}
span.activity__result {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity__result .icon {
  width: 20px;
  height: 20px;
  fill: #777e90;
  transition: fill 0.2s;
}
.activity__result:hover .icon {
  fill: #3b71fe;
}
.activity__line {
  display: flex;
  align-items: center;
  margin-bottom: 42px;
}
.activity__title {
  margin-right: auto;
}
.activity__table {
  display: table;
  width: 100%;
}
@media only screen and (max-width: 1023px) {
  .activity__table {
    display: block;
  }
}
.activity__row {
  display: table-row;
  color: #23262f;
}
@media only screen and (max-width: 1023px) {
  .activity__row {
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 1023px) {
  .activity__row:first-child {
    display: none;
  }
}
.activity__row:first-child .activity__col {
  padding: 0 16px 24px;
  border-bottom: 1px solid #e6e8ec;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
body.dark .activity__row:first-child .activity__col {
  border-color: #353945;
}
@media only screen and (max-width: 1179px) {
  .activity__row:first-child .activity__col:first-child {
    padding-left: 0;
  }
}
@media only screen and (max-width: 1179px) {
  .activity__row:first-child .activity__col:last-child {
    padding-right: 0;
  }
}
.activity__row:not(:first-child) {
  transition: background 0.2s;
}
.activity__row:not(:first-child) .activity__col {
  vertical-align: middle;
  font-weight: 500;
}
.activity__row:not(:first-child) .activity__col:first-child {
  border-radius: 12px 0 0 12px;
  color: #777e90;
}
@media only screen and (max-width: 1023px) {
  .activity__row:not(:first-child) .activity__col:first-child {
    border-radius: 0;
  }
}
.activity__row:not(:first-child) .activity__col:last-child {
  border-radius: 0 12px 12px 0;
}
@media only screen and (max-width: 1023px) {
  .activity__row:not(:first-child) .activity__col:last-child {
    border-radius: 0;
  }
}
.activity__row:not(:first-child):hover {
  background: #f4f5f6;
}
@media only screen and (max-width: 1023px) {
  .activity__row:not(:first-child):hover {
    background: none;
  }
}
body.dark .activity__row:not(:first-child):hover {
  background: #23262f;
}
@media only screen and (max-width: 1023px) {
  body.dark .activity__row:not(:first-child):hover {
    background: none;
  }
}
@media only screen and (max-width: 1023px) {
  .activity__row:not(:last-child) {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e6e8ec;
  }
  body.dark .activity__row:not(:last-child) {
    border-color: #353945;
  }
}
.activity__col {
  display: table-cell;
  padding: 20px 16px;
  font-weight: 500;
}
@media only screen and (max-width: 1023px) {
  .activity__col {
    padding: 0;
    display: flex;
    align-items: center;
  }
}
body.dark .activity__col {
  color: #fcfcfd;
}
@media only screen and (max-width: 1179px) {
  .activity__col:first-child {
    padding-left: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .activity__col:nth-child(2) {
    margin-left: auto;
  }
}
.activity__col:nth-child(5),
.activity__col:nth-child(6) {
  color: #777e90;
}
.activity__col:last-child {
  text-align: right;
}
@media only screen and (max-width: 1179px) {
  .activity__col:last-child {
    padding-right: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .activity__col:not(:first-child):not(:nth-child(2)) {
    flex: 0 0 100%;
    margin-top: 8px;
  }
}
.activity__coin {
  display: flex;
  align-items: center;
}
.activity__icon {
  flex-shrink: 0;
  width: 32px;
  margin-right: 12px;
}
.activity__icon img {
  max-width: 100%;
}
.activity__label {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .activity__label {
    display: block;
    margin-right: auto;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600;
    color: #777e90;
  }
}
.activity__item {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .activity__item_calendar .activity__button {
    width: 100%;
    background: #3b71fe;
    box-shadow: none;
    color: #ffffff;
  }

  .nav__link {
    padding: 6px 14px;
    padding-bottom: 5px;
  }
}
.activity__item_calendar.active > .activity__button {
  background: #23262f;
  box-shadow: 0 0 0 2px #23262f inset;
  color: #fcfcfd;
}
.activity__item_calendar.active > .activity__button .icon {
  fill: #fcfcfd;
}
body.dark .activity__item_calendar.active > .activity__button {
  background: #353945;
  box-shadow: 0 0 0 2px #353945 inset;
}
.activity__body {
  position: absolute;
  top: calc(100%+16px);
  right: 0;
  z-index: 2;
  background: #fcfcfd;
  border: 1px solid #e6e8ec;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  border-radius: 16px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}
body.dark .activity__body {
  background: #23262f;
  border-color: #353945;
}
.activity__item_calendar .activity__body {
  width: 660px;
  padding: 16px 16px 32px;
}
@media only screen and (max-width: 767px) {
  .activity__item_calendar .activity__body {
    display: none;
    position: static;
    width: auto;
    padding: 16px 0 0;
    border: none;
    background: none;
    box-shadow: none;
    border-radius: 0;
    visibility: visible;
    opacity: 1;
  }
  body.dark .activity__item_calendar .activity__body {
    background: none;
  }
}
@media only screen and (max-width: 767px) {
  .activity__item_calendar .activity__datepicker {
    display: none;
  }
}
.activity__item_export .activity__body {
  width: 364px;
  padding: 32px;
}
@media only screen and (max-width: 767px) {
  .activity__item_export .activity__body {
    width: 311px;
    padding: 16px 0 24px;
  }
}
.activity__item.active .activity__body {
  visibility: visible;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .activity__item.active .activity__body {
    display: block;
  }
}
.activity__variants {
  display: flex;
  flex-wrap: wrap;
  margin: -8px 0 0 0;
}
@media only screen and (max-width: 767px) {
  .activity__variants {
    margin-left: -16px;
  }
}
.activity__variants .activity__button {
  margin: 8px 0 0 16px;
}
.activity__range {
  display: none;
  width: 100%;
  margin: 8px 0 16px;
  text-align: center;
  background: none;
  pointer-events: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #23262f;
}
body.dark .activity__range {
  color: #fcfcfd;
}
.activity__body .activity__button {
  margin-top: 16px;
}
.profile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.profile__head {
  padding: 40px 0;
  border-bottom: 1px solid #f1f2f4;
}
@media only screen and (max-width: 767px) {
  .profile__head {
    padding: 16px 0 32px;
  }
}
body.dark .profile__head {
  border-color: #23262f;
}
.profile__head .profile__center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1179px) {
  .profile__head .profile__center {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .profile__head .profile__center {
    display: block;
    padding: 0 32px;
  }
}
.profile__title {
  margin-right: auto;
}
@media only screen and (max-width: 1179px) {
  .profile__title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.01em;
  }
}
@media only screen and (max-width: 1023px) {
  .profile__title {
    font-size: 32px;
    line-height: 40px;
  }
}
.profile .breadcrumbs {
  margin-left: 40px;
}
@media only screen and (max-width: 767px) {
  .profile .breadcrumbs {
    margin: 16px 0 0;
  }
}
.profile__body {
  flex-grow: 1;
  padding: 80px 0;
  background: #fcfcfd;
}
@media only screen and (max-width: 1179px) {
  .profile__body {
    padding-top: 64px;
  }
}
@media only screen and (max-width: 1023px) {
  .profile__body {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .profile__body {
    padding: 16px 0;
  }
}
body.dark .profile__body {
  background: none;
}
.profile__body .profile__center {
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 1179px) {
  .profile__body .profile__center {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 1023px) {
  .profile__body .profile__center {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .profile__body .profile__center {
    padding: 0 16px;
  }
}
.profile__sidebar {
  flex-shrink: 0;
  width: 288px;
  margin-right: 80px;
}
@media only screen and (max-width: 1339px) {
  .profile__sidebar {
    margin-right: 48px;
  }
}
@media only screen and (max-width: 1179px) {
  .profile__sidebar {
    width: 224px;
    margin-right: 32px;
  }
}
@media only screen and (max-width: 1023px) {
  .profile__sidebar {
    width: 100%;
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .profile__sidebar {
    margin-bottom: 24px;
  }
}
.profile__wrapper {
  flex-grow: 1;
  padding: 40px;
  border-radius: 16px;
  background: #fcfcfd;
  /* box-shadow:0px 64px 64px -48px rgba(15,15,15,0.1); */
}
@media only screen and (max-width: 1023px) {
  .profile__wrapper {
    padding: 40px 32px;
  }
}
@media only screen and (max-width: 767px) {
  .profile__wrapper {
    padding: 16px;
  }
}
body.dark .profile__wrapper {
  background: #18191d;
}
@media only screen and (max-width: 1023px) {
  .profile__dropdown {
    position: relative;
    z-index: 5;
  }
  .profile__dropdown.active .profile__top:after {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  .profile__dropdown.active .profile__menu {
    visibility: visible;
    opacity: 1;
  }
}
.profile__top {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .profile__top {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 64px 0 16px;
    border-radius: 24px;
    background: #fcfcfd;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
  }
  body.dark .profile__top {
    background: #23262f;
  }
  .profile__top .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: #23262f;
  }
  body.dark .profile__top .icon {
    fill: #fcfcfd;
  }
  .profile__top:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 24px;
    height: 24px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M16.207 9.793a1 1 0 0 0-1.414 0L12 12.586 9.207 9.793a1 1 0 0 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E")
      no-repeat 50% 50%/100% auto;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
}
@media only screen and (max-width: 1023px) {
  .profile__menu {
    position: absolute;
    top: calc(100%+4px);
    left: 0;
    right: 0;
    z-index: 2;
    padding: 16px;
    background: #fcfcfd;
    border-radius: 24px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }
  body.dark .profile__menu {
    background: #23262f;
  }
}
.profile__link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: color 0.2s;
}
@media only screen and (max-width: 1179px) {
  .profile__link {
    padding: 0;
  }
}
.profile__link .icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: #777e90;
  transition: fill 0.2s;
}
.profile__link:hover,
.profile__link.active {
  color: #23262f;
}
.profile__link:hover .icon,
.profile__link.active .icon {
  fill: #23262f;
}
body.dark .profile__link:hover,
body.dark .profile__link.active {
  color: #fcfcfd;
}
body.dark .profile__link:hover .icon,
body.dark .profile__link.active .icon {
  fill: #fcfcfd;
}
.profile__link:not(:last-child) {
  margin-bottom: 12px;
}
@media only screen and (max-width: 1023px) {
  .profile__link:not(:last-child) {
    margin-bottom: 4px;
  }
}
.profile__link:not(:last-child).profile__link_separator {
  position: relative;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1023px) {
  .profile__link:not(:last-child).profile__link_separator {
    margin-bottom: 4px;
  }
}
.profile__link:not(:last-child).profile__link_separator:after {
  content: "";
  position: absolute;
  left: 16px;
  right: 16px;
  top: calc(100%+12px);
  height: 1px;
  background: #e6e8ec;
}
@media only screen and (max-width: 1179px) {
  .profile__link:not(:last-child).profile__link_separator:after {
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .profile__link:not(:last-child).profile__link_separator:after {
    display: none;
  }
}
body.dark .profile__link:not(:last-child).profile__link_separator:after {
  background: #23262f;
}

.account__head {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .account__head {
    display: block;
  }
}
.account__details {
  margin-right: auto;
}
.account__details.ovw {
  /* margin: auto; */
  text-align: left;
  position: relative;
}
.account__user {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.account__user span {
  font-size: 18px;
}
.account__email {
  font-weight: 500;
  color: #777e90;
}
.account__level {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 16px;
  border-radius: 16px;
  box-shadow: inset 0 0 0 2px #e6e8ec;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #58bd7d;
}
@media only screen and (max-width: 767px) {
  .account__level {
    margin-top: 12px;
  }
}
body.dark .account__level {
  box-shadow: inset 0 0 0 2px #353945;
}
.account .select {
  flex-shrink: 0;
  width: 200px;
  margin: 12px 0 0 36px;
  background: #f4f5f6;
  box-shadow: inset 0 0 0 2px #f4f5f6;
  border-radius: 24px;
}
@media only screen and (max-width: 767px) {
  .account .select {
    width: 100%;
    margin: 40px 0 0;
  }
}
body.dark .account .select {
  background: #23262f;
  box-shadow: inset 0 0 0 2px #23262f;
}
.account__settings:not(:first-child) {
  margin-top: 48px;
}
@media only screen and (max-width: 767px) {
  .account__settings:not(:first-child) {
    margin-top: 24px;
  }
}
.account__title {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.account__box:not(:last-child) {
  margin-bottom: 40px;
}
.account__subtitle {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e6e8ec;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #b1b5c3;
}
body.dark .account__subtitle {
  border-color: #353945;
}
.account__item {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.account__item:not(:last-child) {
  margin-bottom: 24px;
}
.account__category {
  margin-right: auto;
}
.account__content {
  margin-left: 16px;
  text-align: right;
  color: #777e90;
}
.account__btns {
  margin-top: 48px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .account__btns {
    margin-top: 24px;
    text-align: left;
  }
}
.referrals__stage {
  font-weight: 500;
  color: #353945;
}
body.dark .referrals__stage {
  color: #e6e8ec;
}
.referrals__price {
  margin-bottom: 12px;
}
.referrals__price span {
  color: #58bd7d;
}
.referrals__info {
  max-width: 280px;
  margin-bottom: 48px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .referrals__info {
    margin-bottom: 32px;
  }
}
.referrals__wrap {
  padding: 32px;
  border-radius: 16px;
  background: #f1f2f4;
}
@media only screen and (max-width: 767px) {
  .referrals__wrap {
    padding: 24px;
  }
}
body.dark .referrals__wrap {
  background: #23262f;
}
.referrals__title {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.referrals__row {
  display: flex;
  margin: 0 -8px;
}
@media only screen and (max-width: 767px) {
  .referrals__row {
    display: block;
    margin: 0;
  }
}
.referrals__col {
  position: relative;
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}
@media only screen and (max-width: 767px) {
  .referrals__col {
    width: 100%;
    margin: 0;
  }
  .referrals__col:not(:last-child) {
    margin-bottom: 16px;
  }
}
.referrals .field__label {
  color: #23262f;
}
body.dark .referrals .field__label {
  color: #fcfcfd;
}
.referrals .field__input {
  padding-right: 82px;
  background: #fcfcfd;
  border-color: #fcfcfd;
}
body.dark .referrals .field__input {
  background: #353945;
}
.referrals .field__input:focus {
  border-color: #58bd7d;
}
.referrals__category {
  position: absolute;
  right: 12px;
  bottom: 11px;
}
.referrals__button {
  margin-top: 48px;
}
@media only screen and (max-width: 767px) {
  .referrals__button {
    margin-top: 32px;
  }
}
.keys__stage {
  font-size: 12px;
  line-height: 1.66667;
  color: #353945;
}
@media only screen and (max-width: 767px) {
  .keys__stage {
    margin-bottom: 12px;
  }
}
body.dark .keys__stage {
  color: #e6e8ec;
}
.keys__title {
  margin-bottom: 12px;
}
.keys__disabled .keys__title span {
  color: #ff6838;
}
.keys__enabled .keys__title span {
  color: #58bd7d;
  font-size: 18px;
}
.keys__email {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .keys__email {
    margin-bottom: 32px;
  }
}
.keys__email .icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  fill: #23262f;
}
body.dark .keys__email .icon {
  fill: #fcfcfd;
}
.keys__subtitle {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.keys__info {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.5;
  color: #353945;
  margin-top: 10px;
}
.keys__info.vv {
  margin-bottom: 60px;
}
.keys__info.w_cl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.keys__info .page_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_details .item_c {
  margin-right: 30px;
  display: block;
}
.page_details .item_c h4 {
  font-weight: 600;
  font-size: 12px;
}
.page_details .item_c p {
  font-size: 14px;
  font-weight: 700;
}

body.dark .keys__info {
  color: #e6e8ec;
}
.keys__row {
  display: flex;
  margin: 0 -8px;
}
@media only screen and (max-width: 767px) {
  .keys__row {
    display: block;
    margin: 0;
  }
}
.keys__row .field {
  position: relative;
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}
@media only screen and (max-width: 767px) {
  .keys__row .field {
    width: 100%;
    margin: 0;
  }
  .keys__row .field:not(:last-child) {
    margin-bottom: 16px;
  }
}
.keys__form .keys__button {
  margin-top: 16px;
}
@media only screen and (max-width: 767px) {
  .keys__form .keys__button {
    width: 100%;
  }
}
.keys__preview {
  margin-bottom: 48px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .keys__preview {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .keys__preview img {
    max-width: 300px;
  }
}
.keys__text {
  margin-bottom: 16px;
  text-align: center;
  font-weight: 500;
  color: #777e90;
}
.keys__confirm .keys__email {
  justify-content: center;
}
.keys__btns {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .keys__btns {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .keys__btns .keys__button {
    width: 100%;
  }
}
.keys__btns .keys__button:not(:last-child) {
  margin-right: 16px;
}
@media only screen and (max-width: 767px) {
  .keys__btns .keys__button:not(:last-child) {
    margin: 0 0 12px;
  }
}
.keys__item {
  margin-top: 48px;
  padding-top: 48px;
  border-top: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .keys__item {
    margin-top: 24px;
    padding-top: 24px;
  }
}
body.dark .keys__item {
  border-color: #353945;
}
@media only screen and (max-width: 767px) {
  .keys__item:last-child .keys__button {
    width: 100%;
  }
}
.keys__group {
  margin-bottom: 24px;
}
.keys__box {
  position: relative;
}
.keys__box .field {
  position: relative;
  padding-right: 36px;
}
.keys__box .field__label {
  color: #23262f;
}
body.dark .keys__box .field__label {
  color: #777e90;
}
.keys__box .field__input {
  padding-right: 82px;
  border-color: #f4f5f6;
  background: #f4f5f6;
}
body.dark .keys__box .field__input {
  background: #23262f;
  border-color: #23262f;
}
.keys__box .field__input:focus {
  border-color: #58bd7d;
  background: transparent;
}
.keys__box:not(:last-child) {
  margin-bottom: 24px;
}
.keys__remove {
  position: absolute;
  right: 0;
  bottom: 12px;
}
.keys__remove.share {
  right: 55px;
}
.keys__remove .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
  transition: fill 0.2s;
}
.keys__remove:hover .icon {
  fill: #23262f;
}
.keys__category {
  position: absolute;
  right: 48px;
  bottom: 11px;
}
.keys__category.re {
  bottom: 8px;
}
.keys__confirm,
.keys__enabled {
  display: none;
}
.sessions__section:not(:last-child) {
  margin-bottom: 48px;
}
.sessions__title {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.sessions__title.mm {
  margin-bottom: 5px;
}
.sessions__desc {
  margin-bottom: 32px;
  padding-bottom: 32px;
  color: #686f82;
  border-bottom: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .sessions__title {
    margin-bottom: 16px;
  }
}
.sessions__table {
  display: table;
  width: 100%;
}
.sessions__row {
  display: table-row;
}
.sessions__row:first-child .sessions__col {
  border-bottom: 1px solid #f4f5f6;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #353945;
}
body.dark .sessions__row:first-child .sessions__col {
  border-color: #23262f;
  color: #b1b5c3;
}
.sessions__row:not(:first-child):not(:last-child) .sessions__col {
  border-bottom: 1px solid #e6e8ec;
}
body.dark .sessions__row:not(:first-child):not(:last-child) .sessions__col {
  border-color: #353945;
}
.sessions__col {
  display: table-cell;
  padding-top: 16px;
  padding-bottom: 16px;
}
.sessions__col:nth-child(2) {
  padding-left: 32px;
  padding-right: 32px;
}
.sessions__col:last-child {
  text-align: right;
}
.sessions__content {
  font-weight: 500;
}
.sessions__note {
  color: #777e90;
}
.sessions__location,
.sessions__status {
  min-width: 64px;
  text-align: center;
}
body.dark .sessions__location {
  background: #fcfcfd;
  color: #23262f;
}
.sessions__btns {
  margin-top: 36px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .sessions__btns {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .sessions__button {
    width: 100%;
  }
}
.security__title {
  margin-bottom: 16px;
}
.security__disabled .security__title span {
  color: #ff6838;
}
.security__enabled .security__title span {
  color: #58bd7d;
}
.security__text {
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.security__text span {
  font-weight: 600;
  color: #23262f;
}
body.dark .security__text span {
  color: #fcfcfd;
}
.security__email {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.security__email .icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  fill: #23262f;
}
body.dark .security__email .icon {
  fill: #fcfcfd;
}
.security__subtitle {
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.security__info {
  margin-bottom: 24px;
  color: #353945;
}
body.dark .security__info {
  color: #b1b5c3;
}
.security__row {
  display: flex;
  margin: 0 -8px;
}
@media only screen and (max-width: 767px) {
  .security__row {
    display: block;
    margin: 0;
  }
}
.security__row .field {
  position: relative;
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}
@media only screen and (max-width: 767px) {
  .security__row .field {
    width: 100%;
    margin: 0;
  }
  .security__row .field:not(:last-child) {
    margin-bottom: 16px;
  }
}
.security__box {
  display: flex;
  margin-top: 32px;
  background: #f4f5f6;
  border-radius: 16px;
}
@media only screen and (max-width: 767px) {
  .security__box {
    margin-top: 24px;
  }
}
body.dark .security__box {
  background: #23262f;
}
.security__details {
  max-width: 256px;
  margin: 64px auto 0;
  padding: 32px 48px;
  border-radius: 32px 32px 0 0;
  background: #fcfcfd;
}
body.dark .security__details {
  background: #353945;
}
.security__details .security__button {
  width: 100%;
  margin-top: 48px;
}
.security__code {
  padding: 16px;
  border-radius: 8px;
  border: 2px dashed #3b71fe;
}
.security__code img {
  width: 100%;
  border-radius: 8px;
}
.security__btns {
  margin-top: 32px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .security__btns {
    margin-top: 24px;
  }
  .security__btns .security__button {
    width: 100%;
  }
}
.security__enabled .security__button {
  margin-top: 16px;
}
@media only screen and (max-width: 767px) {
  .security__enabled .security__button {
    width: 100%;
  }
}
.security__disabled {
  display: none;
}
.password__form {
  max-width: 380px;
  margin: 0 auto;
}
.password__title {
  text-align: center;
}
.password__form .password__title {
  margin-bottom: 32px;
}
.password .field:not(:last-child) {
  margin-bottom: 32px;
}
.password__form .password__button {
  width: 100%;
}
.password__wrap {
  display: none;
  text-align: center;
}
.password__wrap .password__title {
  margin-bottom: 8px;
}
.password__info {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}
.login {
  display: flex;
  height: 100vh;
}
@media only screen and (max-width: 1023px) {
  .login {
    display: block;
  }
}
.login__col {
  position: relative;
  flex-direction: column;
  background: white;
  background-color: white;
  border-radius: 8px;
  max-height: auto;
  text-align: center;
  max-width: 560px;
  margin: auto;
  padding: 50px 96px 40px;
  margin-top: 6%;
  z-index: 2;
}

.login__col.regstr {
  margin-top: 2%;
}

.login__col:first-child {
  flex-shrink: 0;
  width: 512px;
  min-height: 100vh;
  background-color: #f4f5f6;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media only screen and (max-width: 1339px) {
  .login__col:first-child {
    width: 420px;
  }
}
@media only screen and (max-width: 1179px) {
  .login__col:first-child {
    width: 350px;
  }
}
@media only screen and (max-width: 1023px) {
  .login__col:first-child {
    width: auto;
    min-height: auto;
    background: none !important;
  }
}
body.dark .login__col:first-child {
  background-color: #23262f;
}
.login__col:nth-child(2) {
  position: relative;
  display: flex;
  flex-grow: 1;
}
@media only screen and (max-width: 1419px) {
  .login__col:nth-child(2) {
    padding: 124px 48px 76px;
  }
}
@media only screen and (max-width: 1023px) {
  .login__col:nth-child(2) {
    min-height: calc(var(--vh, 1vh) * 100);
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .login__col:nth-child(2) {
    flex-direction: column;
    padding: 0px 32px 32px;
  }
}
.login__logo {
  position: relative;
  width: 50px;
  max-width: 60px;
  margin: auto;
  text-align: center;
  margin-bottom: 33px;
}

@media only screen and (max-width: 767px) {
  .login__logo {
    margin-top: 51px;
  }
}
.login__logo img {
  width: 100%;
}
.login__logo img:first-child {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .login__logo img:first-child {
    display: inline-block;
  }
  body.dark .login__logo img:first-child {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .login__logo img:nth-child(2) {
    display: none;
  }
  body.dark .login__logo img:nth-child(2) {
    display: inline-block;
  }
}
.login__head {
  position: relative;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .login__head {
    margin-bottom: 0px;
    text-align: center;
  }

  .registration__title.h3,
  .login__wrap .entry__title.h3 {
    font-size: 30px;
  }
}
.login__head .login__link {
  margin-left: 5px;
  color: #3b71fe;
  transition: color 0.2s;
}
.login__head .login__link:hover {
  color: #044eff;
}
.login__wrap {
  width: 380px;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  .login__wrap {
    margin-top: 15px;
    width: 100%;
  }

  .h3.entry__title {
    font-size: 30px;
  }

  .AuthBackground_image__IINQD {
    z-index: 2 !important;
    display: none !important;
  }

  .login {
    height: 96vh;
  }
}
.login__top {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .login__top {
  border-color: #353945;
}
.login__title {
  margin-bottom: 32px;
  text-align: center;
}
.login__form .login__info {
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.login__form .login__button,
.login__new .login__button {
  width: 100%;
}
.login__form .login__button {
  margin-top: 32px;
}
.login__foot {
  margin-top: 24px;
  text-align: center;
}
.login__form .login__link {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #23262f;
  transition: color 0.2s;
}
body.dark .login__form .login__link {
  color: #fcfcfd;
}
.login__form .login__link:hover {
  color: #3b71fe;
}
.login__verification .login__title {
  margin: 0 -5px 32px;
  text-align: center;
}
.login__note {
  margin-bottom: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #353945;
}
body.dark .login__note {
  color: #777e90;
}
.login__text {
  margin-bottom: 24px;
  text-align: center;
  color: #777e90;
}
.login__text span {
  font-weight: 500;
  color: #23262f;
}
body.dark .login__text span {
  color: #fcfcfd;
}
.login__numbers {
  display: flex;
  margin: 0 -8px 32px;
}
.login__number {
  flex: 0 0 calc(25% - 16px);
  width: calc(25% - 16px);
  margin: 0 8px;
}
.login__number input {
  width: 100%;
  height: 96px;
  border-radius: 8px;
  border: 1px solid #f4f5f6;
  background: #f4f5f6;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #23262f;
  transition: all 0.2s;
}
.login__number input:focus {
  border-color: #e6e8ec;
  background: #fcfcfd;
}
body.dark .login__number input {
  background: #23262f;
  border-color: #23262f;
  color: #fcfcfd;
}
body.dark .login__number input:focus {
  background: none;
  border-color: #353945;
}
.login__btns {
  display: flex;
  justify-content: space-between;
}
.login__new .field:not(:last-child) {
  margin-bottom: 32px;
}
.login__verification,
.login__new {
  display: none;
}
.entry__top {
  margin-bottom: 33px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .entry__top {
  border-color: #353945;
}
.entry__title {
  margin-bottom: 32px;
  text-align: center;
}
.entry__info {
  margin-bottom: 12px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.entry__correct {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  background: #f4f5f6;
  border-radius: 20px;
  font-weight: 500;
  color: #58bd7d;
}
.entry__correct.referral {
  justify-content: flex-start;
  border-radius: 7px;
  height: 48px;
  padding: 0 14px;
}
body.dark .entry__correct {
  background: #353945;
}
.entry__correct .icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  fill: #58bd7d;
}
.entry__correct span {
  color: #23262f;
  margin-left: 10px;
}
body.dark .entry__correct span {
  color: #fcfcfd;
}
.entry .nav {
  justify-content: center;
  margin-bottom: 32px;
}
.entry .nav__link:not(:last-child) {
  margin-right: 24px;
}
.entry__container {
  margin-bottom: 32px;
}
.entry__item {
  display: none;
}
.entry__line {
  display: flex;
  align-items: flex-end;
}
.entry__line .field:first-child {
  flex-shrink: 0;
  width: 128px;
  margin-right: 8px;
}
.entry__line .field:nth-child(2) {
  flex-grow: 1;
}
.entry__foot {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
}
.entry__foot .entry__link {
  color: #3b71fe;
  transition: color 0.2s;
}
.entry__foot .entry__link:hover {
  color: #044eff;
}
.entry__scan {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
  transition: color 0.2s;
}
.entry__scan:hover {
  color: #23262f;
}
body.dark .entry__scan:hover {
  color: #fcfcfd;
}
.entry__form .entry__button {
  width: 100%;
  margin-top: 32px;
}
.entry__box {
  display: flex;
  margin-top: 32px;
  background: #f4f5f6;
  border-radius: 16px;
}
@media only screen and (max-width: 767px) {
  .entry__box {
    margin-bottom: -32px;
    background: none;
  }
}
body.dark .entry__box {
  background: #23262f;
}
@media only screen and (max-width: 767px) {
  body.dark .entry__box {
    background: none;
  }
}
.entry__details {
  max-width: 256px;
  margin: 64px auto 0;
  padding: 32px 48px;
  border-radius: 32px 32px 0 0;
  background: #fcfcfd;
}
@media only screen and (max-width: 767px) {
  .entry__details {
    margin-top: 0;
    background: #f4f5f6;
  }
}
body.dark .entry__details {
  background: #353945;
}
.entry__code {
  padding: 16px;
  border-radius: 8px;
  border: 2px dashed #3b71fe;
}
.entry__code img {
  width: 100%;
  border-radius: 8px;
}
.entry__wrap {
  display: none;
}
.entry__wrap .entry__button {
  width: 100%;
  margin-top: 48px;
}
body.dark .entry__wrap .entry__button {
  box-shadow: inset 0 0 0 2px #777e90;
}
body.dark .entry__wrap .entry__button:hover {
  box-shadow: inset 0 0 0 2px #b1b5c3;
}
.registration__top {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .registration__top {
  border-color: #353945;
}
.registration__top .registration__info {
  margin-bottom: 20px;
}
.registration__title {
  margin-bottom: -5px;
  text-align: center;
}
.registration__info {
  text-align: left;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.registration__btns {
  display: flex;
  justify-content: center;
  margin: 0 -10px;
  max-width: 400px;
}
@media only screen and (max-width: 767px) {
  .registration__btns {
    margin: 0 -6px;
  }
}
.registration__btns .registration__button {
  flex: 0 0 calc(50% - 20px);
  margin: 0 10px;
  max-width: 180px;
}
@media only screen and (max-width: 767px) {
  .registration__btns .registration__button {
    flex: 0 0 calc(50% - 12px);
    margin: 0 6px;
  }
}
.registration__btns .registration__button:not(:last-child) {
  margin-right: 12px;
}
@media only screen and (max-width: 767px) {
  .registration__btns .registration__button:not(:last-child) {
    margin-right: 6px;
  }
}
.registration .field:not(:last-child) {
  margin-bottom: 32px;
}
.registration .checkbox__text {
  font-weight: 400;
  color: #777e90;
}
body.dark .registration .checkbox__text {
  color: #777e90;
}
.registration .checkbox__link {
  font-weight: 500;
}
.registration__form > .registration__button,
.registration__confirm .registration__button {
  width: 100%;
  margin-top: 32px;
}
.registration__form > .registration__info,
.registration__confirm .registration__info {
  margin-bottom: 32px;
}
.registration__code .registration__info {
  margin-bottom: 12px;
}
.registration__variants {
  display: flex;
  flex-direction: column;
}
.registration .radio:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .registration .radio:not(:last-child) {
  border-color: #353945;
}
.registration__numbers {
  display: flex;
  margin: 0 -8px;
}
.registration__number {
  flex: 0 0 calc(25% - 16px);
  width: calc(25% - 16px);
  margin: 0 8px;
}
.registration__number input {
  width: 100%;
  height: 96px;
  border-radius: 8px;
  border: 1px solid #f4f5f6;
  background: #f4f5f6;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #23262f;
  transition: all 0.2s;
}
.registration__number input:focus {
  border-color: #e6e8ec;
  background: #fcfcfd;
}
body.dark .registration__number input {
  background: #23262f;
  border-color: #23262f;
  color: #fcfcfd;
}
body.dark .registration__number input:focus {
  background: none;
  border-color: #353945;
}
.registration__confirm,
.registration__code {
  display: none;
}
.radio {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.radio__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.radio__input:checked + .radio__inner .radio__tick:before {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.radio__inner {
  display: flex;
}
.radio__inner:hover .radio__tick {
  border-color: #3b71fe;
}
.radio__tick {
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
  border: 2px solid #e6e8ec;
  transition: all 0.2s;
}
body.dark .radio__tick {
  background: #141416;
  border-color: #353945;
}
.radio__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #3b71fe;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.radio__text {
  line-height: 1.71429;
  color: #23262f;
}
body.dark .radio__text {
  color: #fcfcfd;
}
.radio__phone {
  display: block;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
}
.radio__email {
  font-weight: 500;
}
.popup {
  width: 100%;
  max-width: 448px;
  margin: auto;
  padding: 32px;
  background: #fcfcfd;
  border-radius: 20px;
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  z-index: 2;
}
body.dark .popup {
  background: #23262f;
}
@media only screen and (max-width: 767px) {
  .popup {
    padding: 32px 16px;
  }
}
.popup__title {
  margin-bottom: 32px;
  padding-right: 56px;
}
@media only screen and (max-width: 767px) {
  .popup__title {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
}
.popup__title .icon {
  position: relative;
  top: -2px;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
@media only screen and (max-width: 767px) {
  .popup__title .icon {
    top: 0;
    margin-right: 8px;
  }
}
body.dark .popup__title .icon {
  fill: #fcfcfd;
}
.popup .field:not(:last-child),
.popup .checkbox:not(:last-child),
.popup__wrap:not(:last-child),
.popup__sign:not(:last-child),
.popup__list:not(:last-child),
.popup__percent:not(:last-child) {
  margin-bottom: 32px;
}
body.dark .popup .select {
  background: none;
}
.popup__wrap {
  display: flex;
  padding: 20px 24px;
  border-radius: 4px;
  background: #f4f5f6;
}
body.dark .popup__wrap {
  background: #353945;
}
.popup__wrap .popup__category {
  margin-right: auto;
  font-weight: 500;
  color: #353945;
}
body.dark .popup__wrap .popup__category {
  color: #e6e8ec;
}
.popup__wrap .popup__details {
  text-align: right;
}
.popup__currency {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.popup__wrap .popup__price {
  color: #777e90;
}
.popup__sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #e6e8ec;
}
.popup__sign .icon {
  width: 16px;
  height: 16px;
  fill: #353945;
}
body.dark .popup__sign {
  background: #353945;
}
body.dark .popup__sign .icon {
  fill: #e6e8ec;
}
.popup__line {
  display: flex;
  justify-content: space-between;
}
.popup__line:not(:last-child) {
  margin-bottom: 16px;
}
.popup__line .popup__category {
  font-weight: 500;
  color: #353945;
}
body.dark .popup__line .popup__category {
  color: #e6e8ec;
}
.popup__line .popup__details {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .popup__line .popup__details {
    position: relative;
    top: 2px;
  }
}
.popup__line .popup__price {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .popup__line .popup__price {
    font-size: 14px;
  }
}
.popup__line .popup__note {
  color: #777e90;
}
.popup__percent {
  display: flex;
  justify-content: space-between;
}
.popup__percent .popup__button {
  height: 24px;
  padding: 0 16px;
  border-radius: 12px;
}
.popup > .popup__button {
  width: 100%;
}
.popup .mfp-close {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: normal;
  opacity: 1;
  border: 1px solid #e6e8ec;
  background: #fcfcfd
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%2323262F'/%3E%3C/svg%3E")
    no-repeat 50% 50%/14px auto;
  font-size: 0;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (max-width: 767px) {
  .popup .mfp-close {
    top: 28px;
    right: 16px;
  }
}
body.dark .popup .mfp-close {
  border-color: #353945;
  background-color: #23262f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
}
.popup .mfp-close:hover {
  border-color: #e6e8ec;
}
body.dark .popup .mfp-close:hover {
  border-color: #fcfcfd;
}
.mfp-container {
  padding: 48px;
}
@media only screen and (max-width: 767px) {
  .mfp-container {
    padding: 16px 8px;
  }
}
@media (max-width: 900px) {
  .mfp-container {
    padding: 16px 8px;
  }
}
.mfp-bg {
  background: rgba(20, 20, 22, 0.5);
}
body.dark .mfp-bg {
  background: rgba(20, 20, 22, 0.75);
}
.mfp-zoom-in .popup {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-out;
}
.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1;
}
.mfp-zoom-in.mfp-removing .popup {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}
.onex_wl {
  display: flex;
  padding: 4px;
  background: #f4f5f6;
}
.onex_wl.bg_white {
  background: #ffffff;
}
.onex_wl.mm {
  background: #ffffff;
}
@media only screen and (max-width: 1023px) {
  .onex_wl {
    display: block;
    padding: 32px 16px 16px;
  }
}
body.dark .onex_wl {
  background: #141416;
}
.onex_wl__sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 256px;
  width: 256px;
  height: calc(100vh - 88px);
  padding-top: 24px;
  background: #fcfcfd;
  border-radius: 4px;
  position: fixed;
  top: 90px;
  left: 0;
  height: 100%;
  z-index: 2;
}
/* @media only screen and (max-width: 1179px) {
  .onex_wl__sidebar {
    flex: 0 0 180px;
    width: 180px;
  }
}
@media only screen and (max-width: 1023px) {
  .onex_wl__sidebar {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    padding-top: 0;
    background: none;
  }
} */
body.dark .onex_wl__sidebar {
  background: #18191d;
}
@media only screen and (max-width: 1023px) {
  body.dark .onex_wl__sidebar {
    background: none;
  }
}
.onex_wl__wrapper {
  flex-grow: 1;
  height: 100%;
  padding-left: 280px;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-right: 30px;
  overflow: auto;
  max-width: 1920px;
  margin: auto;
  min-height: calc(100vh - 370px);
}

.outer__inner.xxc .onex_wl__wrapper {
  min-height: calc(100vh - 84px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1023px) {
  .onex_wl__wrapper {
    height: auto;
    padding-left: 280px;
    padding-right: 24px;
  }
}
.onex_wl__group {
  margin-bottom: auto;
}
@media only screen and (max-width: 1023px) {
  /* .onex_wl__group {
    position: relative;
    z-index: 5;
    margin: 32px 0 0;
  } */
  .onex_wl__group.active .onex_wl__top {
    box-shadow: inset 0 0 0 2px #3b71fe;
  }
  .onex_wl__group.active .onex_wl__top:after {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  .onex_wl__group.active .onex_wl__menu {
    visibility: visible;
    opacity: 1;
  }
}
.onex_wl__top {
  display: none;
}
@media only screen and (max-width: 1023px) {
  /* .onex_wl__top {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 64px 0 0;
    border-radius: 12px;
    background: #fcfcfd;
    box-shadow: inset 0 0 0 2px #e6e8ec;
    font-weight: 500;
    transition: all 0.2s;
  } */
  body.dark .onex_wl__top {
    background: #18191d;
    box-shadow: inset 0 0 0 2px #353945;
  }
  .onex_wl__top:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 24px;
    height: 24px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M16.207 9.793a1 1 0 0 0-1.414 0L12 12.586 9.207 9.793a1 1 0 0 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E")
      no-repeat 50% 50%/100% auto;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
}
.onex_wl__menu {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1023px) {
  /* .onex_wl__menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    box-shadow: 0px 16px 64px -16px rgba(31, 47, 70, 0.15);
    border-radius: 12px;
    background: #fcfcfd;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  } */
  body.dark .onex_wl__menu {
    background: #353945;
  }
}
.onex_wl__link {
  display: flex;
  align-items: center;
  height: 48px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: color 0.2s;
}
.onex_wl__link .icon {
  width: 20px;
  height: 20px;
  margin: 0 14px 0 22px;
  fill: #777e90;
  transition: fill 0.2s;
}
.onex_wl__link .icon.f_icn {
  fill: none;
  stroke: #777e90;
}

.onex_wl__link:hover,
.onex_wl__link.active {
  color: #23262f;
}
@media only screen and (max-width: 1023px) {
  .onex_wl__link:hover,
  .onex_wl__link.active {
    background: #e6e8ec;
  }
}
.onex_wl__link:hover .icon,
.onex_wl__link.active .icon {
  fill: #23262f;
}
.onex_wl__link:hover .icon.f_icn,
.onex_wl__link.active .icon.f_icn {
  fill: none;
  stroke: #23262f;
}

body.dark .onex_wl__link:hover,
body.dark .onex_wl__link.active {
  color: #fcfcfd;
}
@media only screen and (max-width: 1023px) {
  body.dark .onex_wl__link:hover,
  body.dark .onex_wl__link.active {
    background: #353945;
  }
}
body.dark .onex_wl__link:hover .icon,
body.dark .onex_wl__link.active .icon {
  fill: #fcfcfd;
}
.onex_wl__link:not(:last-child).onex_wl__link_separator {
  position: relative;
  margin-bottom: 41px;
}
@media only screen and (max-width: 1023px) {
  .onex_wl__link:not(:last-child).onex_wl__link_separator {
    margin-bottom: 0;
  }
}
.onex_wl__link:not(:last-child).onex_wl__link_separator:after {
  content: "";
  position: absolute;
  top: calc(100% + 20px);
  left: 0;
  right: 0;
  height: 1px;
  background: #e6e8ec;
}
body.dark .onex_wl__link:not(:last-child).onex_wl__link_separator:after {
  background: #23262f;
}
@media only screen and (max-width: 1023px) {
  .onex_wl__link:not(:last-child).onex_wl__link_separator:after {
    display: none;
  }
}
/* @media only screen and (max-width: 1023px) {
  .onex_wl__link_separator ~ .onex_wl__link {
    display: none;
  }
} */
.onex_wl__color {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin: 0 18px 0 26px;
  border-radius: 4px;
}
.onex_wl__color.und_lnk {
  margin-left: -5px;
  margin-right: 10px;
}

.onex_wl__btns {
  margin-top: 24px;
  padding: 16px;
  margin-bottom: 85px;
}
/* @media only screen and (max-width: 1023px) {
  .onex_wl__btns {
    display: flex;
    margin: 0 -6px;
    padding: 0;
    order: -1;
  }
} */
.onex_wl__btns .onex_wl__button {
  width: 100%;
}
/* @media only screen and (max-width: 1023px) {
  .onex_wl__btns .onex_wl__button {
    flex: 1;
    margin: 0 6px;
  }
}
.onex_wl__btns .onex_wl__button:last-child {
	display:none;
} */
@media only screen and (max-width: 1023px) {
  .onex_wl__btns .onex_wl__button:last-child {
    display: flex;
  }
}
.onex_wl__btns .onex_wl__button:not(:last-child) {
  margin-bottom: 12px;
}
@media only screen and (max-width: 1023px) {
  .onex_wl__btns .onex_wl__button:not(:last-child) {
    margin-bottom: 0;
  }
}
.onex_wl__main {
  padding: 32px 10px;
  /* background:#FCFCFD;
	border-radius:4px; */
}
.onex_wl__main.ovrvw {
  background: #fff;
  width: 95%;
  height: 100%;
  padding: 60px 40px;
  border-radius: 5px;
  min-height: auto;
  position: relative;
  overflow: hidden;

  display: block;
}

.admin .onex_wl__main {
  padding-top: 0;
  padding-bottom: 0;
}
body.dark .onex_wl__main {
  background: #18191d;
}
.onex_wl__line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.admin .onex_wl__line {
  margin-bottom: 0px;
}
@media only screen and (max-width: 767px) {
  .onex_wl__line {
    flex-wrap: wrap;
  }
}
.onex_wl__title {
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .onex_wl__title {
    flex: 0 0 100%;
    margin: 0 0 20px;
  }
}
.onex_wl__description {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.onex_wl__text {
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .onex_wl__text {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
}
.onex_wl__text span {
  margin-left: 12px;
  color: #b1b5c3;
}
@media only screen and (max-width: 1023px) {
  .onex_wl__text span {
    margin-left: 6px;
  }
}
.onex_wl__back {
  margin-right: 12px;
}
.onex_wl__back .icon {
  width: 32px;
  height: 32px;
  fill: #777e90;
  transition: fill 0.2s;
}
.onex_wl__back:hover .icon {
  fill: #23262f;
}
.onex_wl__control {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .onex_wl__control {
    display: none;
  }
}
.onex_wl__control .onex_wl__button:not(:last-child) {
  margin-right: 12px;
}
.onex_wl__wrap {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .onex_wl__wrap {
    flex-wrap: wrap;
  }
}
.onex_wl .subscription {
  width: 256px;
  margin-right: 8px;
}
@media only screen and (max-width: 1179px) {
  .onex_wl .subscription {
    width: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .onex_wl .subscription {
    width: 100%;
    margin: 0 0 16px;
  }
}
.onex_wl .subscription__input {
  height: 40px;
  font-size: 12px;
}
.onex_wl .subscription__btn {
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: none;
}
.onex_wl .subscription__btn .icon {
  width: 20px;
  height: 20px;
  fill: #777e90;
  transition: fill 0.2s;
}
.onex_wl .subscription__btn:hover .icon {
  fill: #23262f;
}
body.dark .onex_wl .subscription__btn:hover .icon {
  fill: #fcfcfd;
}
/* .onex_wl .select {
	width:104px;
	height:40px;
	margin-right:8px;
	line-height:40px;
	background:#E6E8EC;
	border-radius:20px;
}
body.dark .onex_wl .select {
	background:#353945;
} */
.onex_wl__info {
  margin-bottom: 4px;
  font-weight: 500;
}
.onex_wl__currency {
  display: flex;
  align-items: center;
}
.onex_wl__number {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.onex_wl__category {
  margin-left: 8px;
}
.onex_wl__price {
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.onex_wl__head {
  padding: 20px 32px 8px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .onex_wl__head {
    padding: 20px 0 8px;
  }
}
.onex_wl__body {
  background: #fcfcfd;
  border-radius: 4px;
  overflow: hidden;
}
body.dark .onex_wl__body {
  background: #18191d;
}
.successfully {
  padding-top: 40px;
}
.successfully__title {
  margin-bottom: 32px;
  text-align: center;
}
.successfully__info {
  max-width: 280px;
  margin: 0 auto 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.successfully__info span {
  color: #58bd7d;
}
.successfully__list {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e6e8ec;
}
@media only screen and (max-width: 767px) {
  .successfully__list {
    padding: 24px 16px;
  }
}
body.dark .successfully__list {
  border-color: #353945;
}
.successfully__item:first-child {
  margin-right: auto;
}
.successfully__item:first-child .successfully__content {
  color: #58bd7d;
}
.successfully__item:nth-child(3) {
  flex: 0 0 100%;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e6e8ec;
}
body.dark .successfully__item:nth-child(3) {
  border-color: #353945;
}
.successfully__category {
  margin-bottom: 10px;
  color: #777e90;
}
.successfully__content {
  font-weight: 500;
  color: #23262f;
}
body.dark .successfully__content {
  color: #fcfcfd;
}
.successfully > .successfully__button {
  width: 100%;
  margin-top: 32px;
}
.successfully__btns {
  display: flex;
  margin: 32px -8px 0;
}
.successfully__btns .successfully__button {
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}
.overview__list {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
  background: #f4f5f6;
}
body.dark .overview__list {
  background: #141416;
}
.overview__item {
  flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
  margin: 10px;
  border-radius: 4px;
  background: #fcfcfd;
}
.overview__item.ticket {
  flex-basis: calc(50% - 20px);
  width: calc(50% - 20px);
}
.overview__item.info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tickets_info.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
}
@media only screen and (max-width: 767px) {
  .overview__item {
    flex: 0 0 calc(100% - 4px);
    width: calc(100% - 4px);
  }
}
body.dark .overview__item {
  background: #18191d;
}
/* @media only screen and (max-width: 767px) {
  .overview__item_soon {
    display: none;
  }
} */
.overview__head {
  display: flex;
  align-items: flex-start;
  min-height: 89px;
  padding: 20px 32px;
  border-bottom: 1px solid #f1f2f4;
}
body.dark .overview__head {
  border-color: #23262f;
}
.info .overview__head {
  border-bottom: none;
  min-height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  height: 100%;
}
.tickets_info.info {
  border-bottom: 1px solid #f1f2f4;
}
body.dark .tickets_info.info {
  border-color: #23262f;
}
.overview__title {
  display: flex;
  align-items: center;
  margin-right: auto;
  font-weight: 500;
  color: #353945;
}
.info .overview__title {
  font-weight: 600;
}
.overview__item.info,
.tickets_info.info {
  color: #353945;
}
body.dark .overview__title,
body.dark .overview__item.info,
body.dark .tickets_info.info {
  color: #f4f5f6;
}
.overview__details {
  text-align: right;
}
.info .overview__details {
  text-align: left;
  margin-right: auto;
}
.overview__currency {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.overview__price {
  color: #777e90;
}
.overview__color {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 4px;
}
.overview__body {
  padding: 20px 12px;
}
.overview__soon {
  display: inline-block;
  padding: 8px 16px;
  box-shadow: inset 0 0 0 2px #e6e8ec;
  border-radius: 16px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #58bd7d;
}
body.dark .overview__soon {
  box-shadow: inset 0 0 0 2px #353945;
}
.overview__btns {
  display: flex;
}
.overview__button {
  height: 32px;
}
.overview__button:not(:last-child) {
  margin-right: 8px;
}
.overview__wrap {
  padding-bottom: 4px;
}
.overview__table {
  display: table;
  width: 100%;
}
.overview__row {
  display: table-row;
  color: #23262f;
  transition: background 0.2s;
}
.overview__row:first-child .overview__col {
  padding-top: 20px;
  border-bottom: 1px solid #f4f5f6;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #353945;
}
body.dark .overview__row:first-child .overview__col {
  border-color: #23262f;
  color: #fcfcfd;
}
.overview__row:not(:first-child):not(:last-child) .overview__col {
  border-bottom: 1px solid #e6e8ec;
}
body.dark .overview__row:not(:first-child):not(:last-child) .overview__col {
  border-color: #23262f;
}
.overview__row:not(:first-child):hover {
  background: #f4f5f6;
}
body.dark .overview__row:not(:first-child):hover {
  background: #23262f;
}
.overview__col {
  display: table-cell;
  padding: 16px;
}
@media only screen and (max-width: 767px) {
  .overview__col {
    padding: 16px 8px;
  }
}
body.dark .overview__col {
  color: #fcfcfd;
}
.overview__col:first-child {
  padding-left: 32px;
}
.overview__col:last-child {
  padding-right: 32px;
}
.overview__col:nth-child(3),
.overview__col:nth-child(4),
.overview__col:last-child {
  text-align: right;
}
@media only screen and (max-width: 1023px) {
  .overview__col:nth-child(2) {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .overview__col:not(:first-child):not(:last-child) {
    display: none;
  }
}
.overview__element {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .overview__element {
    position: relative;
    bottom: -3px;
  }
}
.overview__icon {
  flex-shrink: 0;
  width: 32px;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .overview__icon {
    margin-right: 12px;
  }
}
.overview__icon img {
  width: 100%;
}
.overview__info {
  font-weight: 500;
}
.overview__text {
  color: #777e90;
}
.integrations__list {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
  background: #f4f5f6;
}
body.dark .integrations__list {
  background: #141416;
}
.integrations__item {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin: 10px;
  border-radius: 4px;
  background: #fcfcfd;
}
@media only screen and (max-width: 767px) {
  .integrations__item {
    flex: 0 0 calc(100% - 4px);
    width: calc(100% - 4px);
  }
}
body.dark .integrations__item {
  background: #18191d;
}
.integrations__head {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 20px 32px;
  border-bottom: 1px solid #f1f2f4;
}
body.dark .integrations__head {
  border-color: #23262f;
}
.integrations__title {
  position: relative;
  margin-right: auto;
  padding-left: 20px;
  font-weight: 500;
  color: #353945;
}
body.dark .integrations__title {
  color: #f4f5f6;
}
.integrations__title:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  border: 2px solid #777e90;
}
.integrations__content {
  max-width: 336px;
  margin-left: 24px;
  text-align: right;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.info .integrations__content {
  text-align: left;
  margin-left: 0;
  font-size: 15px;
  margin-bottom: 6px;
}
.integrations__body {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 32px;
}
.integrations__radio {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.integrations__radio:not(:last-child) {
  margin-right: 24px;
}
.integrations__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.integrations__input:checked + .integrations__inner .integrations__line {
  color: #58bd7d;
}
.integrations__input:checked + .integrations__inner .integrations__line .icon {
  fill: #58bd7d;
}
.integrations__input:checked + .integrations__inner .integrations__text span:first-child {
  display: inline;
}
.integrations__input:checked + .integrations__inner .integrations__text span:nth-child(2) {
  display: none;
}
.integrations__line {
  display: flex;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: color 0.2s;
}
.integrations__line .icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  fill: #777e90;
  transition: fill 0.2s;
}
body.dark .integrations__line .icon {
  fill: #777e90;
}
.integrations__line:hover {
  color: #23262f;
}
body.dark .integrations__line:hover {
  color: #fcfcfd;
}
body.dark .integrations__line:hover .icon {
  fill: #fcfcfd;
}
.integrations__text span:first-child {
  display: none;
}
.integrations__button {
  height: 32px;
}
.deposit__head {
  padding-top: 72px;
}
.deposit__head .deposit__icon {
  background: #9757d7;
}
.deposit__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 0 auto 32px;
  border-radius: 50%;
}
.deposit__icon .icon {
  width: 24px;
  height: 24px;
  fill: #fcfcfd;
}
.deposit__info {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.deposit__btns .deposit__button {
  width: 100%;
}
.deposit__btns .deposit__button:not(:last-child) {
  margin-bottom: 8px;
}
.deposit__title {
  margin-bottom: 8px;
  padding-right: 56px;
}
@media only screen and (max-width: 767px) {
  .deposit__title .h4 {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
}
.deposit__back {
  position: relative;
  top: -7px;
  display: inline-block;
  margin-right: 16px;
}
@media only screen and (max-width: 767px) {
  .deposit__back {
    top: -5px;
  }
}
.deposit__back .icon {
  width: 32px;
  height: 32px;
  transition: fill 0.2s;
}
body.dark .deposit__back .icon {
  fill: #fcfcfd;
}
.deposit__back:hover .icon {
  fill: #3b71fe;
}
.deposit__subtitle {
  margin-bottom: 32px;
  padding-left: 48px;
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.deposit__wrap {
  display: flex;
  margin-bottom: 32px;
  padding: 20px 32px;
  border-radius: 4px;
  background: #f4f5f6;
}
@media only screen and (max-width: 767px) {
  .deposit__wrap {
    padding: 20px;
  }
}
body.dark .deposit__wrap {
  background: #353945;
}
.deposit__category {
  position: relative;
  margin-right: auto;
  padding-left: 20px;
  font-weight: 500;
  color: #353945;
}
body.dark .deposit__category {
  color: #e6e8ec;
}
.deposit__category:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background: #58bd7d;
}
.deposit__details {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .deposit__details {
    padding-top: 2px;
  }
}
.deposit__currency {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .deposit__currency {
    font-size: 14px;
  }
}
.deposit__price {
  color: #777e90;
}
.deposit__stage {
  margin-bottom: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.deposit__content {
  margin-bottom: 32px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.deposit__code {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 310px;
  height: 40px;
  margin: 0 auto 32px;
  background: #f4f5f6;
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  font-weight: 500;
}
body.dark .deposit__code {
  background: #353945;
  border-color: #353945;
}
.deposit__copy {
  margin-left: 12px;
}
.deposit__copy .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
  transition: fill 0.2s;
}
.deposit__copy:hover .icon {
  fill: #23262f;
}
body.dark .deposit__copy:hover .icon {
  fill: #fcfcfd;
}
.deposit__preview {
  width: 160px;
  margin: 0 auto 32px;
  padding: 14px;
  border: 2px dashed #3b71fe;
  border-radius: 12px;
}
.deposit__preview img {
  width: 100%;
  border-radius: 8px;
}
.deposit__note {
  text-align: center;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 500;
  color: #777e90;
}
.details__row {
  display: flex;
  background: #f4f5f6;
}
@media only screen and (max-width: 767px) {
  .details__row {
    display: block;
  }
}
body.dark .details__row {
  background: #141416;
}
.details__col:first-child {
  flex-grow: 1;
  padding-right: 4px;
}
@media only screen and (max-width: 767px) {
  .details__col:first-child {
    padding-right: 0;
  }
}
.details__col:first-child .details__price {
  color: #777e90;
}
.details__col:nth-child(2) {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 285px;
  padding: 24px;
  background: #fcfcfd;
  border-radius: 4px;
}
@media only screen and (max-width: 767px) {
  .details__col:nth-child(2) {
    width: 100%;
    margin-top: 4px;
  }
}
body.dark .details__col:nth-child(2) {
  background: #17181b;
}
.details__col:nth-child(2) .details__price {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.details__list {
  display: flex;
  flex-wrap: wrap;
  margin: -2px;
}
.details__item {
  display: flex;
  align-items: flex-start;
  flex: 0 0 calc(50% - 4px);
  width: calc(50% - 4px);
  margin: 2px;
  padding: 20px 32px;
  border-radius: 4px;
  background: #fcfcfd;
}
@media only screen and (max-width: 1339px) {
  .details__item {
    flex: 0 0 calc(100% - 4px);
    width: calc(100% - 4px);
  }
}
@media only screen and (max-width: 767px) {
  .details__item {
    padding: 20px 24px;
  }
}
body.dark .details__item {
  background: #17181b;
}
.details__item:last-child {
  flex: 0 0 calc(100% - 4px);
  width: calc(100% - 4px);
}
.details__title {
  display: flex;
  align-items: center;
  margin-right: auto;
  font-weight: 500;
  color: #353945;
}
body.dark .details__title {
  color: #f4f5f6;
}
.details__color {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 4px;
}
.details__wrap {
  text-align: right;
}
.details__currency {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.details__info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.details__info .details__category {
  border-radius: 13px;
}
.details__money {
  margin-right: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.details__chart {
  margin: -8px 0 -16px;
  width: 100%;
}

.margin {
  padding: 32px;
  background: #fcfcfd;
  border-radius: 4px;
}
body.dark .margin {
  background: #17181b;
}
@media only screen and (max-width: 767px) {
  .margin {
    padding: 24px 16px;
  }
}
.margin__title {
  margin-bottom: 20px;
}
.margin__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .margin__list {
    display: block;
  }
}
.margin__item_flex {
  display: flex;
  align-items: center;
}
.margin__item_flex .margin__number {
  color: #58bd7d;
}
@media only screen and (max-width: 767px) {
  .margin__item:not(:last-child) {
    margin-bottom: 16px;
  }
}
.margin__info {
  margin-bottom: 4px;
  font-weight: 500;
  color: #353945;
}
body.dark .margin__info {
  color: #777e90;
}
.margin__currency {
  display: flex;
  align-items: center;
}
.margin__number {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 1023px) {
  .margin__number {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .margin__number {
    font-size: 24px;
  }
}
.margin__category {
  margin-left: 8px;
}
.margin__price,
.margin__stage {
  font-size: 16px;
  line-height: 1.5;
  color: #777e90;
}
.margin__chart {
  margin-right: 24px;
}
.margin__group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .margin__group {
    flex-wrap: wrap;
    margin-top: -24px;
  }
}
@media only screen and (max-width: 767px) {
  .margin__element {
    flex: 0 0 50%;
    width: 50%;
    margin-top: 24px;
  }
}
@media only screen and (max-width: 1179px) {
  .margin__element .margin__info {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1179px) {
  .margin__element .margin__number {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .margin__element .margin__number {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1179px) {
  .margin__element .margin__price {
    font-size: 12px;
  }
}
.balances {
  padding: 20px 0 0;
}
@media only screen and (max-width: 767px) {
  .balances {
    padding: 0;
  }
}
.balances__line {
  display: flex;
  align-items: center;
  padding: 0 32px;
}
@media only screen and (max-width: 767px) {
  .balances__line {
    display: none;
  }
}
.balances .subscription {
  margin-right: auto;
}
.balances__link {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
  color: #777e90;
  transition: color 0.2s;
}
.balances__link .icon {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  fill: #777e90;
  transition: fill 0.2s;
}
.balances__link:hover {
  color: #23262f;
}
.balances__link:hover .icon {
  fill: #23262f;
}
body.dark .balances__link:hover {
  color: #fcfcfd;
}
body.dark .balances__link:hover .icon {
  fill: #fcfcfd;
}
.balances__row {
  display: flex;
}
.balances__list > .balances__row {
  border-bottom: 1px solid #f4f5f6;
}
body.dark .balances__list > .balances__row {
  border-color: #23262f;
}
.balances__col {
  flex: 1;
  padding: 16px;
}
.balances__col:first-child {
  padding-left: 32px;
}
@media only screen and (max-width: 767px) {
  .balances__col:first-child {
    padding-left: 16px;
  }
}
.balances__col:last-child {
  padding-right: 32px;
}
@media only screen and (max-width: 767px) {
  .balances__col:last-child {
    padding-right: 16px;
  }
}
.balances__col:not(:first-child) {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .balances__col:not(:first-child):not(:nth-child(2)) {
    display: none;
  }
}
.balances__list > .balances__row .balances__col {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #353945;
}
body.dark .balances__list > .balances__row .balances__col {
  color: #e6e8ec;
}
.balances__item {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.2s;
}
.balances__item:hover,
.balances__item.active {
  background: #f8f8f9;
}
body.dark .balances__item:hover,
body.dark .balances__item.active {
  background: #23262f;
}
.balances__item.active .balances__btns {
  display: flex;
}
.balances__item:not(:last-child) {
  border-bottom: 1px solid #e6e8ec;
}
body.dark .balances__item:not(:last-child) {
  border-color: #23262f;
}
@media only screen and (max-width: 1023px) {
  body.dark .balances__item:not(:last-child) {
    border-color: #353945;
  }
}
.balances__item .balances__row {
  display: flex;
  padding: 16px 0 20px;
  cursor: pointer;
}
.balances__item .balances__col {
  padding: 0 16px;
}
.balances__item .balances__col:first-child {
  padding-left: 32px;
}
@media only screen and (max-width: 767px) {
  .balances__item .balances__col:first-child {
    padding-left: 16px;
  }
}
.balances__item .balances__col:last-child {
  padding-right: 32px;
}
@media only screen and (max-width: 767px) {
  .balances__item .balances__col:last-child {
    padding-right: 16px;
  }
}
.balances__currency {
  display: flex;
}
.balances__currency .balances__text {
  font-weight: 400;
}
.balances__icon {
  flex-shrink: 0;
  width: 32px;
  margin-right: 20px;
}
.balances__icon img {
  width: 100%;
}
.balances__info {
  font-weight: 500;
}
.balances__text {
  font-weight: 500;
  color: #777e90;
}
.balances__btns {
  display: none;
  margin-top: -10px;
  padding: 0 32px 20px;
}
@media only screen and (max-width: 767px) {
  .balances__btns {
    padding: 0 16px 20px;
  }
}
.balances__button {
  height: 32px;
}
@media only screen and (max-width: 767px) {
  .balances__button {
    flex-grow: 1;
    padding: 0;
  }
}
.balances__button:not(:last-child) {
  margin-right: 8px;
}
.bidding__head {
  padding: 40px 0;
}
@media only screen and (max-width: 767px) {
  .bidding__head {
    padding: 16px 0 32px;
  }
}
.bidding__head .bidding__center {
  align-items: center;
}
.bidding__center {
  display: flex;
}
@media only screen and (max-width: 1179px) {
  .bidding__center {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .bidding__center {
    display: block;
    padding: 0 32px;
  }
}
.bidding__title {
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .bidding__title {
    margin: 0 0 16px;
    font-size: 32px;
    line-height: 40px;
  }
}
.bidding__info {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
  color: #777e90;
}
.bidding__link {
  margin-left: 8px;
  color: #23262f;
  transition: color 0.2s;
}
.bidding__link .icon {
  width: 20px;
  height: 20px;
  fill: #23262f;
  transition: fill 0.2s;
}
body.dark .bidding__link {
  color: #e6e8ec;
}
body.dark .bidding__link .icon {
  fill: #e6e8ec;
}
.bidding__link:hover {
  color: #3b71fe;
}
.bidding__link:hover .icon {
  fill: #3b71fe;
}
.bidding__body {
  padding: 80px 0 136px;
  background: #f8f8f9;
}
@media only screen and (max-width: 1179px) {
  .bidding__body {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 1023px) {
  .bidding__body {
    padding-top: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .bidding__body {
    padding: 32px 0;
    border-top: 1px solid #e6e8ec;
    background: none;
  }
}
body.dark .bidding__body {
  background: none;
  box-shadow: inset 0 1px 0 0 #23262f;
}
@media only screen and (max-width: 767px) {
  body.dark .bidding__body {
    border: none;
  }
}
@media only screen and (max-width: 1023px) {
  .bidding__body .bidding__center {
    display: block;
  }
}
.bidding__steps {
  flex-shrink: 0;
  width: 220px;
  margin-right: auto;
}
@media only screen and (max-width: 1023px) {
  .bidding__steps {
    display: none;
  }
}
.bidding__step {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 8px;
  border-radius: 24px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: all 0.2s;
}
.bidding__step.next {
  color: #23262f;
}
.bidding__step.next .bidding__number {
  border-color: #58bd7d;
}
.bidding__step.active {
  background: #fcfcfd;
  box-shadow: 0px 4px 16px -8px rgba(15, 15, 15, 0.1);
  color: #23262f;
}
.bidding__step.active .bidding__number {
  border-color: #58bd7d;
}
.bidding__step.active .bidding__number:after {
  opacity: 1;
}
.bidding__step:not(:last-child) {
  margin-bottom: 24px;
}
.bidding__step:not(:last-child):after {
  content: "";
  position: absolute;
  top: 100%;
  left: 23px;
  height: 24px;
  border-left: 2px dashed #b1b5c3;
}
body.dark .bidding__step.next {
  color: #fcfcfd;
}
body.dark .bidding__step.active {
  background: #23262f;
  color: #fcfcfd;
}
body.dark .bidding__step.active:not(:last-child):after {
  border-color: #777e90;
}
.bidding__number {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border: 2px solid #e6e8ec;
  border-radius: 50%;
  transition: all 0.2s;
}
body.dark .bidding__number {
  border-color: #777e90;
}
.bidding__number:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #58bd7d
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M4 8l2.667 2.667L12 5.333' stroke='%23fcfcfd' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
    no-repeat 50% 50%/16px auto;
  opacity: 0;
  transition: opacity 0.2s;
}
.bidding__wrapper {
  width: 736px;
  padding: 40px;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  border-radius: 16px;
  background: #fcfcfd;
}
@media only screen and (max-width: 1179px) {
  .bidding__wrapper {
    flex-grow: 1;
    width: auto;
    margin-left: 64px;
    padding: 32px;
  }
}
@media only screen and (max-width: 1023px) {
  .bidding__wrapper {
    margin-left: 0;
    padding: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .bidding__wrapper {
    padding: 0;
    box-shadow: none;
    background: none;
    border-radius: 0;
  }
}
body.dark .bidding__wrapper {
  background: #18191d;
}
@media only screen and (max-width: 767px) {
  body.dark .bidding__wrapper {
    background: none;
  }
}
.bidding__item:not(:first-child) {
  display: none;
}
.bidding__control {
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;
}
@media only screen and (max-width: 767px) {
  .bidding__control {
    display: block;
    margin-bottom: 32px;
  }
}
.bidding__back {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
body.dark .bidding__back {
  color: #fcfcfd;
}
.bidding__back .icon {
  width: 14px;
  height: 14px;
  margin-right: 16px;
  fill: #777e90;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.bidding__back:hover .icon {
  -webkit-transform: translateX(-2px);
  transform: translateX(-2px);
}
.bidding__money {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .bidding__money {
    display: none;
  }
}
.bidding__money img {
  margin-left: 12px;
  width: 24px;
}
.currency__title {
  margin-bottom: 48px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 767px) {
  .currency__title {
    margin-bottom: 32px;
  }
}
.currency__row {
  display: flex;
  margin: 0 -12px;
}
@media only screen and (max-width: 767px) {
  .currency__row {
    display: block;
    margin: 0;
  }
}
.currency__row:not(:last-child) {
  margin-bottom: 48px;
}
.currency__col {
  flex: 0 0 calc(50% - 24px);
  width: calc(50% - 24px);
  margin: 0 12px;
}
@media only screen and (max-width: 767px) {
  .currency__col {
    width: 100%;
    margin: 0;
  }
  .currency__col:not(:last-child) {
    margin-bottom: 24px;
  }
}
.currency__label {
  margin-bottom: 12px;
  font-weight: 500;
  color: #23262f;
}
body.dark .currency__label {
  color: #fcfcfd;
}
.currency__payment {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.currency .select {
  background: none;
}
body.dark .currency .select {
  background: none;
}
.currency__field {
  position: relative;
}
.currency__value,
.currency__input {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 96px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #23262f;
}
@media only screen and (max-width: 767px) {
  .currency__value,
  .currency__input {
    font-size: 64px;
  }
}
.currency__value {
  min-width: 72px;
  max-width: 500px;
  min-height: 96px;
  padding-right: 5px;
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .currency__value {
    min-height: 64px;
    max-width: 280px;
    padding-bottom: 10px;
  }
}
.currency__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  box-shadow: none;
  color: #23262f;
}
body.dark .currency__input {
  color: #fcfcfd;
}
.currency__sign {
  position: relative;
  top: 8px;
}
@media only screen and (max-width: 767px) {
  .currency__sign {
    font-size: 24px;
  }
}
.currency__sign:first-child {
  margin-right: 8px;
}
.currency__sign:last-child {
  margin-left: 4px;
}
.currency__price {
  margin-bottom: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.currency__note {
  margin-left: 8px;
  color: #777e90;
}
.currency__note:not(:last-child) {
  margin-right: 8px;
}
.currency__variants {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -8px;
}
.currency__variants .currency__button {
  margin: 8px 8px 0;
  color: #777e90;
}
.currency__variants .currency__button:hover {
  color: #fcfcfd;
}
.currency__variants .currency__button.active {
  color: #fcfcfd;
}
.currency__btns,
.currency__foot {
  margin-top: 48px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .currency__btns,
  .currency__foot {
    margin-top: 32px;
  }
}
.currency__btns .currency__button {
  min-width: 184px;
}
@media only screen and (max-width: 767px) {
  .currency__btns .currency__button {
    min-width: 100%;
  }
}
.notes__title {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.notes__text,
.notes__info {
  margin-bottom: 12px;
}
.notes__text {
  color: #777e90;
}
.notes__info {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.notes__code {
  padding: 12px;
  background: #e6e8ec;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
body.dark .notes__code {
  background: #353945;
}
.notes__btns,
.notes__foot {
  margin-top: 24px;
}
.notes__btns {
  text-align: right;
}
.notes__foot {
  text-align: center;
}
.notes__button {
  min-width: 184px;
}
.notes__list {
  margin-bottom: 32px;
}
.notes__line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.notes__line:not(:last-child) {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .notes__line:not(:last-child) {
  border-color: #353945;
}
.notes__subtitle {
  margin-right: auto;
  padding-right: 20px;
  color: #777e90;
}
.notes__details {
  display: flex;
  align-items: center;
}
.notes__content {
  margin-right: 12px;
  font-weight: 500;
}
.notes__copy .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
  transition: fill 0.2s;
}
.notes__copy:hover .icon {
  fill: #23262f;
}
body.dark .notes__copy:hover .icon {
  fill: #fcfcfd;
}
.crypto__title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.crypto__form {
  position: relative;
  flex-shrink: 0;
  margin-bottom: 40px;
}
.crypto__input {
  width: 100%;
  height: 48px;
  padding: 0 48px 0 14px;
  border-radius: 8px;
  background: none;
  border: 2px solid #e6e8ec;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 1.66667;
  color: #23262f;
  transition: border-color 0.2s;
}
body.dark .crypto__input {
  border-color: #353945;
  color: #fcfcfd;
}
.crypto__input::-webkit-input-placeholder {
  color: #777e90;
}
.crypto__input::-moz-placeholder {
  color: #777e90;
}
.crypto__input:-ms-input-placeholder {
  color: #777e90;
}
.crypto__input::-ms-input-placeholder {
  color: #777e90;
}
.crypto__input::placeholder {
  color: #777e90;
}
.crypto__input:focus {
  border-color: #3b71fe;
}
.crypto__result {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
}
.crypto__result .icon {
  width: 20px;
  height: 20px;
  fill: #777e90;
  transition: fill 0.2s;
}
.crypto__result:hover .icon {
  fill: #3b71fe;
}
.crypto__table {
  display: table;
  width: 100%;
}
.crypto__row {
  display: table-row;
  color: #23262f;
}
.crypto__row:first-child .crypto__col {
  padding-top: 0;
  padding-bottom: 24px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.crypto__row:not(:first-child) {
  cursor: pointer;
}
.crypto__row:not(:first-child) .crypto__col {
  border-top: 1px solid #e6e8ec;
  vertical-align: middle;
  font-weight: 500;
}
body.dark .crypto__row:not(:first-child) .crypto__col {
  border-color: #353945;
}
.crypto__row:not(:first-child) .crypto__col:first-child {
  color: #777e90;
}
.crypto__row:not(:first-child):hover .crypto__subtitle {
  color: #3b71fe;
}
.crypto__col {
  display: table-cell;
  padding: 16px;
  text-align: right;
  font-weight: 500;
}
body.dark .crypto__col {
  color: #fcfcfd;
}
.crypto__col:first-child,
.crypto__col:nth-child(2) {
  text-align: left;
}
.crypto__col:first-child {
  font-size: 12px;
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
  .crypto__col:first-child {
    display: none;
  }
}
.crypto__col:last-child {
  padding-right: 0;
}
@media only screen and (max-width: 767px) {
  .crypto__col:last-child {
    display: none;
  }
}
.crypto__line {
  display: flex;
  align-items: center;
}
.crypto .favorite {
  position: relative;
  top: -4px;
  margin-right: 10px;
}
.crypto__item {
  display: flex;
  align-items: center;
}
.crypto__icon {
  flex-shrink: 0;
  width: 32px;
  margin-right: 12px;
}
.crypto__icon img {
  max-width: 100%;
}
.crypto__subtitle {
  margin-right: 4px;
  transition: color 0.2s;
}
.crypto__currency {
  font-weight: 400;
  color: #777e90;
}
.crypto__positive {
  color: #58bd7d;
}
.crypto__negative {
  color: #ff6838;
}
.payment__options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  padding: 24px 36px;
  border-radius: 16px;
  background: #f4f5f6;
}
@media only screen and (max-width: 767px) {
  .payment__options {
    display: block;
    margin-bottom: 32px;
  }
}
body.dark .payment__options {
  background: #23262f;
}
.payment__option {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .payment__option:not(:last-child) {
    margin-bottom: 32px;
  }
}
.payment__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}
.payment__icon .icon {
  width: 20px;
  height: 20px;
  fill: #fcfcfd;
}
.payment__category {
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.payment__content {
  font-weight: 500;
}
.payment__line {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.payment__subtitle {
  margin-right: auto;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.payment__cards {
  display: flex;
  align-items: center;
}
.payment__fieldset > .field {
  margin-bottom: 32px;
}
.payment__row {
  display: flex;
  margin: 0 -8px;
}
.payment__row .field {
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}
.payment .checkbox {
  margin-top: 32px;
}
.payment__info {
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .payment__info {
    margin-bottom: 32px;
  }
}
.payment__flex {
  display: flex;
  justify-content: space-between;
}
.payment__flex:first-child {
  padding-bottom: 12px;
  border-bottom: 1px solid #e6e8ec;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
body.dark .payment__flex:first-child {
  border-color: #353945;
}
.payment__flex:not(:first-child) .payment__cell:first-child {
  color: #777e90;
}
.payment__flex:not(:last-child) {
  margin-bottom: 12px;
}
.payment__cell:nth-child(2) {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.payment__btns {
  margin-top: 48px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .payment__btns {
    margin-top: 32px;
  }
}
.payment__foot {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}
@media only screen and (max-width: 767px) {
  .payment__foot {
    display: block;
    margin-top: 32px;
  }
  .payment__foot .payment__button {
    width: 100%;
  }
  .payment__foot .payment__button:not(:last-child) {
    margin-bottom: 10px;
  }
}
.exchange {
  min-height: calc(100vh - 88px);
  padding: 4px;
  background: #f1f2f4;
}
@media only screen and (max-width: 1023px) {
  .exchange {
    min-height: calc(100vh - 114px);
    padding: 16px 16px 132px;
  }
}
body.dark .exchange {
  background: #141416;
}
.exchange__main {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fcfcfd;
  border-radius: 4px;
}
@media only screen and (max-width: 1023px) {
  .exchange__main {
    display: block;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .exchange__main {
    padding: 16px;
  }
}
body.dark .exchange__main {
  background: #17181b;
}
.exchange__details {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1023px) {
  .exchange__details {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .exchange__details {
    display: block;
    margin-bottom: 24px;
  }
}
.exchange__box .exchange__content {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.exchange__box .exchange__content .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: #777e90;
}
.exchange__box:not(:last-child) {
  margin-right: 32px;
}
@media only screen and (max-width: 767px) {
  .exchange__box:not(:last-child) {
    margin: 0 0 8px;
  }
}
.exchange__line {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.exchange__info {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #141416;
}
body.dark .exchange__info {
  color: #fcfcfd;
}
.exchange__category {
  margin-left: 4px;
  padding: 7px 8px 5px;
  border-radius: 12px;
}
.exchange__price {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #ff6838;
}
.exchange__list {
  display: flex;
  margin-left: auto;
}
@media only screen and (max-width: 1023px) {
  .exchange__list {
    margin: 0;
  }
}
@media only screen and (max-width: 767px) {
  .exchange__list {
    flex-wrap: wrap;
    margin: -12px 0 0;
  }
}
.exchange__item {
  flex: 0 0 160px;
  width: 160px;
  padding-right: 24px;
}
@media only screen and (max-width: 1179px) {
  .exchange__item {
    flex: 0 0 auto;
    width: auto;
  }
}
@media only screen and (max-width: 1023px) {
  .exchange__item {
    flex: 1 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .exchange__item {
    flex: 0 0 50%;
    width: 50%;
    margin: 12px 0;
  }
}
.exchange__item .exchange__content {
  font-weight: 500;
  color: #141416;
}
body.dark .exchange__item .exchange__content {
  color: #fcfcfd;
}
.exchange__item:not(:last-child) {
  margin-right: 24px;
  border-right: 1px solid #e6e8ec;
}
body.dark .exchange__item:not(:last-child) {
  border-color: #23262f;
}
@media only screen and (max-width: 767px) {
  .exchange__item:not(:last-child) {
    margin: 12px 0 0;
    border: none;
  }
}
.exchange__subtitle {
  display: flex;
  align-items: center;
  -webkit-margin-after: 4px;
  margin-block-end: 4px;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.exchange__subtitle .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  fill: #777e90;
}
.exchange > .nav {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .exchange > .nav {
    display: flex;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .exchange > .nav {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 767px) {
  .exchange > .nav .nav__link:not(:last-child) {
    margin-right: 0;
  }
}
.exchange .nav__link.active {
  background: #e6e8ec;
  color: #23262f;
}
.exchange__row {
  display: flex;
  margin-top: 4px;
}
@media only screen and (max-width: 1179px) {
  .exchange__row {
    display: block;
  }
  .exchange__row:after {
    content: " ";
    display: table;
    clear: both;
  }
}
@media only screen and (max-width: 1179px) and (max-width: 1023px) {
  .exchange__row:after {
    display: none;
  }
}
.exchange__col:first-child,
.exchange__col:nth-child(3) {
  flex-shrink: 0;
  width: 256px;
}
@media only screen and (max-width: 1179px) {
  .exchange__col:first-child,
  .exchange__col:nth-child(3) {
    float: left;
  }
}
@media only screen and (max-width: 1023px) {
  .exchange__col:first-child,
  .exchange__col:nth-child(3) {
    float: none;
    width: 100%;
  }
}
.exchange__col:nth-child(2) {
  flex: 0 0 calc(100% - 520px);
  width: calc(100% - 520px);
  margin: 0 4px;
}
@media only screen and (max-width: 1179px) {
  .exchange__col:nth-child(2) {
    float: right;
    width: calc(100% - 260px);
    margin: 0 0 0 4px;
  }
}
@media only screen and (max-width: 1023px) {
  .exchange__col:nth-child(2) {
    float: none;
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 1179px) {
  .exchange__col:first-child {
    margin-bottom: 4px;
  }
}
@media only screen and (max-width: 1023px) {
  .exchange__col:first-child {
    display: none;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .exchange__col:nth-child(3) {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .exchange__col {
    display: block !important;
  }
}
.balance {
  border-radius: 4px;
  background: #fcfcfd;
}
body.dark .balance {
  background: #17181b;
}
.balance__head {
  display: flex;
  align-items: center;
  padding: 16px 16px 12px;
}
.balance__sorting {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.balance__direction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: all 0.2s;
}
.balance__direction span {
  width: 12px;
  height: 2px;
  border-radius: 1;
}
.balance__direction span:not(:last-child) {
  margin-bottom: 2px;
}
.balance__direction:hover,
.balance__direction.active {
  background: #f1f2f4;
}
body.dark .balance__direction:hover,
body.dark .balance__direction.active {
  background: #23262f;
}
.balance__direction:not(:last-child) {
  margin-right: 12px;
}
.balance .select {
  width: 62px;
  height: 32px;
  padding: 0 36px 0 8px;
  background: #f1f2f4;
  border-radius: 4px;
  box-shadow: none;
  font-size: 12px;
  line-height: 32px;
}
body.dark .balance .select {
  background: #23262f;
  box-shadow: inset 0 0 0 2px #23262f;
}
.balance .select:before {
  right: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777E90'/%3E%3C/svg%3E");
}
.balance .select .list {
  border-radius: 4px;
}
.balance .select .option {
  padding: 4px 8px;
  font-size: 12px;
}
.balance__top {
  display: flex;
  margin-bottom: 4px;
  padding: 4px 16px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.balance__item {
  position: relative;
  display: flex;
  padding: 4px 16px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
}
.balance__item.negative .balance__line {
  background: rgba(255, 104, 56, 0.15);
}
.balance__item.negative .balance__price {
  color: #ff6838;
}
.balance__item.positive .balance__line {
  background: rgba(88, 189, 125, 0.15);
}
.balance__item.positive .balance__price {
  color: #58bd7d;
}
.balance__item:not(:last-child) {
  margin-bottom: 4px;
}
@media only screen and (max-width: 1179px) {
  .balance__item:nth-child(n + 10) {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .balance__item:nth-child(n + 10) {
    display: flex;
  }
}
.balance__price {
  font-weight: 600;
}
.balance__price,
.balance__amount {
  flex-shrink: 0;
  width: 70px;
}
.balance__amount {
  text-align: right;
}
.balance__total {
  flex-grow: 1;
  text-align: right;
}
.balance__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.balance__statistics {
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
@media only screen and (max-width: 1179px) {
  .balance__statistics {
    margin-top: 4px;
  }
}
@media only screen and (max-width: 1023px) {
  .balance__statistics {
    margin-top: 12px;
  }
}
.balance__statistics .icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
.balance__statistics:not(:last-child) {
  margin-bottom: 12px;
}
@media only screen and (max-width: 1179px) {
  .balance__statistics:not(:last-child) {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .balance__statistics:not(:last-child) {
    margin-bottom: 12px;
  }
}
.balance__statistics.positive .balance__currency {
  color: #58bd7d;
}
.balance__statistics.positive .balance__icon {
  fill: #58bd7d;
}
.balance__statistics.negative .balance__currency {
  color: #ff6838;
}
.balance__statistics.negative .balance__icon {
  fill: #ff6838;
}
.balance__money {
  margin-left: 8px;
}
.currencies {
  padding: 16px;
  border-radius: 4px;
  background: #fcfcfd;
}
body.dark .currencies {
  background: #17181b;
}
.currencies .nav {
  margin-bottom: 12px;
}
.currencies__form {
  position: relative;
  margin-bottom: 12px;
}
.currencies__input {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 14px;
  border-radius: 8px;
  background: none;
  border: 2px solid #e6e8ec;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 1.66667;
  color: #23262f;
  transition: border-color 0.2s;
}
body.dark .currencies__input {
  border-color: #353945;
  color: #fcfcfd;
}
.currencies__input::-webkit-input-placeholder {
  color: #777e90;
}
.currencies__input::-moz-placeholder {
  color: #777e90;
}
.currencies__input:-ms-input-placeholder {
  color: #777e90;
}
.currencies__input::-ms-input-placeholder {
  color: #777e90;
}
.currencies__input::placeholder {
  color: #777e90;
}
.currencies__input:focus {
  border-color: #777e90;
}
.currencies__result {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
}
.currencies__result .icon {
  width: 20px;
  height: 20px;
  fill: #777e90;
  transition: fill 0.2s;
}
.currencies__result:hover .icon {
  fill: #3b71fe;
}
.currencies__table {
  display: table;
  width: 100%;
}
.currencies__row {
  display: table-row;
}
.currencies__row:first-child .currencies__col {
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.currencies__row:not(:first-child) .currencies__col {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
}
.currencies__col {
  display: table-cell;
  padding: 4px;
}
.currencies__col:first-child {
  padding-left: 0;
}
.currencies__col:last-child {
  padding-right: 0;
  text-align: right;
}
.currencies__line {
  display: inline-flex;
  align-items: center;
}
.currencies .favorite {
  position: relative;
  top: -2px;
  margin-right: 4px;
}
.currencies__info span {
  color: #777e90;
}
.currencies__positive {
  color: #58bd7d;
}
.currencies__negative {
  color: #ff6838;
}
.trades {
  margin-top: 4px;
  padding: 16px;
  border-radius: 4px;
  background: #fcfcfd;
}
body.dark .trades {
  background: #17181b;
}
.trades .nav {
  margin-bottom: 12px;
}
.trades__table {
  display: table;
  width: 100%;
}
.trades__row {
  display: table-row;
}
.trades__row:first-child .trades__col {
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.trades__row:not(:first-child) .trades__col {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
}
@media only screen and (max-width: 1179px) {
  .trades__row:nth-child(n + 12) {
    display: none;
  }
}
.trades__col {
  display: table-cell;
  padding: 2px 4px;
}
.trades__col:first-child {
  padding-left: 0;
}
.trades__col:nth-child(2) {
  padding-right: 12px;
  text-align: right;
}
.trades__col:last-child {
  padding-right: 0;
  text-align: right;
}
.trades__col.positive {
  color: #58bd7d;
}
.trades__col.negative {
  color: #ff6838;
}
.trades__line {
  display: inline-flex;
  align-items: center;
}
.trades .favorite {
  position: relative;
  top: -2px;
  margin-right: 4px;
}
.trades__info span {
  color: #777e90;
}
.table {
  margin-top: 4px;
  padding: 16px;
  border-radius: 4px;
  background: #fcfcfd;
}
body.dark .table {
  background: #17181b;
}
@media only screen and (min-width: 1024px) {
  .table {
    display: block !important;
  }
}
.table .select {
  display: none;
}
@media only screen and (max-width: 767px) {
  .table .select {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
}
.table .nav {
  margin-bottom: 16px;
}
@media only screen and (max-width: 767px) {
  .table .nav {
    display: none;
  }
}
.table .nav__link:not(:last-child) {
  margin-right: 16px;
}
.table__inner {
  display: table;
  width: 100%;
}
.table__row {
  display: table-row;
}
.table__row:first-child .table__col {
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.table__row:not(:first-child) .table__col {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
}
.table__col {
  display: table-cell;
  padding: 6px;
}
.table__col:first-child {
  padding-left: 0;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .table__col:nth-child(3) {
    padding-right: 0;
    text-align: right;
  }
}
.table__col:last-child {
  padding-right: 0;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .table__col:last-child {
    display: none;
  }
}
.table__col.positive {
  color: #58bd7d;
}
.table__col.negative {
  color: #ff6838;
}
.bargaining {
  margin-top: 4px;
  padding: 16px;
  background: #fcfcfd;
  border-radius: 4px;
}
@media only screen and (max-width: 1023px) {
  .bargaining {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    margin: 0;
    box-shadow: 0px -16px 64px rgba(15, 15, 15, 0.1);
  }
}
body.dark .bargaining {
  background: #17181b;
}
.bargaining__main {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1023px) {
  .bargaining__main {
    margin-bottom: 16px;
  }
}
.bargaining__info {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 500;
  color: #777e90;
}
@media only screen and (max-width: 767px) {
  .bargaining__info {
    display: none;
  }
}
.bargaining__info a {
  margin-left: 8px;
  color: #23262f;
  transition: color 0.2s;
}
.bargaining__info a .icon {
  width: 20px;
  height: 20px;
  transition: fill 0.2s;
}
body.dark .bargaining__info a {
  color: #e6e8ec;
}
body.dark .bargaining__info a .icon {
  fill: #e6e8ec;
}
.bargaining__info a:hover {
  color: #3b71fe;
}
.bargaining__info a:hover .icon {
  fill: #3b71fe;
}
.bargaining .nav {
  margin-right: auto;
}
.bargaining .nav__link:not(:last-child) {
  margin-right: 16px;
}
@media only screen and (max-width: 1023px) {
  .bargaining__wrapper {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 6;
    background: #fcfcfd;
    padding: 16px;
    transition: all 0.3s;
  }
  body.dark .bargaining__wrapper {
    background: #141416;
  }
  .bargaining__wrapper.show {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    box-shadow: 0px -16px 64px rgba(15, 15, 15, 0.1);
  }
}
.bargaining__btns {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .bargaining__btns {
    display: flex;
    margin: 0 -6px;
  }
}
.bargaining__btns .bargaining__button {
  flex: 0 0 calc(50% - 12px);
  width: calc(50% - 12px);
  margin: 0 6px;
}
.bargaining__item {
  display: none;
}
.bargaining__top {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .bargaining__top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}
.bargaining__subtitle {
  margin-right: auto;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #777e90;
}
.bargaining__close .icon {
  width: 24px;
  height: 24px;
  fill: #777e90;
}
.bargaining__row {
  display: flex;
  margin: 0 -16px;
}
@media only screen and (max-width: 1023px) {
  .bargaining__row {
    display: block;
    margin: 0;
  }
}
.bargaining__col {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px;
}
@media only screen and (min-width: 1024px) {
  .bargaining__col {
    display: block !important;
  }
}
@media only screen and (max-width: 1023px) {
  .bargaining__col {
    display: none;
    width: 100%;
    margin: 0;
  }
}
.bargaining__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.bargaining__title {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.bargaining__counter {
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
}
.bargaining__counter .icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.bargaining__field {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 16px;
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px #e6e8ec;
  cursor: pointer;
}
body.dark .bargaining__field {
  box-shadow: inset 0 0 0 2px #23262f;
}
.bargaining__input {
  flex-grow: 1;
  height: 48px;
  padding: 0 10px;
  background: none;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #23262f;
}
body.dark .bargaining__input {
  color: #fcfcfd;
}
.bargaining__label,
.bargaining__currency {
  font-weight: 500;
  color: #777e90;
  pointer-events: none;
}
.bargaining__col > .bargaining__button {
  width: 100%;
}
.range {
  position: relative;
  height: 36px;
}
.range__slider {
  position: absolute;
  left: 0;
  top: 12px;
  right: 0;
}
.range__indicators {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}
.range__indicators span {
  width: 2px;
  height: 6px;
  border-radius: 2px;
  background: #e6e8ec;
}
body.dark .range__indicators span {
  background: #353945;
}
.noUi-horizontal {
  height: 2px;
}
.noUi-target {
  border: none;
  background: #e6e8ec;
  box-shadow: none;
}
body.dark .noUi-target {
  background: #353945;
}
.noUi-connect {
  background: #3b71fe;
}
.noUi-handle {
  border: none;
  box-shadow: none;
  background: #3b71fe;
  border-radius: 50%;
  cursor: pointer;
}
.noUi-handle:before,
.noUi-handle:after {
  display: none;
}
.noUi-horizontal .noUi-handle {
  right: -12px;
  top: -9px;
  width: 20px;
  height: 20px;
  border: 4px solid #777e90;
  background: #f4f5f6;
  box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
}
.noUi-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  padding: 3px 10px;
  background: #23262f;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #fcfcfd;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}
.noUi-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #141416 transparent transparent transparent;
}
body.dark .noUi-tooltip {
  background: #353945;
}
body.dark .noUi-tooltip:before {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #353945 transparent transparent transparent;
}
.noUi-handle:hover .noUi-tooltip {
  visibility: visible;
  opacity: 1;
}
.noUi-horizontal .noUi-tooltip {
  bottom: 24px;
}
.range_time .noUi-horizontal {
  height: 2px;
}
.range_time .noUi-handle {
  top: -11px;
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -12px;
}
.charts {
  position: relative;
  z-index: 2;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  border-radius: 4px;
}
@media only screen and (min-width: 1024px) {
  .charts {
    display: block !important;
  }
}
.charts__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  background: #fcfcfd;
  border-bottom: 1px solid #e6e8ec;
}
body.dark .charts__head {
  background: #17181b;
  border-color: #23262f;
}
.charts .nav {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .charts__group .nav {
    display: none;
  }
}
.charts .select {
  display: none;
  height: 28px;
  line-height: 28px;
  background: #e6e8ec;
}
body.dark .charts .select {
  background: #353945;
}
@media only screen and (max-width: 767px) {
  .charts .select {
    display: block;
  }
}
.charts__item {
  display: none;
}
.charts__inner {
  overflow: hidden;
}
.charts__inner > div {
  height: 492px !important;
  margin: -1px;
}
@media only screen and (max-width: 1023px) {
  .charts__inner > div {
    position: relative;
    z-index: -1;
  }
}
body.dark .charts__inner:first-child {
  display: none;
}
.charts__inner:nth-child(2) {
  display: none;
}
body.dark .charts__inner:nth-child(2) {
  display: block;
}
.charts__element {
  width: 100%;
  height: 490px;
  background: #fcfcfd;
}
body.dark .charts__element {
  background: #17181b;
}
body.dark .charts__element .highcharts-grid-line,
body.dark .charts__element .highcharts-tick,
body.dark .charts__element .highcharts-axis-line {
  stroke: #353945 !important;
}

.footer__socials {
  display: flex;
}

.footer__social:not(:last-child) {
  margin-right: 24px;
}

.footer__social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.footer__social .icon {
  width: 16px;
  height: 16px;
  fill: #777e90;
  transition: fill 0.2s;
}

.header__notification:not(:last-child) {
  border-bottom: 1px solid #e6e8ec;
}

.header__notification_new {
  padding-right: 24px;
}

.header__notification {
  position: relative;
  display: block;
  padding: 12px 0;
  color: #23262f;
  transition: color 0.2s;
}

.header__notification_new:after {
  content: "";
  position: absolute;
  top: 16px;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #58bd7d;
}

.header__activity {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 24px;
  margin-right: -20px;
  cursor: pointer;
}

.header__activity .icon {
  width: 24px;
  height: 24px;
  fill: white;
  transition: fill 0.2s;
  stroke: #777e90;
}

.header__activity:hover .icon,
.header__activity:focus .icon {
  stroke: #23262f;
}

.main__text {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.5;
}

.main__text.blg {
  font-weight: 600;
}

.main__text.colord {
  background: rgb(59, 113, 254);
  background: linear-gradient(90deg, rgba(59, 113, 254, 1) 14%, rgba(130, 209, 158, 1) 100%);
  color: white;
  max-width: 470px;
  padding: 9px 15px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 29px;
  font-weight: 600;
}

.main__button {
  margin-bottom: 80px;
}

.main__title.app {
  margin-bottom: 20px;
}
.user__list__item__checkbox {
  display: flex;
  align-items: center; /* This ensures your checkbox and email text are vertically aligned in the center, for that extra touch of finesse. */
  margin-bottom: 5px;
}
.user__list__item__email {
  margin-left: 10px;
}
