.ant-table {
  font-size: 14px;
  background: #fcfcfd;
}

.ant-table-tbody > tr > td {
  /* font-weight: 500; */
  color: #676e7e !important;
  font-size: 1em !important;
  vertical-align: middle;
  font-weight: 500;
  padding: 18px;
}

.dif__ablee .ant-table-tbody > tr > td {
  color: #676e7e !important;
  font-size: 12px !important;
  vertical-align: middle;
  font-weight: 500;
  padding: 10px;
  line-height: 14px;
  position: relative;
}
.dif__ablee.minii__tablee .ant-table-tbody > tr > td {
  color: #000000 !important;
  font-weight: 600;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  font-size: 12px !important;
  padding: 18px;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}
.dif__ablee .ant-table-thead > tr > th {
  font-weight: 600;
  font-size: 11px !important;
  padding: 10px 18px;
  background: #f9f9f9;
  line-height: 16px;
  border-top: 1px solid #f0f0f0;

  position: sticky;
  top: 0;
  z-index: 3;
}
/* .cmp__drwe .ant-table-thead > tr > th {
   min-width: 180px;
} */

.ant-dropdown-menu {
  padding: 10px 10px;
}

.ant-dropdown-menu-title-content {
  flex: auto;
  font-size: 12px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
}

.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 10px;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background: #0142eb;
  box-shadow: 0px 1px 15px rgb(46 125 229 / 40%);
  color: white;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: #0142eb;
}

.ant-radio-button-wrapper {
  margin-bottom: 15px;
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus,
.ant-btn-dangerous:active {
  background: #ff1c0c;
  box-shadow: none;
  color: white;
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  border-color: #ff1c0c;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  color: #adadad;
}

/* .ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #000000;
}

.ant-table-filter-trigger.active {
  color: #000000;
} */
