.footer__theme.desk {
    display: none;
}

.footer__col.mbc {
    display: block;
    text-align: right;
}

.column_info,
.mblle{
    display: none;
}

.dashbordi__nav.mm {
    display: flex;
}
/* @media only screen and (max-width: 1500px) and (max-height: 1120px) {
    .globe_world {
        right: -20%;
      }
} */






  
@media only screen and (max-width: 1852px) {
    .articles__area {
        display: none;
    }
}
  
@media only screen and (max-width: 1790px) {
    .card__kpp {
        max-width: initial;
        min-width: auto;
    }

    .plan__ihm h5 {
        min-height: 40px;
    }
}
  
@media only screen and (max-width: 1700px) {
    .block_m .ant-table-tbody {
        grid-template-columns: repeat(auto-fill, 30%);
    }
}

@media only screen and (max-width: 1672px) {
    .new__dashh {
        justify-content: space-around;
    }
    .onex_wl__main.ovrvw.new__ui {
        padding: 60px 0px;
    }
}
  
@media only screen and (max-width: 1600px) {
    .globe-wrapper.immg {
        display: none;
    }

    img.hero__welcome {
        display: none;
    }
    .new__dashh {
        gap: 30px;
    }
    .earth_markets_vector_web.css_vals_web {
        right: 35px;
        height: 120%;
        width: 200px;
        top: -38px;
    }
  
}
@media only screen and (max-width: 1498px) {
    .new__dashh,
    .new__dashh__left, .new__dashh__middle, .new__dashh__right {
        gap: 17px;
    }
    .card__kpp.wlcm {
        padding: 45px 15px;
        position: relative;
    }

    .earth_markets_vector_web.css_vals_web {
        right: 15px;
        height: 90%;
        width: 180px;
        top: auto;
        bottom: 0;
    }

    .more_markets_middle_web p {
        max-width: 60%;
    }
}
@media only screen and (max-width: 1465px) {

    .card__kpp_welcome ul {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 1446px) {
    .admin__card {
        width: -webkit-fill-available;
        max-width: fit-content;
    }
}
@media only screen and (max-width: 1414px) {
    .title__thk {
        font-size: 12px;
        line-height: 16px;
    }

    .desc__thk, a.desc__thk {
        font-size: 12px;
    }
}
@media only screen and (max-width: 1400px) {
    .onex_f__item {
        width: 33%;
        flex: 0 0 33%;
    }

    .section.onex_d.support .onex_d__video {
        top: 10%;
    }

    .block_m .ant-table-tbody {
        grid-template-columns: repeat(auto-fill, 32%);
    }
}

@media only screen and (max-width: 1384px) {
    .pricing_box {
        width:300px;
    }
    .sec_pricing .container {
        padding: 0 40px;
    }

    .fees__area {
        gap: 25px;
        flex-wrap: wrap;
    }

    .pr_title {
        flex-direction: column-reverse;
        align-items: start;
    }

    .pr_title h4 {
        white-space: normal;
        font-size: 12px;
        line-height: normal;
    }

    .tagg__iitm {
        line-height: 13px;
        font-size: 12px;
    }
}



@media only screen and (max-width: 1366px) {
    .new__dashh {
        display: block;
    }

    .new__dashh__middle {
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .new__dashh__right {
        flex-direction: row;
    }

    .new__dashh__right .card__kpp,
    .new__dashh__middle .card__kpp {
        width: 50%;
    }

    .card__kpp_welcome {
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    .itmm__wlcc h4 {
        margin: 0;
    }

    .contact__arnvb {
        flex-direction: column;
        align-items: start;
    }

    .onex_wl__main.ovrvw.new__ui {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }
}



@media only screen and (max-width: 1280px) {
    .pricing_box {
        width: 280px;
    }

    .sec_pricing .container {
        padding: 0 20px;
    }

    .onex_wl__main.ovrvw {
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .onex_wl__main.ovrvw .onex_wl__details {
        width: 50%;
        min-width: 50%;
    }
}
@media only screen and (max-width: 1200px) {
    .block_m .ant-table-tbody {
        grid-template-columns: repeat(auto-fill, 48%);
    }
}


@media only screen and (max-width: 1177px) {
    .admin__card {
        max-width: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 1170px) {
    .pricing_box {
        width: 100%;
    }

    .pricing_area {
        flex-direction: column;
    }

    .h1 {
        font-size: 44px;
    }

    .lottie__animm {
        width: 30%;
    }

    .lottie__animm .ovw_lott.user {
        max-width: 141px;
        margin-top: -290px;
        margin-left: 82px;
    }

    .removed__mode .question__center {
        padding-right: 0;
    }
}

@media only screen and (max-width: 1023px) {
    .outer__inner.auth {
        background-color: #ffffff;
    }
}

@media only screen and (max-width: 1000px) {
    

    .section.onex_d.support .onex_d__video {
        top: 20%;
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .cards_area_kpf {
        flex-direction: column;
    }

    .card__item_kpf {
        margin-bottom: 38px;
    }


}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 768px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .cards_area_kpf {
        flex-direction: column;
    }

    .card__item_kpf {
        margin-bottom: 38px;
    }

   
}

@media only screen and (max-width: 820px) {

    .removed__mode .form__gbb {
        display: block;
        padding-top: 21px !important;
    }

    .removed__mode .lottie__animm, .removed__mode p.remov__desc {
        display: block;
        margin: auto;
        width: 100%;
        margin-top: 0;
    }

    .removed__mode .question__center {
        width: 100%;
        margin: auto;
        padding: 0 20px;
        text-align: left;
    }

    .removed__mode p.remov__desc {
        width: 100%;
        margin-bottom: 30px;
    }

    .section_tags__clouds {
        margin-top: -7px;
        margin-bottom: 30px;
    }

    .version__mdl {
        z-index: 99999;
    }

    
.version__mdl_body {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.version__mdl_body.affl {
    height: auto;
}

    .horizontal__Scroll {
        width: 100%;
        overflow-x: scroll;
        max-width: fit-content;
        -ms-overflow-style: none;
scrollbar-width: none;
    }

    .horizontal__Scroll::-webkit-scrollbar {

        display:none;
        }



    .activity__top.ss>.activity__item+.right_items>.activity__form {
        margin-top: 15px;
    }

    .no__msgs_warn {
        height: calc(100vh - 220px);
      }

    .kpf_icn_token {
        width: 95px;
        height: 95px;
        margin: auto;
        margin-bottom: 10px;
        margin-top: 10px;
    }

   .radio-tile .radio-icon {
        text-align: center;
    }

    .ant-table-placeholder .ant-empty-normal {
        width: 86vw;
      }

    .onex_wl__main.ovrvw .onex_wl__details {
        width: 100%;
        min-width: 100%;
        position: relative;
    }

    .cards_area_kpf {
        margin-top: 25px;
        flex-direction: column;
    }

    .card__item_kpf {
        margin-bottom: 24px;
        margin-right: 0;
        min-width: 100%;
        width: 100%;
    }


    

    .admin__Cards {
        margin-top: 0px;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        max-width: 100%;
        justify-content: start;
        align-items: stretch;
        flex-direction: column;
    }

    .total__inf {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .admin__card img {
        display: none;
    }

    .admin__card {
        max-width: 100%;
        width: 100%;
        margin: 0px;
    margin-bottom: 15px;
        display: block;
    }

    .onex_wl__wrapper.pos_relative .globe_world {
       display: none;
    }

    .payment_detials {
        flex-wrap: wrap;
        padding: 20px;
        padding-bottom: 10px;
        margin-bottom: 30px;
    }

    .pay__footer .pay_mesg {
        max-width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 5px;
        margin-top: 8px;
        height: 33px;
        overflow-y: hidden;
    }

    .setting__fieldset.ghh {
        padding: 20px;
        padding-bottom: 0px;
    }

    .top__status_payment {
        margin-bottom: 10px;
        padding: 15px;
      }

      .top_step__itm span {
        font-size: 12px;
    }
    .top_step__itm svg {
        width: 18px;
        height: 18px;
  }

  .btn_Sss {
    gap: 5%;
}

  

    .payment_detials .inf_item {
        margin-bottom: 15px;
        width: 50%;
    }

    .payment_detials .inf_item.total__p {
        width: 100%;
    }

    .payment_detials .inf_item ul li span {
        display: flex;
        justify-content: start;
        align-items: center;
    }


    .flex_inps {
        flex-direction: column;
        margin-bottom: 0;
      }
      
      .flex_inps .field {
        width: 100%;
        margin-bottom: 32px;
      }

      .registration .flex_inps .field:not(:last-child) {
        margin-bottom: 32px !important;
      }

    /* .outer__inner.xxc .onex_wl {
        padding: 32px 16px 16px;
    } */

    .globe_world {
        z-index: 0;
        top: 30%;
    }

    .account__head.cvvn {
        position: absolute;
        height: 100%;
        z-index: 2;
        width: 100%;
        display: block;
    }


    .account__details.ovw {
        margin-top: 65px;
    }


    .onex_wl__wrapper {
        height: auto;
        padding-left: 0px;
        padding-right: 24px;
      }

    .section.onex_d.support .onex_d__video {
        top: -20%;
    }

    .bottom__img {
        display: none;
    }

    .StripeElement {
        margin-top: 0px;
    }


    .onex_f__item {
        padding-top: 25px;
        padding-left: 30px;
        width: 100%;
        flex: 0 0 100%;
        margin: 0px;
        margin-bottom: 20px;
    }

    .center {
        padding: 0 25px;
    }
    .footer__theme.desk {
        display: block;
        padding-left: 25px;
    }
    
    .footer__col.mbc {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 40px;
        margin-bottom: -25px;
        gap: 20px;
    }
    span.vrfy__link {
        margin-top: 0;
    }
    a.vrfy__link img, span.vrfy__link img {
        max-width: 150px;
        height: auto;
        margin-left: -3px;
    }

    .section.main.main_kpcker{
        position: relative;
        display: block;
        max-height: 100%;
        height: 100%;
        margin-top: -90px;
    }

    .main__preview img.earth {
        top: 120px;
        left: 0;
        right: 0;
        width: 100%;
        height: 500px;
        object-fit: contain;
        z-index: -1;
    }

    .hero {
        font-size: 22px;
    }

    .main_kpcker .main__info {
        font-size: 12px;
    }

    .main__info.bb {
        font-size: 12px;
        margin-bottom: 10px;
        width: 100%;
    }

    .main__info.mb_60 {
        margin-bottom: 20px !important;
    }

    [class^="button"] {
        height: 42px;
        padding: 0 18px;
        border-radius: 4px;
        font-size: 14px;
    }
    a.header__button {
        padding: 0 12px;
        font-size: 13px;
    }

    .iconic {
        height: 42px;
        padding: 0 18px;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 8px;
    }

    .main__button {
        margin-bottom: 30px;
    }

    .globe_all {
        top: 345px;
        left: 0;
        right: 0;
        width: 95%;
        border-radius: 2px;
        padding: 20px;
        padding-bottom: 22px;
        z-index: 2;
        font-size: 14px;
        margin: auto;
    }

    .TR .globe_all,
    .DE .globe_all {
        padding-top: 18px;
    }

    .globe_all span {
        margin-top: -5px;
        font-size: 0.8rem;
    line-height: 17px;
    }


    .amazon_b {
        width: 20px !important;
        height: 30px !important;
        margin-right: -1px !important;
    }

    .p_card.bb {
        top: 663px;
        left: 50%;
    }

    .DE .globe_all span.amazon_b,
    .TR .globe_all span.amazon_b {
        height: 0 !important;
    }

    .DE .globe_all span,
    .TR .globe_all span {
        margin-top: 6px;
    }

    .p_card {
        top: 590px;
        left: 5%;
        width: 150px;
        height: 50px;
        border-radius: 2px;
        z-index: 1;
        background: white;
    }

    .p_card .left .p_img {
        height: 30px;
        width: 30px;
        left: 10px;
        border-radius: 4px;
        padding: 5px;
        margin-right: 20px;
    }

    .p_card .left .p_desc.aa {
        width: 60px;
        height: 0.55rem;
        margin-bottom: 0.45rem;
    }

    .p_card .left .p_desc.bb {
        width: 38px;
        height: 0.55rem;
    }

    .p_card .percentage_b {
        height: 26px;
        width: 26px;
        border-radius: 100%;
        margin-right: 5px;
    }

    .map_dots {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        width: 100%;
        height: 500px;
        border-radius: 24px;
        object-fit: contain;
        z-index: -1;
    }

    .dot {
        margin-top: 366px;
        margin-left: 69px;
    }

    .dot.bb {
        margin-top: -65px;
        margin-left: 180px;
    }

    .h2 {
        font-size: 22px;
        line-height: 1;
    }

    .info {
        font-size: 14px;
    }

    .section.onex_w {
        margin-top: -100px;
    }

    .section.onex_w.hero__img {
        margin-top: -164px;
    margin-bottom: 193px;
    padding-top: 43px;
    }

    .about .main__text.colord {
        font-size: 11px;
    }

    .about .h1  {
        font-size: 16px;
    }

    .about .main__title.app {
        margin-bottom: 6px;
    }

    p.about_lined_txt {
        padding-top: 48px;
        margin-bottom: 46px;
        font-size: 11px;
    }

    .about .section.onex_w.hero__img {
        margin-top: -30px;
        margin-bottom: 10px;
    }

    .promo_cn .center .kpc_dd__head {
        margin: auto;
        margin-top: 35px;
    }

    .p_badge img {
        margin-top: 2px;
        margin-bottom: 10px;
    }

    .p_badge.tt img {
        margin-top: -8px;
    }

    .setting__notification.prc .switch__box:before {
        transform: translateY(-50%);
    }

    .setting__notification.prc .switch__input:checked+.switch__inner .switch__box:before {
        transform: translate(21px,-50%);
    }

    .onex_d__badge.mobile {
        display: flex;
      }

      .onex_d__badge.desk {
        display: none;
      }

    .promo_sc .view__stage  {
        font-size: 12px;
        line-height: 14px;
        max-width: 80%;
        margin-bottom: 11px;
    }

    .services .prc .services__title {
        font-size: 18px;
    }

    .services .prc .stage {
        width: 100%;
    }

    .footer__theme p {
        font-weight: 400 !important;
        font-size: 12px;
        margin-top: 5px;
    }


    .header {
        padding: 10px 0 10px;
    }
    .header.no__banner {
        padding: 10px 0 10px;
    }

    .header .center {
        padding: 0 10px;
    }

    .header__logo span {
        display: none;
    }

    .header__logo img {
        width: 40px;
    }

    .footer__bottom {
        display: block;
        text-align: center;
    }

    .scroll-to-top {
        right: 15px !important;
        bottom: 15px !important;
        width: 35px !important;
        height: 35px !important;
        opacity: .8 !important;
    }

    .footer__socials {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .footer__logo {
        margin-right: auto;
        margin-left: 0;
    }

    .footer__col:first-child {
        margin-bottom: 30px;
    }

    .footer__col:nth-child(2) {
        margin-bottom: 0px;
        padding: 0;
    }


    .onex_pl__gallery.xx {
        position: relative;
        top: -20px;
        right: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        align-items: flex-start;
        min-height: 380px;
    }

    .details_slider span {
        font-size: 12px;
    }

    .details_slider p {
        font-size: 12px;
        line-height: 14px;
    }

    .bar_b {
        width: 7rem;
        height: 0.75rem;
    }

    .wrapper_slider .card_slider {
        min-height: 100px;
        padding: 15px;
        border-radius: 4px;
    }

    .wrapper_slider .card_slider .img {
        height: 45px;
        width: 45px;
        left: 0px;
        border-radius: 4px;
        padding: 20px;
    }

    .card_slider .details_slider {
        margin-left: 15px;
    }

    .card_slider .percentage_b {
        font-size: 14px;
        height: 50px;
        width: 50px;
    }

    .card_slider .title {
        font-size: 11px;
        margin-top: 6px;
        line-height: 12px;
    }

    .promo_sc {
        border-radius: 8px;
        padding: 15px;
        margin: auto;
        min-height: auto;
        max-width: 95%;
    }

    .promo_sc .view__head {
        padding: 24px;
        border-radius: 8px;
        margin: auto;
        width: 100%;
        min-height: auto;
    }

    .question__center.center.fixed {
        height: 40px;
    }

    .question__center.center.fixed .question__form {
        position: fixed;
        top: 59px;
        z-index: 22;
        width: 100%;
        background-color: #ffffff;
        left: 0;
        right: 0;
        height: 75px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #e6e8ec;
    }

    .sec_pricing .question__center.center.fixed .question__inner {
        padding: 10px 5px;
        height: 36px;
        border-radius: 3px;
    }

    .slick-slider {
        display: block;
      }

    .desk_img {
        display: none;
    }

    .sec_pricing .question__center.center.fixed .question__inner .question__text_small {
        display: none;
    }

    .promo_sc .view__button {
        min-height: 45px;
        font-size: 14px;
        margin-top: 20px;
    }

    ul.checked_items li {
        font-size: 14px;
    }
    .onex_pl__info {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .onex_w__subtitle {
        margin-bottom: 2px;
        font-size: 22px;
    }

    .main__bg.app {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        max-width: 1000px;
        pointer-events: none;
        height: auto;
    }

    .h1 {
        font-size: 30px;
    }

    .main__text {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .main__text.gghj {
        margin-bottom: 40px;
    }

    .section-bg.app.section-mb0.releases {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .section.app.about {
        margin-bottom: 0;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .onex_a__slider {
        padding-bottom: 30px;
        flex-direction: column;
    }

    .onex_a__item {
        margin-bottom: 20px;
    }

    .promo_b .onex_p__inner {
        align-items: flex-start;
        flex-direction: column;
    }

    .onex_p__wrapper {
        margin-top: 25px;
    }

    .promo_b .onex_p__inner:before {
        left: 5px;
        right: 5px;
        bottom: -11px;
    }

    .onex_d {
        padding-top: 0px;
    }

    .h4 {
        font-size: 20px;
        line-height: 1;
    }

    .content h3 {
        font-size: 25px;
        line-height: 1.25;
    }

    /* .releases__item:not(:last-child) {
        margin-bottom: 10px;
    } */

    .player img {
        width: 100%;
        height: auto;
        border-radius: 6px;
        object-fit: contain;
        position: relative;
    }

    .player:before {
        display: none;
    }

    .breadcrumbs__item:not(:last-child) {
        display: block;
    }

    .article {
        padding-top: 10px;
    }

    .section.onex_d.support {
        min-height: auto;
    }

    .faq1 .nav {
        display: flex;
    }

    .section.app.question {
        padding: 10px 0;
    }

    .question__btns {
        margin-bottom: 55px;
    }

    .question__text {
        font-size: 14px;
    }

    .content p,
    p.lnd__p {
        font-size: 14px;
    }

    .player.tmt {
        margin-top: 25px;
    }

    .pricing_area {
        flex-direction: column;
        margin-top: -40px;
    }

    .pricing_box {
        padding: 25px 15px 20px 15px;
        border-radius: 8px;
        width: 100%;
        min-height: auto;
        height:auto;
        margin-bottom: 35px;
    }

   

    .p_button {
        padding-top: 0px;
    }
    .details .p_button {
        padding-top: 0px;
    }

    .sec_pricing {
        margin-top: 0px;
    }


    .sec_pricing .question__fieldset {
        margin-bottom: -105px;
    }

    .login__logo {
        z-index: 2;
    }

    .onex_a__preview {
        max-height: 112px;
    }

    .onex_p__center.center {
        padding: 0 5px;
    }

    .onex_p__inner {
        border-radius: 4px;
        padding: 30px 24px;
    }

   .sec_pricing .question__inner {
        padding: 30px 8px;
    }

    .price {
        border-radius: 8px;
    }

    .checkout .control {
        margin-bottom: 15px;
    }

    .onex_wl__wrapper {
        overflow-x: hidden;
        padding-right: 0;
    }

    .ant-table-content {
        width: calc(100vw - 30px);
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding-bottom: 15px;
    }
    .cmp__drwe .ant-table-content {
        overflow-x: unset;
        overflow-y: unset;
    }

    .other__insf__area .ant-table-content {
        width: 100%;
    }

    .outer__inner.xxc .onex_wl.client {
        background-color: white;
    }

    .ovw_lott {
        width: 100%;
        height: auto;
        margin-bottom: 0;
        margin-top: 25px;
        margin-left: -12px;
    }

    .onex_wl__main.ovrvw {
        background: white;
        width: 100%;
        height: 100%;
        padding: 20px 10px;
        border-radius: 4px;
        margin-top: -50px;
        flex-direction: column;
        justify-content: start;
        align-items: stretch;
        min-height: 900px;
    }

    .onex_wl__main.ovrvwnew__ui {
        padding: 15px;
        padding-top: 48px;
    }
    .card__item_kpf h2.tt {
        
        margin-top: 10px;
    }

    .card__item_kpf h2.tt.aa {
        margin-top: 50px;
    }

    .ant-table .ant-table-container::after {
       display: none;
    }

    .onex_wl__sidebar {
        top: 0;
        padding-top: 90px;
    }

    .onex_wl__btns {
        margin-bottom: 15px;
      }

      .onex_wl__btns .onex_wl__button {
        margin-top: 15px;
      }

      .outer__inner.xxc {
        margin-top: 60px;
    }

    .outer__inner.landng {
      margin-top: 90px;
  }
  .outer__inner.landng.gg {
    margin-top: 90px;
}

  .outer__inner.landng.blog__page {
    margin-top: 50px;
}

    .overview__list {
        margin: 0;
    }


    .overview__item.info {
        flex-basis: calc(50% - 20px);
        width: calc(50% - 20px);
        margin: auto;
    }

    .overview__item.ticket {
        flex-basis:calc(100% - 20px);
        width:calc(100% - 20px);
    }


    .overview__item.ticket .overview__button  {
        display: none;
    }

    .overview__item.info svg,
    .overview__item.ticket svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .overview__head {
        padding: 10px 5px;
    }

    .client .overview__head {
        padding: 16px 25px;
    }

    .info .integrations__content {
        font-size: 12px;
        line-height: 15px;
    }
    .main_area {
        display: block;
    }

    .main_area > .clients_area {
        margin: 0px;
    }

    .clients_area .top {
        flex-direction: column;
    }
    .clients_area .top .clients_info {
        width: 100%;
        margin-right: 0px;
    }

    .clients_area .top .clients_chart {
        width: 100%;
    }
    .clients_area .bottom {
        height: 20px;
        padding: 25px;
        padding-bottom: 15px;
        padding-right: 5px;
    }

    .clients_area .top .clients_chart .apex_chart {
        margin-left: -30px;
        padding-right: 5px;
    }

    .clients_area .top .clients_info {
        width: 100%;
        border-right: 1px solid #f1f2f4;
        margin-right: 0px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
    }

    .clients_area .top .clients_info .itms {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .clients_area .top .clients_info .itms {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .clients_area .top .clients_info .itms::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .clients_area .top .clients_info .itms .itm {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0px !important;
        margin-right: 20px;
    }

    .clients_info .subtitle.mt_40 {
        margin-top: 0px !important;
    }

    .onex_wl__sidebar {
        display: flex;
        left: -300px;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
    }

    .onex_wl__sidebar.actived {
        left: 0px;
    }

    .admin .onex_wl__wrapper,
        .client .onex_wl__wrapper,
        .onex_wl__wrapper  {
        padding-top: 0;
    }

    .client .onex_wl__wrapper.ovww {
        padding-bottom: 40px;
    }

    .page-_loader.opt {
        opacity: 1 !important;
        z-index: 999;
      }

      .login__col {
        margin-top: 0;
    }

    .tickets_info.info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        width: 100%;
    }

    .keys__info.w_cl span {
        display: none;
    }

    .keys__info.w_cl .page_details .item_c:first-child,
    .keys__info.w_cl .page_details .item_c:first-of-type{
        margin-left: 0px;
    }

    .keys__info {
        margin-bottom: 32px;
        font-size: 14px;
    }




    .card_m thead {
        display: none;
        border: none !important;
    }

    .card_m tr {
        display: flex;
        flex-wrap: wrap;
        width: calc(100vw - 35px);
        margin-bottom: 36px;
        border: 1px solid #e6e8ec;
        border-radius: 4px;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-top: 55px;
    }

    .card_m td {
        flex-basis: calc(50% - 4px);
        width: calc(50% - 4px);
        border: none !important;
    }

    .card_m td.table_actin button {
        margin-left: auto;
        margin-right: 0;
    }

    .ant-table-tbody > tr > td {
        border: none !important;
        position: initial;
        white-space: pre-line;
    }

    .column_info {
        display: block;
        font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    color: rgb(23, 23, 23);
    }
    .dif__ablee  .column_info {
        display: none;
    }
    .dif__ablee.block_m  .column_info {
        display: block;
    }

    .cmp_drw_title h4 {
        font-size: 13px;
    }

    .card_m .ant-tag, .card_m .ant-tag.paid, .card_m .table__tag {
        border-radius: 3px;
        margin-top: 8px;
    }

    td.table_actin   {
        position: absolute !important;
        top: 0px;
        right: 0px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px !important;
        padding-top: 10px !important;
    }
    .cmp__drwe td.table_actin   {
        display: table-cell;
    }
    .cmp__drwe .block_m td.table_actin   {
        display: flex;
    }

    .ant-table-tbody > tr > td.table_actin {
        border-bottom: 1px solid #e6e8ec !important;
    }

    td.table_actin button  {
        margin-left: auto;
    }

    td.table_actin button:last-child,
    td.table_actin button:last-of-type  {
        margin-left: 10px;
    }

    td.table_actin .column_info  {
        margin-bottom: 0;
    }

    .outer__inner {
        margin-top: 60px;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .ant-drawer-body {
        padding: 8px;
    }

    .messages__inner {
        padding: 5px;
    }

    .messages__text {
        padding: 15px 10px;
        font-size: 13px;
    line-height: 18px;
    }

    .messages__time {
        margin-top: 6px;
        font-size: 10px;
    }
    .subscription {
        min-height: 115px;
        position: fixed;
        bottom: 0;
        padding-bottom: 15px;
        width: 100%;
        left: 0;
        right: 0;
        padding-left: 10px;
        padding-right: 10px;
    }

    .subscription__input {
        margin-bottom: -14px;
        min-height: 82px;
        padding-right: 57px;
    }

    .messages__wrapper {
        height: calc(100vh - 70px);
        padding-bottom: 124px;
    }

    .ant-drawer-title {
        font-size: 14px;
        font-weight: 700;
    }

    .ant-drawer-header {
        padding: 16px 8px;
    }

    .bidding__step:after {
      display: none !important;
    }

    .upload__preview.js-upload-preview.ss{
        position: fixed;
        top: 60px;
        width: 100%;
        height: 60px;
        padding: 0;
        right: 0;
        left: 0;
        opacity: 1;
        visibility: visible;
        background: white;
        border-left: none;
        border-bottom: 1px solid #e6e8ec;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bidding__steps {
        width: 100%;
    display: flex;
    }

    .bidding__number{
        font-size: 11px;
        margin-right: 5px;
        width: 30px !important;
        height: 30px !important;
        min-width: 30px;
        min-height: 30px;
        line-height: 0;
    }

    .bidding__step:not(:last-child),
    .bidding__step {
        margin-bottom: 0;
    }

    .bidding__step {
        font-size: 12px;
    }

    .comparee .onex_wl__wrapper {
        margin-top: 60px;
    }

    .receipt__btns {
        flex-wrap: wrap;
    }

    .receipt__btns .s_check:first-of-type, 
    .receipt__btns .s_check:first-child,
    .receipt__btns .s_check {
        margin-left: 0px;
    }

    .receipt__btns .s_check {
        margin-right: 15px;
        margin-bottom: 15px;
    }

    .iconic_nav {
        display: none;
    }

    .ant-tooltip-placement-right .ant-tooltip-arrow {
        display: none;
    }

    .ml_c {
        margin-left: 15px;
      }

      .header__item_language .header__body {
        left: auto;
        top: 10px;
        right: 10px;
    }

  

    .onex_wl__sidebar {
        /* z-index: 9999; */
        z-index: 999;
    }

    .kepicker_header {
        z-index: 99999;
    }

    .footer__mobile {
        display: flex;
      }

      .onex_wl__link {
        width: 100%;
      }

      .openable_link .arrw {
        margin-left: auto;
    }

    .ant-drawer {
        z-index: 999999;
    }

    .ant-drawer.payment__-drw.tkns {
        z-index: 999998;
      }
      
      .ant-drawer.clnts {
        z-index: 999997;
      }

    .sidebar_bgg {
        display: block;
      }

      .activity__top .nav {
        margin-bottom: 20px;
        width: calc(100vw - 35px);
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 15px 10px;
        margin-top: -30px;
    }

    .activity__top .nav::-webkit-scrollbar {
        width: 5px !important;
        height: 5px !important;
        background: #fff !important;
        border-radius: 5px !important;
        }
        
        .activity__top .nav::-webkit-scrollbar-button {
        display: none !important;
        }
        
        .activity__top .nav::-webkit-scrollbar-thumb {
        background: #cdcdcd !important;
        border-radius: 5px !important;
        }

    .activity__top .nav button {
      font-size: 12px;
  }

  .activity__top .right_items,
  .activity__top.ss .right_items {
    display: contents;
}

.activity__top.ss .right_items .activity__item {
    margin-left: 0px;
}

.payment__-drw .ant-drawer-content-wrapper .ant-drawer-body {
    padding-bottom: 40px;
}

.payment_detials .inf_item h3 {
    width: fit-content;
}

.chk_comp {
    padding-top: 0px;
}

.radio-group .field {
    max-width: 100%;
}

.kp_icn_token_landing {
    display: none;
}

.radio-group {
    margin-top: 25px;
}

.tt_radios {
    justify-content: space-around;
    margin: 0;
    gap: 20px;
}

.tt_radios>.radio {
    width: calc(100% - 73px);
}



.radio-tile {
    width: 140px;
    min-height: 140px;
    margin-bottom: 20px;
}

.payment__-drw.tkns .radio-tile {
    width: auto;
    min-width: -webkit-fill-available;
    padding: 15px 15px;
}

.buy_tokens_detials  {
    padding-bottom: 65px;
}
.buy_tokens_detials.gghh  {
    padding-bottom: 25px;
}

.notifications {
    background: #ffffff;
}

.notifications__subtitle {
    max-width: 80%;
}

.notifications__item,
.notifications__details {
    position: relative;
    width: 100%;
}
.notifications__status  {
    position: absolute;
    right: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.main_headr .m__right {
    flex-direction: column;
    align-items: end;
}

.divider__btns {
    width: 2px;
    height: 5px;
    background: transparent;
    margin-left: 25px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.main__cards_ar {
    flex-wrap: wrap;
}

.main_d_card {
    width: 50%;
    margin-bottom: 15px;
}

.tab__btns {
    max-width: 100%;
}

.not__webgl  {
    margin-top: 50%;
}

.not__webgl img {
    width: 100%;
    height: auto;
}

.globe_world.not_wb {
    right: -38% !important;
  }

  h2.swal2-title {
    font-size: 20px !important;
}

.add_price_pavkages {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1150px !important;
    margin-top: 15px;
    flex-direction: column;
    margin-bottom: 55px;
}

.attention_pls.add_price_pavkages {
    margin: 0;
}

.add_price_pavkages .pricing_box {
    width: 100%;
}

.add_price_pavkages .services__center {
    display: none;
}
.attention_pls.add_price_pavkages .p_badge img {
    width: 39px;
    height: auto;
    margin-top: -4px;
    margin-left: -2px;
    margin-bottom: 5px;
    opacity: .3;
}

.undrstnd_chk .radio-pill-inner span {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    display: block;
}

.attention_pls.add_price_pavkages .pricing_box {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: 2px;
}

.attention_pls.add_price_pavkages .view__button {
    margin-top: 8px;
}

.admin .onex_wl__main h4 {
    margin-bottom: 25px;
}

.admin .onex_wl__main {
    padding: 0;
}

.features__area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 50px;
    gap: 50px;
}

.features__itm {
    margin-bottom: 5px;
    width: 100%;
}

.coming__fetaures {
    margin-bottom: -104px;
}


.section.onex_a.abt__us .center {
    flex-direction: column;
}

.section.onex_a.abt__us .onex_a__head {
    width: 100%;
    text-align: left;
}

.section.onex_a.abt__us {
    background: white;
    padding-top: 41px;
    padding-bottom: 80px;
}

.section.onex_a.abt__us .img__abt {
    width: 100%;
    margin-top: 0;
}

.section.onex_a.abt__us .img__abt img {
    height: auto;
    width: 100%;
}

.icn__gg {
    position: relative;
    width: 30px;
    height: 30px;
    margin-bottom: 32px;
    display: block;
    margin-top: 10px;
}

.onex_wl__sidebar.admn {
    overflow: auto;
    padding-bottom: 100px;
    /* height: calc(100vh - -25px); */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.onex_wl__sidebar.admn::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.plan_sc .plan_top {
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.swal2-container.msg___cnt .swal2-actions {
    margin-top: 10px;
   }

.swal2-container.msg___cnt .swal2-actions button {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 16px !important;
}



td.long__inf  {
    flex: 100%;
}

.notif__Y.TR .notifications__top .notifications__button:not(:last-child),
.notif__Y.DE .notifications__top .notifications__button:not(:last-child) {
    margin-bottom: 15px;
}

.big___btnss button.ml-35 {
    margin-left: 0 !important;
    margin-top: 15px;
}

.flx_cc .token_bt.up_btn {
    margin-top: -13px;
    margin-bottom: 20px;
}

.hide__mm {
    opacity: 0;
    visibility: hidden;
}

.right_items>.onex_ts__select+.onex_ts__select {
    margin-left: 0px;
  }

  .saved_setting_itm {
    max-width: 100%;
}

.setting__category.cvvf {
    justify-content: space-between;
    gap: 0px;
}

.mang_setting_area {
    margin-bottom: 40px;
  }

  .radio__boxt.rrt {
    flex-wrap: wrap;
}

.radio__boxt .s_check.rd input.field__input {
    max-width: 60px;
    padding: 0 4px;
}

.checkbox__text {
    font-size: 12px;
}

.mang_setting_area .s_check .checkbox__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
}

/* span.inff__text {
    max-width: 50%;
  } */

  small.inf__wrngg,
  small.inf__wrngg.ggb {
    max-width: 100%;
  }


  .extesinon__area .onex_pl__wrap {
    max-width: 100%;
}

.onex_pl.cmp_anim.extesinon__area {
    padding-top: 20px;
    margin-bottom: 60px;
}



.tabs___ext ul {
    padding-bottom: 11px;
}

.tabs___ext ul li button {
    font-size: 70%;
}

.onex_pl__wrap .tabs___ext ul li button {
    font-size: 80%;
}

.home_m.DE .tabs___ext ul li button {
    font-size: 64%;
}

.onex_pl__wrap .tabs___ext ul {
    margin-bottom: 20px;
    padding-bottom: 19px;
}

.show_ui_Area ul {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-top: 30px;
}

.show_ui_Area ul li button {
    font-size: 11px;
    line-height: 12px;
}

.show_ui_Area ul li button:hover {
    text-decoration: none;
}

.extesinon__area .onex_pl__wrap {
    margin-bottom: 15px;
}

.pbi__Area .pbi_tabs {
    width: 100%;
    margin-bottom: 30px;
    height: auto;
    text-align: left;
    margin-top: 15px;
    padding: 5px;
}

.tabs___ext ul {
    padding-bottom: 11px;
}

.extesinon__area>.onex_pl__center>.onex_pl__wrap>.stage {
    width: 80%;
}

.hd__mbl {
    display: none;
}

.sh__mbl {
    display: block;
    font-size: 12px;
    margin-top: -20px;
    line-height: 18px;
}

.tabs___ext ul li button.active::before, 
.tabs___ext ul li button:hover::before {
    top: 41px;
}

.show_ui_Area ul li button span {
    width: unset;
}

.close_ui_btn {
    color: black;
    background: white;
    border-radius: 50%;
    width: 33px;
    height: 33px;
}
.close_ui_btn svg {
    width: 24px;
    height: 24px;
    stroke-width: 2px;
    margin: auto;
}

.amazon__extensn {
    margin: auto;
}

.ant-pagination-options {
    display: inline-block !important;
}

.ant-pagination-prev, .ant-pagination-next {
    margin-right: 16px !important;
}

.ant-pagination-options {
    margin-left: 0px !important;
}

.nxt_pdue.tkn {
    max-width: 70%;
    margin-bottom: 15px;
}

.article__wrayt .footer-push {
    box-shadow: none;
    border: none !important;
    margin-bottom: 25px;
}

.article__wrayt .footer-push .footer-push-content .item:first-of-type {
    border: none !important;
}

.article__wrayt .footer-push .item {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.article__wrayt .footer-push .item .icon {
    margin: auto;
    margin-bottom: 5px;
    display: block;
  }

  .how_videos_Area .video__itm {
    width: 100%;
}

.left__ar {
    display: none;
}

.subb_tt  .left__ar {
    display: block;
}

.welcome__mdl_area .welcome__mdl {
    max-width: 90%;
}



.subb_tt .ovw_lotenr {
    max-width: 108px;
    margin: auto;
    margin-top: -30px;
    margin-bottom: 25px;
}

.welcome__mdl_area.subb_tt .welcome__mdl {
    display: flex;
    margin: 0;
    margin-top: 50px;
    padding: 30px 30px;
    padding-bottom: 50px;
    flex-direction: column;
}

.subb_tt .welcome__mdl .close_ui_btn {
    z-index: 999;
}

.articles__area {
    display: none;
}

.warn__mesg {
    width: 100%;
}

.new__dashh {
    flex-direction: column;
}

.onex_wl__main.ovrvw.new__ui {
    padding: 45px 15px;
  }

  .card__kpp_body.cc .plan__ihm img, .card__kpp_body.vv .plan__ihm img {
    width: 29px;
    height: auto;
    margin-bottom: 8px;
}

.new__dashh__left, .new__dashh__middle, .new__dashh__right {
    width: 100%;
}









.new__dashh {
    display: flex;
}

.new__dashh__middle {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.new__dashh__right {
    flex-direction: column;
}

.new__dashh__right .card__kpp,
.new__dashh__middle .card__kpp {
    width: 100%;
}

.card__kpp_welcome {
    display: block;
}

.itmm__wlcc h4 {
    margin-top: 35px;
    margin-bottom: 8px;
}

.contact__arnvb {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
}

.onex_wl__main.ovrvw.new__ui {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

.setting__controls.bbn {
    margin-top: 0px;
    border-top: none;
    padding-top: 30px;
}

.tp-price-offer-badge-wrap {
    display: none;
}

button.cnc_nxtt {
    flex: none;
    text-align: left;
    white-space: nowrap;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
    color: #23262F;
  }

  .warn__pln {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 15px;
    margin-bottom: 25px;
}

.user__gide.show {
    display: block !important;
    z-index: 99999;
    overflow: auto;
    padding-top: 0px;
}

.user__gide h3 {
    margin-top: -20px;
}

.user__gide .card__kpp_welcome {
    flex-direction: column;
    gap: 5px;
}

.user__gide .contact__arnvb {
    flex-direction: row;
    align-items: start;
    gap: 30px;
}

.admin__card img + div {
    margin-left: 0px;
}

.ststs .field__input {
    width: 100%;
}

.btns__areat  {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;
}

.mb__warn {
    display: flex;
  }

  .admin__switch {
    flex-wrap: wrap;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}

.admin__switch .nav {
    border-right: none;
    padding-right: 0px;
    margin-bottom: 10px;
}

.merged___area {
    position: relative;
    width: 100%;
    top: 16px;
    max-width: 100%;
    gap: 9px;
    margin-bottom: 25px;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: transparent;
}

.new__page .activity__top.ss .right_items .activity__item button+button {
    margin-left: 0;
    /* margin-top: 15px; */
}
.block_m .ant-table-tbody {
    display: block;
    gap: 0;
}
.block_m tr {
    width: calc(100% - 3px);
    justify-content: space-between;
    align-items: stretch;
}

.block_m.dif__ablee .ant-table-content {
    width:100%;
}
.merged___area>span {
    display: none;
}
.dif__ablee td.table_actin {
    position: relative !important;
}
.dif__ablee.block_m td.table_actin {
    position: absolute !important;
}
.cmp__drwe .dif__ablee td.table_actin {
    position: sticky !important;
    top: auto;
}
.cmp__drwe .dif__ablee.block_m td.table_actin {
    top: 0;
}
.cmp__drwe .dif__ablee.block_m td.table_actin {
    position: absolute !important;
}

.new__page .activity__top.ss .right_items .activity__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}


.empty__tble {
    margin: auto;
    width: calc(100vw - 72px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block_m .empty__tble img {
    margin-top: -65px;
}
.block_m tr.ant-table-placeholder {
    width: 100%;
    display: block;
}
.card__kpp_body.gg.sdbar,
.button_upg {
    display: none !important;
}
.subscription__btn {
    top: 40px;
}

.blg__ifs {
    margin-top: 7px;
    margin-bottom: -2px;
}

button.view_det {
    display: flex;
  }

  .p_list {
    display: none;
  }

  .details .p_list {
      display: block;
    }

    .market__activities {
        flex-direction: column;
        justify-content: start;
    align-items: start;
    }

    .market__activities span.mrk__inf {
        border-left: none;
        padding-left: 0px;
    }

    .market__activities span.mrk__inf.tt {
        margin-left: 10px;
    }

    .mrk__header {
        flex-direction: column;
        align-items: start;
        margin-bottom: 35px;
    }

    .mrk__header .upload__head {
        margin-bottom: 0;
    }

    .iconic__ttle {
        font-size: 16px;
    }

    /* .ant-transfer {
        width: 100%;
        overflow: auto;
    } */
    .ant-transfer-list {
        min-width: 180px;
    }

    .how__it_wrks {
        flex-direction: column;
    }

    .transfer__body {
        width: 100%;
        overflow: auto;
        flex-wrap: nowrap;
    }

    .transfer__body .left__arre {
        min-width: 500px;
    }

    .transfer__body .right__arre {
        min-width: 300px;
    }
    .sheets_nav button {
        text-align: left;
    }

    .all_Cards {
        flex-direction: column;
        align-items: start;
        gap: 50px;
    }


    .new__page .upload__head.hhg {
        flex-direction: column;
        align-items: start;
    }

    .new__page .upload__head.hhg .comparsion__hdr {
        padding: 0;
        margin-top: 15px;
        border: none;
    }
    .upload__head.fvvbg {
        flex-direction: column;
        align-items: start;
        gap: 15px;
        margin-bottom: 15px;
    }
    .ant-table-cell-fix-left, 
    .ant-table-cell-fix-right {
        position: relative !important;
    }

    .cmp__drwe .ant-table-cell-fix-left, 
    .cmp__drwe .ant-table-cell-fix-right {
        position: sticky !important;
    }
    .cmp__table.has_pag_btn .ant-table {
        margin-top: 10px;
        max-height: calc(100vh - 300px);
    }

    .cmp__table.has_pag_btn.favr_tbl .ant-table {
        max-height: 100%;
      }

    .cmp__drwe .ant-drawer-body .comparsion__hdr {
        flex-direction: column;
        align-items: start;
    }

    .cmp__drwe .ant-drawer-body .comparsion__hdr .cmp_lft {
        overflow: auto;
        width: 100%;
        max-width: 100%;
    }

    .cmp__drwe .ant-drawer-body .comparsion__hdr .cmp_lft .cmp_inf {
        min-width: 150px;
    }
    .cmp__drwe .ant-drawer-body .comparsion__hdr .cmp_rght {
        width: 100%;
        flex-direction: column;
        align-items: start;
        position: relative;
    }
    .cmp__drwe .ant-drawer-body .comparsion__hdr .cmp_rght .btn_grp {
        position: absolute;
        right: 0;
        top: 10px;
    }


    .admin__card.ww100 .details__Card {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        padding-right: 20%;
    }
    .admin__card.ww100 .details__Card .detail__card{
        white-space: nowrap;
    }

    .setting__stage.iconic__ttle {
        overflow: auto;
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          align-items: center;
          padding-bottom: 15px;
      }
      .setting__stage.iconic__ttle.tt {
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .my__sheet {
        flex: 100%;
        padding: 0;
        border: none;
        margin-top: 20px;

        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 30px;
    }
      
    .comparsion__body {
        display: block;
    }

 
    .product__main_info {
        gap: 10px;
        padding: 10px;
        flex-wrap: nowrap;
        align-items: center;
    }

    .product__main_info.is_manual_infos {
        background: transparent;
        padding: 0;
        border: none;
    }

    .product__main_info img {
        height: 80px;
    }

    .product__main_info.is_manual_infos img {
        height: 60px;
    }
    .product__main_info.is_manual_infos button.icconc__btn {
       display: none;
    }

    .product__cal_info {
        flex-wrap: wrap;
        gap: 10px;
    }


    .product__cal_info img, 
    .product__dmns_info img {
       display: none;
    }

    .calcs_Actnss {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 5px;
        padding: 0;
    }

    .product__cal_info .calc__inppt {
        width: 30%;
    }

    .product__dmns_info {
        align-items: start;
        gap: 10px;
        padding: 10px;
    }

    .dmns_item h4 {
        line-height: 14px;
    }

    .dmns_item span {
        line-height: 15px;
        display: block;
        margin-top: 8px;
    }

    .cmp_drw_title{
        margin-top: 0;
        gap: 10px;
        flex-wrap: wrap;
    }
    .cmp_drw_title.tt h4 {
        width: 100%;
        text-align: right;
    }

    .cmp_drw_title.ffg {
        flex-wrap: nowrap;
        padding: 0;
        padding-bottom: 10px;
        border: none;
        background: transparent;
        border-bottom: 1px solid rgb(217, 223, 226);
    }
    .cmp_drw_title.tt.ffg h4 {
        text-align: left;
    }

    .rght_itms_gbh {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 25px;
    }

    .rght_itms.tt {
        flex-wrap: wrap;
        justify-content: end;
        width: 100%;
    }

    .pbi__selectt {
        width: 100%;
    }

    .pbi__selectt .select {
        width: 100%;
        max-width: 100%;
    }

    /* .rght_itms {
        width: 100%;
        justify-content: space-between;
    }

.prdct__Tags {
    width: 80%;
} */

.options__kkp {
    left: auto;
        right: -10px;
        max-width: 320px;
        top: 75px;
}
.options__kkp.tt {
    right: -30vw;
}

.options__kkp .option__kkp {
    font-size: 12px;
}
.options__kkp .option__kkp .option-text {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pr_title h4 {
    max-width: 250px;
}

.fees__area {
    gap: 15px;
    align-items: start;
}

.pbii__manual .product__roi_info .fees__area {
    margin-top: 10px;
}
.pbii__manual .product__roi_info .fees__area {
    margin-top: 10px;
}

.product__main_info .fees_item img {
    height: auto;
}

.mob__dimnsiol {
    display: block;
}

.desk__dimnsiol {
    display: none;
}

.product__roi_info {
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.pbii__manual .product__roi_info {
    flex-direction: column-reverse;
}
.product__roi_info .fees_item h4 {
    flex-direction: column;
    align-items: start;
}


.comparsion__body .sheets_nav {
    width: 100%;
    overflow-x: auto;
    padding-right: 0;
        padding-left: 0;
}

.pbii__manual .comparsion__body .sheets_nav {
    width: 100%;
    overflow-x: auto;
    padding-right: 15px;
    padding-left: 15px;
    overflow-y: hidden;
    justify-content: start;
}

.comparsion__body .sheets_nav button {
    font-size: 11px;
}

.pbii__manual .comparsion__body .sheets_nav button {
    font-size: 11px;
    white-space: nowrap;
}

.mnll__box {
    margin-top: 10px;
}

.product__cmp_info.ttr.pbi_m_card .calc__inppt,
.product__cmp_info.ttr.pbi_m_card .smm_price.gtth {
    margin: 0;
    width: 25%;
}

.product__cmp_info.ttr.pbi_m_card .smm_price.gtth .calc__inppt {
    margin: 0;
    width: 100%;
}

.product__cmp_info.ttr.pbi_m_card .mnll__box {
    display: flex;
    gap: 15px;
    align-items: end;
}


.middle__icn {
    display: none;
}


.product__cmp_info {
    overflow-x: auto;
    gap: 10px;
    white-space: break-spaces;
    padding-bottom: 10px;
}

.product__cmp_info.ttr {
    flex-direction: column;
}

.product__cmp_info.ttr .middle__icn {
    display: none;
}

.product__cmp_info .lft__Card, 
.product__cmp_info .rght__Card {
    width: fit-content;
    min-width: 300px;
}
.product__cmp_info.ttr .lft__Card, 
.product__cmp_info.ttr .rght__Card {
    width: 100%;
    min-width: 300px;
}
.product__cmp_info.ttr.pbi_m_card .lft__Card, 
.product__cmp_info.ttr.pbi_m_card .rght__Card {
    padding: 0;
    background: transparent;
    border: none;
    margin-top: 15px;
    border-top: 1px solid rgb(217, 223, 226);
    padding-top: 20px;
}

.is_manual .fees_item img {
    display: none;
}

.sellers__mdoall {
    width: auto !important;
    max-width: 95% !important;
}
.sellers__mdoall .ant-modal-body {
    overflow-x: auto;
    width: 100%;
}

.product__cmp_info.stck {
    overflow: hidden;
    padding: 0;
    border: none;
}

.stock__Table_card {
    display: block;
}

.st__lftt {
    padding: 0;
    border: none;
}

.st__rghth {
    padding: 0;
    border: none;
    margin-top: 20px;
}

.onex_wl__wrapper.ppbbi {
    padding-bottom: 0;
}


.pb_icn_info:hover .hover_card_details {
    right: -50vw;
    max-width: 300px;
    white-space: break-spaces;
}


.cmp__drwe td.table_actin a.pbi__link {
    /* margin-right: 0;
    margin-left: auto; */
    margin: auto;
}


.calc__inppt .field__input {
    max-width: 100%;
}

.product__cmp_info.ttr.pbi_m_card .calc__inppt .field__input,
.product__cmp_info.ttr.pbi_m_card .calc__inppt .select {
    height: 40px;
}

.iccn_info p {
    white-space: break-spaces;
    line-height: 14px;
}

.iccn_info+.iccn_info {
    margin-top: 15px;
}

.ffvv.activity__item_calendar .activity__button {
    font-size: 12px;
    height: 33px;
    border-radius: 2px;
    padding: 0 5px;
}

.new__page .activity__top.ss .right_items .activity__item.ffvv button span {
    margin-bottom: -1px;
}
.new__page .activity__top.ss .right_items .activity__item.ffvv button svg {
    width: 16px;
    height: auto;
    margin-right: 5px;
    margin-top: -2px;
}

a.has__beta {
    width: 100%;
    max-width: 100%;
}
.has__beta .beta_tag.gg {
    right: 10px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .button-stroke.button-small.activity__button.js-activity-button:disabled, .button-stroke.button-small.activity__button.js-activity-button[disabled] {
    color: #fff;
    opacity: 0.5;
}


.shett_sstings_Area  {
    flex-direction: column;
    gap: 45px;
}
.shett_sstings_Area .keys__item {
    width: 100%;
    padding: 0;
}
.shett_sstings_Area .keys__item:first-child, .shett_sstings_Area .keys__item:first-of-type {
    border-right: none;
    border-bottom: 1px solid #e6e8ec;
    padding-right: 0;
    padding-bottom: 50px;
}


.setting__box.tt {
    width: 100%;
    min-width: 100%;
}

.custom__ship__inf {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

p.custom__shipp_info {
    width: 100%;
    max-width: 100%;
  }

  .select {
    border: 1px solid #a4a8ae;
    box-shadow: none;
}

.fbaam__warn {
    max-width: 270px;
  }
  .custoom_shpp_Area.not__aallow:hover, 
  .custoom_shpp_Area.not__aallow:focus {
    padding-bottom: 40px;
}

.telegram__how__area p {
    padding-left: 10px;
}

.telegram__how__area .new_ampt_colmn.tt.gg {
    margin-left: 15px;
}

.telegram__how__area .custom__ship__inf.wrrn {
    padding-right: 15px;
}

.telegram__how__area.active {
    flex-direction: column;
}

.inf__tlgramms {
    width: 100%;
}
.exmpp_immg img {
    min-width: 100%;
}

.releases__item {
    width: 100%;
    flex: 100%;
    max-width: 100%;
}

.releases__preview img {
    width: 100%;
    height: auto;
}

.releases__list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 70px;

  }
  .releases__center.center {
    background: white;
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.releases__item {
    padding: 0;
    border: none;
}


.main__preview {
    display: flex;
    flex-direction: column-reverse;
}

.img_area {
    position: relative;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    min-height: 325px;
    height: 100%;
    max-width: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main__wrap {
    position: relative;
    padding-bottom: 20px;
    padding-top: 45px;
}

.typing__grdent span {
    font-size: 23px;
}

.bg_bx_div {
    font-size: 11px !important;
    max-width: 70%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px !important;
}

.sub__infs {
    display: flex;
        grid-gap: 0px;
        gap: 0px;
        margin-top: -15px;
        margin-bottom: 45px;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 10px;
}
.sub__infs.rr {
    display: none;
}

.sub__inf {
    font-size: 11px;
    line-height: 13px;
}

.hero__logo_card {
    width: 100%;
    height: 100%;
    position: absolute;
}

.hero__logo_card .hero__logo {
    width: 100px;
    height: 100px;
}

.obj_fade.prd__11,
.obj_fade.prd__12,
.obj_fade.prd__13,
.obj_fade.prd__14 {
    width: 120px;
}

.promo_cn.bb {
    margin-top: 100px;
    margin-bottom: 85px;
}
.promo_cn.nn {
    margin-top: 90px;
}

.promo_cn.bb .map__dotted {
    width: 520px !important;
    height: 520px !important;
    top: 17%;
    left: -50%;
        right: -50%;
        overflow: hidden;
}
/* .promo_cn.bb .map__dotted canvas {
    width: 100% !important;
    height: auto !important;
} */

.iitm__tags {
    max-width: 300px;
}

span.tag__mmtm {
    padding: 5px 15px;
    font-size: 12px;
}

.kpc_dd__info.info.ggb {
    font-size: 16px;
}

.kpc_dd__title.tt {
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 25px;
    margin-bottom: 0;
}

.iitm__tags li i {
    margin-right: 6px;
    transform: scale(0.7);
    margin-left: -4px;
}

.onex_f__item img {
    max-width: 100%;
    margin-left: auto;
}


.pbi__Area .pbi_top img, .pbi__Area .pbi_bottom img {
    width: 100%;
}
.extesinon__area .onex_pl__wrap {
    max-width: 100%;
    min-width: 100%;
}

.extesinon__area .onex_pl__center {
    flex-direction: column-reverse;
}

.tagst-list-wrapper .checked_items + .checked_items {
    margin-left: 0px;
    margin-top: 20px;
  }

  .tagst-list-wrapper {
    padding-bottom: 5px;
}

.extesinon__area.ggh .pbi__Area .pbi_top img, 
.extesinon__area.ggh .pbi__Area .pbi_bottom img {
    margin-left: auto;
}
.onex_pl.cmp_anim.extesinon__area.ggh {
    margin-bottom: 55px;
}
.onex_pl.cmp_anim.extesinon__area.ggh .onex_pl__center {
    flex-direction: column;
}

.coming__fetaures.tstmls {
    padding: 0 25px;
    background: white;
    padding-top: 35px;
}

.scroll-animation-wrapper::after {
    right: -25px;
}

.scroll-animation-wrapper::before {
    left: -25px;
}

.scroll-animation-wrapper.pb--30 {
    padding-bottom: 20px !important;
}

.dkstp {
    display: none;
}

.mblle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

span.vstry {
    font-size: 14px;
}

.rbt-header-campaign {
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #19233529;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
}

.rbt-header-campaign .inner_campaign {
    background: #192335;
    width: 95%;
    height: auto;
    padding: 30px 25px;
    border-radius: 10px;
    display: block;
    position: relative;
}

.rbt-header-campaign .inner_campaign .content .news-text {
    margin-left: 0;
    margin-top: 20px;
    max-width: 90%;
}
.rbt-header-campaign .inner_campaign .right-button {
    margin-left: 0;
    margin-top: 30px;
}
.rbt-header-campaign .icon-close.position-right {
    top: 25px;
        right: 5px;
}
.rbt-header-campaign .inner_campaign .content .news-text img {
    position: absolute;
    top: 15px;
    width: 33px;
}
.rbt-badge {
    margin-top: 40px;
} 

.rbt-round-btn svg {
    width: 18px;
    height: 18px;
    stroke-width: 3px;
}
.rbt-round-btn::after {
    opacity: 1;
    transform: scale(1);
    background: white;
}

.rbt-round-btn {
    color: #192335;
}

.glightbox_video svg {
    width: 55px;
    height: 55px;
}

.main__info.cc {
    margin-top: 5px;
}

.onex_d__head.mm {
    margin-bottom: 55px;
}

.testimonalss__wrapper.ptb--100 {
    padding-bottom: 5px !important;
}

.scroll-animation {
    width: -webkit-calc((270px * 17) + (10px * 17));
    width: -moz-calc((270px * 17) + (10px * 17));
    width: calc((270px * 17) + (10px * 17));
}

.contact-us-hero-card-illo.have.tt.gg img,
.contact-us-hero-card-illo.have.tt.hh img {
    height: 65px;
}

.wow__info {
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 20px;
    padding: 15px 20px;
}
.iitm__tags.mm {
    max-width: 100%;
    padding: 0 25px;
}

.iitm__tags.mm .rfm-initial-child-container .rfm-child+.rfm-child {
    margin-left: 5px;
}

.iitm__tags.mm li img {
    height: 30px;
}

.dashbordi__nav {
    justify-content: space-between;
}

.dashbordi__link {
    padding-bottom: 15px;
    font-size: 12px;
}

.dashbordi__nav.mm {
    display: flex;
    position: fixed;
    top: 55px;
    z-index: 1;
    background: #ffffff;
    width: 100%;
    left: 0;
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 20px;
    margin: 0;
}
.dashbordi__nav.mm+h2 {
    margin-top: 35px !important;
}
.dashbordi__nav.mm::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    background: #ffffff;
    width: 100%;
    height: 10px;
    left: 0;
    right: 0;
    bottom: -10px;
    backdrop-filter: blur(20px);
    z-index: 0;
}

.footer-push.tmpp__area {
    margin-bottom: 200px;
}

.pbi__bddy {
    height: calc(100vh - 175px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ps_rrel .page-_loader.pbi {
    align-items: start;
    z-index: 999;
  }
  .dif__ablee .mob_bts td.table_actin {
    display: table-cell;
  }

  .dvd_pb {
    display: none;
  }

  .pr_title h4 {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60vw;
    display: block;
}

.btmm_infos {
    margin-top: 5px;
}

.pr_title {
    flex-direction: column-reverse;
    align-items: start;
}

.tagg__iitm img {
    height: auto;
}

.left__wrpp, .right__wrpp {
    max-width: 100%;
}

.product__roi_info .ant-progress {
    margin-bottom: 15px;
    margin-top: 10px;
}

.pbii__manual .product__roi_info .ant-progress {
    margin-bottom: 5px;
    margin-top: 0px;
}

.warn__card.plln {
    max-width: 98vw;
  }

  
.warn__card.plln.pymnt {
    margin-top: -10px;
    margin-bottom: 15px;
    max-width: 100%;
}



.ant-dropdown {
    z-index: 999999 !important;
  }

 .ant-dropdown .ant-dropdown-menu {
    padding: 0;
}

.dif__ablee.block_m .mob_bts .new__fix a.pbi__link {
   
    margin: 0;
}

.has_pag_btn.favr_tbl .ant-spin-container > .ant-table-pagination:first-child, 
.has_pag_btn.favr_tbl .ant-spin-container > .ant-table-pagination:first-of-type {
    position: relative;
    top: 0;
}

.fav_Tbl_wrapper .merged___area {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 4px;
    padding-bottom: 8px;
}

.earth_markets_vector_web.css_vals_web {
    display: none;
}

.more_markets_middle_web p {
    max-width: 100%;
}

.more_markets_btn_web {
    width: 100%;
    max-width: 100%;
    min-height: auto;
    margin-top: 5px;
    padding: 41px 20px;
    margin-bottom: 45px;
}

.market__cards_area {
    flex-direction: column;
}

.mini_market__card_web {
    width: 100%;
    max-width: 100%;
}

.mrkt__hdr {
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.market__cards_wrappera {
    margin-top: 20px;
}


.Inputss__itemm {
    flex-wrap: wrap;
    border: 1px solid #f0f0f0;
    padding: 9px 12px;
    border-radius: 5px;
    justify-content: start;
}

.Inputss__itemm>.setting__fieldset:first-child, .Inputss__itemm>.setting__fieldset:first-of-type {
    width: 100%;
    border: none;
    margin: 0;
}

.together__inputs {
    margin: 0px;
}

  }


  @media only screen and (max-width: 700px) {

    .brcdd___link {
        display: flex !important;
      }


    .section.onex_w.hero__img {
        margin-top: -50px;
    }
    .globe_all {
        top: 400px;
    }
    .main__preview img.earth {
        top: 161px;
    }
    
    .main__info.bb {
        margin-bottom: 0px;
    }
    
    .globe_all span {
        margin-top: 1px;
    }


  }