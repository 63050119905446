
.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  border: 2px solid #E6E8EC;
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  box-shadow: none;
  font-size: 1rem;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(1em - 1px);
  position: relative;
  vertical-align: top;
  background-color: #fff;
  border-radius: 12px;
  color: #23262F;
  max-width: 100%;
  width: 100%;
  height: 38px;
  transition: all .3s;

  height: 48px;
}



.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  border-color:#777E90;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}

.AppWrapper input,
.AppWrapper button {
  all: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.AppWrapper {
    width: 100%;
    height: 100%;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}



  /********************/
  /********************/
  /********************/
  /********************/
  /********************/

  .stripe_result {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .stripe_result .cmt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .stripe_result .cmt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .stripe_order label {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    /* text-transform: uppercase; */
    color: #B1B5C3;
  }